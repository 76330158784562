import { LoadingButton } from '@mui/lab'
import { BaseDialog } from 'yu-open-lib'
import {
  Typography, Link as MuiLink, Button, Alert
} from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import { Link } from 'react-router-dom'
import { useAvailabilities } from '@/hooks/availabilities'
import api from '@/api'

type Props = {
  open: boolean
  onClose: () => void
  meetingId: number | string
  jobUUID?: string
}

const ReScheduleMeetingDialog: React.FC<Props> = ({
  meetingId, jobUUID, open, onClose
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { addToast } = useToasts()

  const { availabilities } = useAvailabilities()

  const reScheduleMeeting = useMutation(
    () => api.post('client_area/meetings/re_schedule', {
      id: meetingId
    }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['candidates_area', 'jobs', 'meetings', 'user'])

        if (jobUUID) {
          queryClient.invalidateQueries(['jobs', 'meetings', 'job_meetings', jobUUID])
        } else {
          queryClient.invalidateQueries(['jobs', 'meetings', 'job_meetings'])
        }
      }
    }
  )

  return (
    <BaseDialog
      title={t('meetings.dialogs.re_schedule.title')}
      open={open}
      onClose={onClose}
      loading={!availabilities}
      actions={(
        <>
          <LoadingButton onClick={onClose} disabled={reScheduleMeeting.isLoading} color="info">
            {t('meetings.dialogs.actions.cancel')}
          </LoadingButton>

          <LoadingButton
            onClick={() => {
              reScheduleMeeting.mutate(undefined, {
                onSuccess() {
                  addToast(t('meetings.dialogs.alerts.re_schedule.success'))
                  onClose()
                },
                onError() {
                  addToast(t('meetings.dialogs.alerts.re_schedule.error'), {
                    appearance: 'error'
                  })
                }
              })
            }}
            loading={reScheduleMeeting.isLoading}
            color="error"
            disabled={!availabilities || availabilities.data.length < 5}
          >
            {t('meetings.dialogs.actions.confirm')}
          </LoadingButton>
        </>
      )}
    >
      {availabilities && availabilities.data.length >= 5 && (
        <Typography variant="body1">{t('meetings.dialogs.re_schedule.message')}</Typography>
      )}

      {availabilities && availabilities.data.length < 5 && (
        <Alert
          severity="error"
          variant="outlined"
          action={(
            <MuiLink component={Link} to="/availabilities">
              <Button>{t('meetings.dialogs.re_schedule.availabilities_link')}</Button>
            </MuiLink>
          )}
        >
          {t('meetings.dialogs.re_schedule.no_availabilities.message')}
        </Alert>
      )}
    </BaseDialog>
  )
}

export default ReScheduleMeetingDialog
