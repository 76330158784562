import { LoadingButton } from '@mui/lab'
import { Button, Grid, Typography } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import { BaseDialog, useToggle } from 'yu-open-lib'
import { ResponsibleCompany } from '@/types/graphs_blacklists'
import api from '@/api'
import RejectDialog from '../reports/RejectDialog'

type Props = {
  open: boolean
  onClose: () => void
  meetingId: number | string
  jobUUID?: string
  jobApplicationUUID: string
}

const CancelMeetingDialog: React.FC<Props> = ({
  meetingId,
  jobApplicationUUID,
  jobUUID,
  open,
  onClose
}) => {
  const { t } = useTranslation()
  const { data: responsibleCompany } = useQuery<ResponsibleCompany>([
    'client_area',
    'jobs',
    jobUUID,
    'responsible_company'
  ])
  const queryClient = useQueryClient()
  const { addToast } = useToasts()
  const [rejectionDialog, toggleRejectionDialog] = useToggle(false)

  const cancelMeeting = useMutation(
    () => api.post('client_area/meetings/cancel', {
      id: meetingId
    }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['candidates_area', 'jobs', 'meetings', 'user'])

        if (jobUUID) {
          queryClient.invalidateQueries(['jobs', 'meetings', 'job_meetings', jobUUID])
        } else {
          queryClient.invalidateQueries(['jobs', 'meetings', 'job_meetings'])
        }
      }
    }
  )

  return (
    <BaseDialog
      title={t('meetings.dialogs.cancel.title')}
      open={open}
      onClose={onClose}
      actions={(
        <>
          <LoadingButton onClick={onClose} disabled={cancelMeeting.isLoading} color="info">
            {t('meetings.dialogs.actions.cancel')}
          </LoadingButton>

          <LoadingButton
            onClick={() => {
              cancelMeeting.mutate(undefined, {
                onSuccess() {
                  addToast(t('meetings.dialogs.alerts.success'))
                  onClose()
                },
                onError() {
                  addToast(t('meetings.dialogs.alerts.error'), {
                    appearance: 'error'
                  })
                }
              })
            }}
            loading={cancelMeeting.isLoading}
            color="error"
          >
            {t('meetings.dialogs.actions.confirm')}
          </LoadingButton>
        </>
      )}
    >
      <RejectDialog
        uuid={jobApplicationUUID}
        isOpen={rejectionDialog}
        handleClose={toggleRejectionDialog}
      />

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <Button onClick={toggleRejectionDialog} color="error" fullWidth variant="contained">
            {t('meetings.actions.candidate.reject')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            {t('meetings.dialogs.cancel.message', {
              company: responsibleCompany === 'plonge' ? 'Plongê' : 'Yu'
            })}
          </Typography>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}

export default CancelMeetingDialog
