import { useMemo, useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  Input,
  Chip,
  ListSubheader,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  Typography,
  SelectChangeEvent
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '@yu/i18n'
import { useToasts } from 'react-toast-notifications'
import { useQuery } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import { BackdropLoading, groupBy } from 'yu-open-lib'

import api from '@/api'
import { getCandidate } from '@/api/candidates'
import { jobCandidates, useRedirectTo } from '@/helpers/routes'
import { useReportCooldown } from '@/hooks/reports'
import ActionCooldownContent from '@/components/reports/ActionCooldownContent'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: theme.spacing(1, 0, 4, 0)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

type Props = {
  uuid: string
  isOpen: boolean
  handleClose: () => void
}

type Reason = {
  id: number
  reason: string
  rejection_type: string
}

type ReasonTargetValue = {
  value: Reason[]
}

type ReasonTarget = {
  target: ReasonTargetValue
  name?: string
}

const MIN_COMMENT_SIZE = 1

const RejectDialog: React.FC<Props> = ({ uuid, isOpen, handleClose }) => {
  const { t } = useTranslation(['text'])
  const { addToast } = useToasts()
  const classes = useStyles()
  const { data: candidate } = useQuery(['candidate', uuid], () => getCandidate(uuid))
  const candidateName = candidate?.data?.attributes?.person?.attributes?.name
  const [reasons, setReasons] = useState([] as Reason[])
  const [isReasonInvalid, setIsReasonInvalid] = useState(false)
  const [commentValue, setCommentValue] = useState('')
  const [isCommentInvalid, setIsCommentInvalid] = useState(false)
  const { delayedRedirect } = useRedirectTo(1)
  const [isLoading, setIsLoading] = useState(false)
  const { isOnCooldown, triggerAction } = useReportCooldown()

  const rejectionTemplates = useMemo(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const templates = candidate?.meta?.job?.attributes?.rejection_templates
    if (templates) return groupBy(templates, 'rejection_type')

    return {}
  }, [candidate])

  const handleReasonsChange = (event: SelectChangeEvent<Reason[]>) => {
    const { target }: ReasonTarget = (event as unknown) as ReasonTarget
    const filteredReasons = target.value.filter((value: Reason): Reason => value)
    setReasons(filteredReasons)
  }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentValue(event.target.value)
  }

  const rejectionGroups: string[] = useMemo(() => Object.keys(rejectionTemplates), [
    rejectionTemplates
  ])

  const handleReject = async () => {
    try {
      const reasonIsValid = !!reasons.length
      const commentIsValid = !!commentValue && commentValue.length >= MIN_COMMENT_SIZE
      if (!reasonIsValid || !commentIsValid) {
        if (!reasonIsValid) setIsReasonInvalid(true)
        if (!commentIsValid) setIsCommentInvalid(true)

        return
      }

      handleClose()

      setIsLoading(true)

      const { data } = await api.post(`client_area/reports/${uuid}/reject`, {
        reasons,
        comment: commentValue
      })

      if (data.status_code !== 200) {
        throw new Error(`API returned status code not 200: ${data.status_code}`)
      }

      addToast(t('text:report.toasts.reject_success'))

      triggerAction()

      // eslint-disable-next-line
      // @ts-ignore
      const jobUuid: string = candidate?.meta?.job?.attributes?.uuid

      delayedRedirect(jobUuid ? jobCandidates(jobUuid) : '/')
    } catch (error) {
      addToast(t('text:report.toasts.reject_error'), { appearance: 'error' })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <BackdropLoading open={isLoading} />
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{t('text:report.tooltip.reject')}</DialogTitle>
        <DialogContent>
          {isOnCooldown ? (
            <ActionCooldownContent />
          ) : (
            <>
              <Typography variant="subtitle1">
                {t('text:report.label.rejecting', { candidate_name: candidateName })}
              </Typography>
              <FormControl variant="outlined" className={classes.input} fullWidth>
                <InputLabel id="reasons-label">{t('text:report.label.reasons')}</InputLabel>
                <Select
                  labelId="reasons-label"
                  multiple
                  input={<Input />}
                  value={reasons}
                  onChange={handleReasonsChange}
                  error={isReasonInvalid}
                  renderValue={(selected: any) => (
                    <div className={classes.chips}>
                      {selected.map((template: Reason) => (
                        <Chip
                          key={`${template.id} ${uuid}`}
                          // eslint-disable-next-line
                          // @ts-ignore
                          label={`${template.reason} (${t(
                            `text:rejection.type.${template.rejection_type}`
                          )})`}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {rejectionGroups.map((rejectionType: string) => [
                    <ListSubheader key={`${uuid} + ${rejectionType}`}>
                      {
                        // eslint-disable-next-line
                        /* @ts-ignore */
                      }
                      {t(`text:rejection.type.${rejectionType}`)}
                    </ListSubheader>,

                    rejectionTemplates[rejectionType].map((template: any) => (
                      <MenuItem
                        key={`${uuid} ${template.id}`}
                        value={(template as unknown) as string[]}
                      >
                        <Checkbox checked={reasons.indexOf(template) > -1} />
                        <ListItemText primary={template.reason} />
                      </MenuItem>
                    ))
                  ])}
                </Select>
              </FormControl>

              <TextField
                className={classes.input}
                fullWidth
                label={t('text:report.label.mandatory_comment')}
                value={commentValue}
                error={isCommentInvalid}
                helperText={isCommentInvalid && t('text:report.label.mandatory_comment')}
                onChange={handleCommentChange}
                multiline
                rows={6}
                variant="outlined"
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={isOnCooldown}>
            {t('text:misc.close')}
          </Button>

          <Button
            onClick={handleReject}
            color="primary"
            type="submit"
            disabled={isOnCooldown || !commentValue}
          >
            {t('text:misc.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RejectDialog
