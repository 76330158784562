import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '@yu/i18n'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { APIJobData } from '@/types/jobs'
import { CandidateStatus } from '@/types/candidates'
import { InterestStatus } from '@/types/interest'

type Props = {
  id: string
  candidateStatus?: CandidateStatus | InterestStatus
  title?: string
  size: number
  jobData?: APIJobData
  children?: React.ReactNode
}

type StyleProps = {
  isMobile: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  accordionDetails: {
    padding: '4px 8px 8px'
  },
  weekHeader: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold
  }
}))

const CarometroGrouping: React.FC<Props> = ({
  id, candidateStatus, title, size, children
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles({ isMobile })
  const { t } = useTranslation(['candidates'])

  return (
    <Grid item xs={12} key={id}>
      <Accordion defaultExpanded={!isMobile}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.weekHeader}>
            {candidateStatus
              ? `${t(`candidates:${candidateStatus}`, {
                defaultValue: candidateStatus
              })} (${size})`
              : `${title} (${size})`}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          sx={{ display: 'flex', flexDirection: 'column' }}
          className={classes.accordionDetails}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

export default CarometroGrouping
