import confetti from 'canvas-confetti'
import { useTranslation } from '@yu/i18n'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import * as Sentry from '@sentry/react'
import { BackdropLoading } from 'yu-open-lib'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useMemo } from 'react'
import { interestsCacheKey, InterestStatus } from '@/types/interest'
import api from '@/api'

type Props = {
  interestId: string | number
  status?: InterestStatus
  onClose: () => void
  open: boolean
}

const AproveInterestDialog: React.FC<Props> = ({
  interestId, status, open, onClose
}) => {
  const { t } = useTranslation('text')

  const { addToast } = useToasts()
  const queryClient = useQueryClient()

  const texts = useMemo(() => {
    const defaultTexts = {
      onApproveError: t('report.toasts.errors.approve_interest'),
      title: t('report.dialog.interest.approve.title'),
      contentText: t('report.dialog.interest.approve.text')
    }
    if (!status) {
      return defaultTexts
    }
    if (status !== 'available_to_client') {
      return {
        onApproveError: t('report.toasts.errors.approve_candidate'),
        title: t('report.dialog.interest.approve.title_2'),
        contentText: t('report.dialog.interest.approve.text_2')
      }
    }

    return defaultTexts
  }, [status, t])

  const approveMutation = useMutation(
    () => api.post(`client_area/interests/approve/${interestId}`),
    {
      onSuccess() {
        confetti({
          particleCount: 300,
          spread: 100,
          origin: { y: 0.6 }
        })
        onClose()
        queryClient.invalidateQueries(interestsCacheKey)
      },
      onError(error) {
        addToast(texts.onApproveError, { appearance: 'error' })
        Sentry.captureException(error)
        onClose()
      }
    }
  )

  return (
    <>
      <BackdropLoading open={approveMutation.isLoading} />
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{texts.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{texts.contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={approveMutation.isLoading} onClick={onClose}>
            {t('misc.close')}
          </Button>
          <Button
            disabled={approveMutation.isLoading}
            onClick={() => approveMutation.mutate()}
            color="secondary"
          >
            {t('misc.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AproveInterestDialog
