export function generateRandomString(length = 20): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function truncatedText(text?: string | null, cut = 40): string | null {
  if (!text) return null

  if (text.length > cut) {
    return `${text.substring(0, cut - 3).trimEnd()}...`
  }

  return text
}

export const asPercentage = (number: number): string => `${(number * 100).toFixed(0)}%`

export const currency = (amount: string | number, prefix = '', fixed = 2): string => prefix
  + Number(parseFloat(amount.toString()))
    .toFixed(fixed)
    .replace(/\d(?=(\d{3})+\.)/g, '$&.')

export const kCurrency = (amount: string | number, fixed = 0): string => `${(parseFloat(amount.toString()) / 1000).toFixed(fixed)}k`

export const roundToThousand = (value: number, method: 'floor' | 'ceil' | 'nearest'): number => {
  switch (method) {
    case 'ceil':
      return Math.ceil(value / 1000) * 1000
    case 'floor':
      return Math.floor(value / 1000) * 1000
    case 'nearest':
      return Math.round(value / 1000) * 1000
    default:
      throw new Error(`'${method}' is not valid. Possible values are: 'floor', 'ceil', 'nearest'.`)
  }
}

export const textOrNothing = (text: string, conditionForText: boolean): string => (conditionForText ? text : '')

export const deepClone = (obj: Record<any, any>): Record<any, any> => {
  // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
  let copy

  // Handle the 3 simple types, and null or undefined
  if (obj == null || typeof obj !== 'object') return obj

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date()
    copy.setTime(obj.getTime())
    return copy
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = []
    for (let i = 0, len = obj.length; i < len; i += 1) {
      copy[i] = deepClone(obj[i])
    }
    return copy
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {}
    // eslint-disable-next-line
    for (const attr in obj) {
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line
      if (obj.hasOwnProperty(attr)) copy[attr] = deepClone(obj[attr])
    }
    return copy
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}
