import { createContext, useContext, useReducer } from 'react'

import { GroupingType } from '@/types/candidates'

export type CandidatesState = {
  /** @default 'sent_to_client_month_week' */
  groupBy: GroupingType
}

export type SetCandidatesGroupAction = {
  type: 'setCandidatesGroup'
  value: GroupingType
}

export type CandidatesAction = SetCandidatesGroupAction

export interface CandidatesContextType extends CandidatesState {
  dispatch: (action: CandidatesAction) => CandidatesState
}

export const CandidatesContext = createContext({})

export const candidatesReducer = (
  state: CandidatesState,
  action: CandidatesAction
): CandidatesState => {
  switch (action.type) {
    case 'setCandidatesGroup':
      localStorage.setItem('candidatesGroupBy', action.value)
      return { ...state, groupBy: action.value }
    default:
      throw new Error()
  }
}

type Props = {
  children?: React.ReactNode
}

export const CandidatesStore: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(candidatesReducer, {
    groupBy:
      (localStorage.getItem('candidatesGroupBy') as GroupingType) || 'sent_to_client_month_week'
  })

  return (
    <CandidatesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CandidatesContext.Provider>
  )
}

export const groupFields: GroupingType[] = [
  'sent_to_client_month_week',
  'status',
  'evaluation_step_name'
]

// eslint-disable-next-line max-len
export const useCandidates = (): CandidatesContextType => useContext(CandidatesContext) as CandidatesContextType
