import { useContext, createContext, useState } from 'react'
import { JobGraphOptions } from '@/api/jobs'
import { useRequiredParams } from '@/hooks'

export type JobHistoryState = {
  asDate?: Date
  filters: JobGraphOptions
  updateDate: (newDate: Date) => void
}

export const HistoryContext = createContext<JobHistoryState>({} as JobHistoryState)

export const historyStorageKey = (uuid: string): string => `job_history_date_${uuid}`

type Props = {
  children?: React.ReactNode
}

export const ProvideHistoryContext: React.FC<Props> = ({ children }) => {
  const { uuid } = useRequiredParams<{ uuid?: string }>()

  const existingDate = uuid ? localStorage.getItem(historyStorageKey(uuid)) : null
  const defaultDate: JobHistoryState['asDate'] | undefined = (existingDate ? new Date(existingDate) : null) || undefined

  const [asDate, setAsDate] = useState<typeof defaultDate>(defaultDate)

  const updateDate = (newDate: Date) => {
    if (uuid && import.meta.env.PROD) {
      localStorage.setItem(historyStorageKey(uuid), newDate.toISOString())
    }

    setAsDate(newDate)
  }

  const context: JobHistoryState = {
    asDate,
    updateDate,
    filters: {
      asDate
    }
  }

  return <HistoryContext.Provider value={context}>{children}</HistoryContext.Provider>
}

export const useNavigateContext = (): Partial<JobHistoryState> => useContext(HistoryContext)
