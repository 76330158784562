import {
  Box, Typography, useTheme, useMediaQuery
} from '@mui/material'
import { ResponsiveBar, BarTooltipDatum } from '@nivo/bar'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import DefaultSkeleton from '@/components/dashboard_cards/DefaultSkeleton'
import GraphCard from '@/components/dashboard/GraphCard'

type AverageStepDaysProps = {
  name: string
  count: number
}

type LoadingOrErrorProps = {
  data: AverageStepDaysProps[] | undefined
}

type IndexType = 'Atração' | 'Etapa YU' | 'Shortlist' | 'Proposta'

const ToolTip = (props: PropsWithChildren<BarTooltipDatum>) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const { id } = props

  const indexes = {
    Atração: 0,
    'Etapa YU': 1,
    Shortlist: 2,
    Proposta: 3
  }

  const index = indexes[id as IndexType]
  const isFirstHalf = index <= 1

  const tooltipText = (step: IndexType): string => {
    if (step === t('homepage.graphs.label.application')) {
      return t('homepage.graphs.tooltip.application')
    }
    if (step === t('homepage.graphs.label.yu_step')) {
      return t('homepage.graphs.tooltip.yu_step')
    }
    if (step === t('homepage.graphs.label.client_step')) {
      return t('homepage.graphs.tooltip.client_step')
    }
    if (step === t('homepage.graphs.label.offer')) {
      return t('homepage.graphs.tooltip.offer')
    }
    return ''
  }

  return (
    <Typography
      variant="body2"
      sx={{
        background: '#fff',
        color: '#000',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        fontSize: '14px',
        padding: '10px',
        width: isMobile ? '130px' : '200px',
        maxWidth: isMobile ? '130px' : '200px',
        position: 'absolute',
        transform: isFirstHalf ? 'translate(20px,-60px)' : 'translate(-110%,-40%)',
        display: 'flex',
        flexWrap: 'wrap'
      }}
    >
      {tooltipText(id as IndexType)}
    </Typography>
  )
}

const LoadingOrError: React.FC<LoadingOrErrorProps> = ({ data }) => {
  const { t } = useTranslation()

  return data && data.length === 0 ? (
    <Typography variant="h6" sx={{ textAlign: 'center', opacity: 0.7 }}>
      {t('generic_error.no_data_enough')}
    </Typography>
  ) : (
    <DefaultSkeleton />
  )
}

type Props = {
  projectId?: string | number | undefined | null
}

const AverageStepDays: React.FC<Props> = ({ projectId }) => {
  const { t } = useTranslation()
  const { data } = useQuery<AverageStepDaysProps[]>([
    'client_area',
    'overall',
    'average_days_per_step',
    { project_id: projectId }
  ])

  const days_per_step = useMemo(() => {
    if (data && data.length > 0) {
      return [
        {
          days: t('homepage.graphs.label.days'),
          [t('homepage.graphs.label.application')]: data[0].count,
          [t('homepage.graphs.label.yu_step')]: data[1].count,
          [t('homepage.graphs.label.client_step')]: data[2].count,
          [t('homepage.graphs.label.offer')]: data[3].count
        }
      ]
    }
    return []
  }, [data, t])

  return (
    <GraphCard
      title={t('homepage.graphs.title.average_time_per_step')}
      showInfoDialog
      infoText={t('homepage.graphs.info.average_time_per_step')}
      height="200px"
      mobileHeight={250}
      onClick={undefined}
    >
      <Box
        sx={{
          height: '250px',
          display: 'flex',
          marginTop: '-40px',
          paddingBottom: '40px',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {data && data.length > 0 ? (
          <ResponsiveBar
            padding={0.5}
            data={days_per_step}
            keys={[
              t('homepage.graphs.label.application'),
              t('homepage.graphs.label.yu_step'),
              t('homepage.graphs.label.client_step'),
              t('homepage.graphs.label.offer')
            ]}
            indexBy="days"
            layout="horizontal"
            margin={{
              top: 0,
              right: 5,
              bottom: 0,
              left: 5
            }}
            tooltip={ToolTip}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            theme={{
              labels: {
                text: {
                  fontSize: '16px'
                }
              },
              legends: {
                text: {
                  fontSize: '14px'
                }
              }
            }}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            legends={[
              {
                itemTextColor: '#999',
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 120,
                itemHeight: 100,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 15,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            colors={['#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6']}
          />
        ) : (
          <LoadingOrError data={data} />
        )}
      </Box>
    </GraphCard>
  )
}

export default AverageStepDays
