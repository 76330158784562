import React from 'react'
import { Download as DownloadIcon } from '@mui/icons-material'

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography
} from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { CandidateCsvAttributes } from '@/types/candidate_csv'

type Props = {
  open: boolean
  onClose: () => void
  candidateCsv?: CandidateCsvAttributes
}

const ExportCandidateAlert: React.FC<Props> = ({ open, onClose, candidateCsv }) => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>{t('export_candidates.alert_dialog.title')}</DialogTitle>
      <DialogContent>
        <Alert variant="outlined" severity="info">
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('export_candidates.alert_dialog.email')}
          </Typography>
        </Alert>

        <Box mt={2} />

        <Alert variant="outlined" severity="warning">
          <Typography sx={{ fontWeight: 'bold' }}>
            {t('export_candidates.alert_dialog.policy')}
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Link
          component="a"
          download={candidateCsv?.file?.name || ''}
          href={candidateCsv?.file?.url || ''}
          aria-label="download"
        >
          <Button onClick={onClose} endIcon={<DownloadIcon />}>
            {t('export_candidates.alert_dialog.confirm')}
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  )
}

export default ExportCandidateAlert
