import {
  Box, Chip, Tooltip, Typography, useTheme
} from '@mui/material'

import { useTranslation } from '@yu/i18n'
import { Check, Close, ThumbUp } from '@mui/icons-material'
import { EvaluationCriterionAttributes } from '@/types/evaluation_criterion'

type Props = {
  criterion: EvaluationCriterionAttributes
  checked: boolean
  chip?: boolean
}

type IconProp = {
  checked: boolean
  mandatory: boolean
}

const CheckedIcon: React.FC<IconProp> = ({ checked, mandatory }) => {
  if (checked && mandatory) return <Check fontSize="small" />
  if (checked) return <ThumbUp fontSize="small" />
  return <Close fontSize="small" />
}

const EvaluationCriteriaCheckbox: React.FC<Props> = ({ criterion, checked, chip = false }) => {
  const theme = useTheme()
  const { t } = useTranslation(['text'])

  return (
    <Tooltip
      key={criterion.criterion}
      title={`${criterion.criterion_type}: ${criterion.criterion}${
        criterion.mandatory ? ` - ${t('text:alignment.evaluation_criteria.columns.mandatory')}` : ''
      }`}
    >
      {!chip ? (
        <Box sx={{ display: 'flex' }}>
          <CheckedIcon checked={checked} mandatory={criterion.mandatory} />
          <Typography sx={{ marginLeft: '5px' }} variant="inherit">
            {criterion.criterion}
          </Typography>
        </Box>
      ) : (
        <Chip
          variant="outlined"
          icon={<CheckedIcon checked={checked} mandatory={criterion.mandatory} />}
          size="small"
          label={criterion.criterion}
          sx={{
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
            margin: '5px',
            fontSize: '12px',
            height: '20px',
            paddingLeft: '5px'
          }}
        />
      )}
    </Tooltip>
  )
}

export default EvaluationCriteriaCheckbox
