import i18next from 'i18next'
import { resources } from '@yu/i18n/config'

export function getUserLang(): string {
  const userLang = localStorage.getItem('yuri_language')

  if (!userLang) {
    return navigator.language.split('-')[0]
  }

  return userLang
}

export function setUserLang(lang: keyof typeof resources): void {
  i18next.changeLanguage(lang)
  localStorage.setItem('yuri_language', lang)
}
