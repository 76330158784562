import {
  useState, useEffect, useMemo, useCallback, useContext, createContext
} from 'react'

import { useQuery } from '@tanstack/react-query'

import dayjs from 'dayjs'
import { APIInterestData, Interest } from '@/types/interest'

export type ReportCooldown = {
  isOnCooldown: boolean
  triggerAction: () => void
}

export const ReportCooldownContext = createContext<ReportCooldown>({
  isOnCooldown: false,
  triggerAction: () => null
})

type Props = {
  uuid: string
  children?: React.ReactNode
}

export const ProvideReportCooldownContext: React.FC<Props> = ({ children, uuid }) => {
  const reportKey = useMemo(() => `last_report_button_action_at-${uuid}`, [uuid])
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(
    // Re-update currentDate every minute
    () => {
      const interval = setInterval(() => {
        setCurrentDate(new Date())
      }, 60000)

      return () => clearInterval(interval)
    },
    []
  )

  const isOnCooldown = useMemo<boolean>(() => {
    const lastActionAt = localStorage.getItem(reportKey)
    if (!lastActionAt) return false

    return Math.abs(dayjs(currentDate).diff(dayjs(lastActionAt), 'minute')) < 30
  }, [reportKey, currentDate])

  const triggerAction = useCallback(() => {
    if (import.meta.env.PROD) {
      // Only set report cooldown in Production
      localStorage.setItem(reportKey, new Date().toString())
    }
  }, [reportKey])

  const context = {
    isOnCooldown,
    triggerAction
  }

  return <ReportCooldownContext.Provider value={context}>{children}</ReportCooldownContext.Provider>
}

export const useReportCooldown = (): ReportCooldown => useContext(ReportCooldownContext)

export type UseInterestOptions = {
  /** @default false */
  enabled?: boolean
}

/**
 * Get specific interest from all interests data
 */
export const useInterest = (
  personUUID: string,
  { enabled = false }: UseInterestOptions
): Interest | null => {
  const { data: interests } = useQuery<APIInterestData>(['client_area', 'interests'], {
    enabled
  })

  const interest = useMemo(() => {
    if (!interests) return null

    return (
      interests.data.find(
        (interestDatum) => interestDatum?.attributes?.person?.data.attributes.uuid === personUUID
      ) || null
    )
  }, [interests, personUUID])

  return interest
}
