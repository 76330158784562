import { useMemo, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from '@yu/i18n'

import {
  Toolbar, useTheme, useMediaQuery, Typography
} from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import GroupsIcon from '@mui/icons-material/Groups'
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Language from '@mui/icons-material/Language'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import { YuAppBar, BackdropLoading, useToggle } from 'yu-open-lib'
import { NavbarItem } from 'yu-open-lib/dist/types'
import { CalendarToday } from '@mui/icons-material'
import ListAltIcon from '@mui/icons-material/ListAlt'
import PlongeIcon from '@/assets/icons/plonge/PlongeIcon'

import { ScrollToTopControlller } from '@/Routes'
import { ThemeContext, ThemeContextType } from '@/hooks/theme'
import { useCurrentUser } from '@/hooks/auth'
import { logout } from '@/api/authentication'
import { setUserLang } from '@/helpers/language'
import { EXPORT_CANDIDATES_CONFIG_KEY } from '@/helpers/constants'
import { APIConfigDatum } from '@/types/config'
import UserIdSelector from './UserIdSelector'
import YuProSaveds from '../homepage/yupro/YuProSaveds'
import Notification from '../shared/Notification'
import ExportCandidateDialog from '../export_candidates/ExportCandidateDialog'

const Header: React.FC = () => {
  const { loggedIn, user, isYuproClient } = useCurrentUser()
  const { dispatch, darkMode } = useContext(ThemeContext) as ThemeContextType
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [langMenuAnchor, setLangMenuAnchor] = useState<
    null | Element |(EventTarget & SVGSVGElement)
      >(null)
  const [yuproMenuAnchor, setYuproMenuAnchor] = useState<
    null | Element |(EventTarget & SVGSVGElement)
      >(null)
  const [NotifyAnchorEl, setNotifyAnchorEl] = useState<
    null | Element |(EventTarget & SVGSVGElement)
      >(null)
  const [openYuProFavorites, setOpenYuProFavorites] = useState(false)

  const [openExportDialog, toggleExportDialog] = useToggle(false)
  const { data: exportCandidates } = useQuery<APIConfigDatum>(
    ['client_area', 'configs', EXPORT_CANDIDATES_CONFIG_KEY],
    { enabled: !!user }
  )

  const logoutMutation = useMutation(logout, {
    onSuccess() {
      navigate('/login')
      addToast(t('auth.success.sign_out'), { appearance: 'success' })
    },
    onError() {
      addToast(t('auth.errors.sign_out'), { appearance: 'error' })
    },
    onSettled() {
      queryClient.invalidateQueries(['users', 'current'])
    }
  })

  const themeInfo = useMemo(
    () => ({
      text: darkMode ? t('header.theme.light') : t('header.theme.dark'),
      icon: darkMode ? <BrightnessHighIcon /> : <Brightness4Icon />
    }),
    [darkMode, t]
  )

  const handleMenuOpen = () => {
    // TODO buscar uma maneira mais elegante de adicionar menu na YU LIB
    const langIcon = document.querySelector('#lang-item')

    if (!langMenuAnchor) {
      setLangMenuAnchor(langIcon)
      return
    }

    setLangMenuAnchor(null)
  }

  const handleNotifyMenuOpen = () => {
    // TODO buscar uma maneira mais elegante de adicionar menu na YU LIB
    const notifyIcon = document.querySelector('#notify-item')

    if (!NotifyAnchorEl) {
      setNotifyAnchorEl(notifyIcon)
      return
    }

    setLangMenuAnchor(null)
  }

  const handleYuProMenuOpen = () => {
    const yuproIcon = document.querySelector('#yupro-item')

    if (!yuproMenuAnchor) {
      setYuproMenuAnchor(yuproIcon)
      return
    }

    setYuproMenuAnchor(null)
  }

  const appBarItems: {
    leftItems: NavbarItem[]
    rightItems: NavbarItem[]
    drawerItems: NavbarItem[]
  } = {
    rightItems: [
      {
        iconButton: true,
        text: themeInfo.text,
        onClick: () => dispatch({ type: 'toggleTheme' }),
        tooltip: themeInfo.text,
        icon: themeInfo.icon
      },
      {
        iconButton: true,
        tooltip: t('header.buttons.availabilities'),
        text: t('header.buttons.availabilities'),
        icon: <CalendarToday />,
        onClick() {
          navigate('/availabilities')
        }
      },
      {
        iconButton: true,
        text: t('header.buttons.language'),
        tooltip: t('header.buttons.language'),
        onClick: () => handleMenuOpen(),
        icon: (
          <>
            <Language id="lang-item" onClick={(e) => setLangMenuAnchor(e.currentTarget)} />

            <Menu
              id="lang-menu"
              anchorEl={langMenuAnchor}
              keepMounted
              open={Boolean(langMenuAnchor)}
              onClose={() => setLangMenuAnchor(null)}
              style={{
                zIndex: theme.zIndex.tooltip + 1
              }}
            >
              <MenuItem
                onClick={(e) => {
                  setUserLang('en')
                  setLangMenuAnchor(null)
                }}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUserLang('pt')
                  setLangMenuAnchor(null)
                }}
              >
                Português
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUserLang('es')
                  setLangMenuAnchor(null)
                }}
              >
                Español
              </MenuItem>
            </Menu>
          </>
        )
      },

      {
        iconButton: true,
        onClick: () => logoutMutation.mutate(),
        tooltip: t('header.buttons.sign_out'),
        text: t('header.buttons.sign_out'),
        icon: <ExitToAppIcon />
      }
    ],
    leftItems: [],
    drawerItems: []
  }

  const notifyItemBar = {
    iconButton: true,
    text: t('header.buttons.notifications'),
    tooltip: t('header.buttons.notifications'),
    onClick: () => handleNotifyMenuOpen(),
    icon: <Notification />
  }

  const exportItemBar = {
    iconButton: true,
    text: t('export_candidates.title'),
    onClick: toggleExportDialog,
    tooltip: t('export_candidates.title'),
    icon: <ListAltIcon />
  }

  if (exportCandidates?.data?.attributes?.value === 'true') {
    appBarItems.rightItems.unshift(exportItemBar)
  }

  if (!isMobile) {
    appBarItems.rightItems.unshift(notifyItemBar)
  }

  if (isYuproClient) {
    appBarItems.rightItems.splice(1, 0, {
      iconButton: true,
      tooltip: t('header.buttons.yupro'),
      text: t('header.buttons.yupro'),
      onClick: () => handleYuProMenuOpen(),
      icon: (
        <>
          <GroupsIcon
            id="yupro-item"
            onClick={(e) => setYuproMenuAnchor(e.currentTarget)}
            fontSize="large"
          />

          <Menu
            id="lang-menu"
            anchorEl={yuproMenuAnchor}
            keepMounted
            open={Boolean(yuproMenuAnchor)}
            onClose={() => setYuproMenuAnchor(null)}
            style={{
              zIndex: theme.zIndex.tooltip + 1
            }}
          >
            <MenuItem
              onClick={(e) => {
                setYuproMenuAnchor(null)
                navigate('/yupro')
              }}
            >
              <Typography>{t('header.buttons.yupro')}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setYuproMenuAnchor(null)
                setOpenYuProFavorites(!openYuProFavorites)
              }}
            >
              {t('header.buttons.yupro_saveds_interests')}
            </MenuItem>
          </Menu>
        </>
      )
    })
  }

  let toolbarMargin = 30

  if (loggedIn) {
    toolbarMargin += 20
  }

  if (isMobile) {
    toolbarMargin += 20
  }

  return (
    <>
      <YuAppBar
        logo={user?.client_type === 'client_plonge' ? <PlongeIcon /> : 'yu'}
        backgroundColor={theme.palette.common.black}
        loggedIn={loggedIn && !!user}
        items={appBarItems}
        homeURL="/"
        mobileActionItem={notifyItemBar}
      />

      <ExportCandidateDialog open={openExportDialog} onClose={toggleExportDialog} />

      {loggedIn && !!user && (
        <YuProSaveds open={openYuProFavorites} onClose={() => setOpenYuProFavorites(false)} />
      )}

      {/* For debugging purposes, impersonate other users in Dev or staging, for Admins */}
      <UserIdSelector />

      <ScrollToTopControlller />

      <Toolbar style={{ marginBottom: toolbarMargin }} />

      <BackdropLoading open={logoutMutation.isLoading} />
    </>
  )
}

export default Header
