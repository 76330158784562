import { Grid } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import GraphCard from '@/components/dashboard/GraphCard'
import ChartInfoLabel from '@/components/dashboard/ChartInfoLabel'

type Props = {
  projectId?: string | number | undefined | null
}

const AveragePlacedDays: React.FC<Props> = ({ projectId }) => {
  const { data: numberInfo } = useQuery<{ value: number }>([
    'client_area',
    'overall',
    'average_placed_days',
    { project_id: projectId }
  ])
  const { t } = useTranslation('text')

  return (
    <GraphCard
      showInfoDialog
      infoText={t('homepage.cards.infos.average_placed_days')}
      height="100%"
      mobileHeight={150}
      onClick={undefined}
      headerStyles={{ padding: 0 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100% !important' }}
      >
        <Grid item>
          <ChartInfoLabel
            loading={!numberInfo}
            value={numberInfo?.value}
            subtext={t('homepage.graphs.label.days')}
            text={t('homepage.cards.label.average_placed_days')}
            errorMessage={numberInfo?.value === 0 ? t('generic_error.no_data_enough') : undefined}
          />
        </Grid>
      </Grid>
    </GraphCard>
  )
}

export default AveragePlacedDays
