type Props = {
  width?: number
  height?: number
}

const PlongeIcon: React.FC<Props> = ({ width = 200, height = 200 }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 200 200"
    enableBackground="new 0 0 200 200"
    xmlSpace="preserve"
  >
    <image
      id="image0"
      width="200"
      height="200"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAB2HAAAdhwGP5fFlAAABIHpUWHRSYXcgcHJvZmlsZSB0eXBlIHhtcAAAKJF1UktyxSAM
23OKHoHIxibHSR+w60yXPX5lkr6keS2e/GwjSyLp6+MzvcUSSJKHV9m8WvNszYqrLcjxbQ/rLlGT
BthiasNgRbY9/+weAHI6YZh8jy2laisZWcWGcyOyGLrkeUF63pAjSAEEN9kKVJPabf5eDA7VlZFl
48zhc6E7m9DnCMeQRdYIjCRZwAR4bzsIn+IrYUnbK1oMiPLJ5c5I1UpyMWFindJWutDJ8GigL+Bs
OhEMacQvoGt4TRdRAWR/yZqc+mk33+mfNQo+9oS0ZZo4Xqk/BfwM2DScWa0csg8F0unUI52o/zfe
vcG4urObk67uXI/2bI7DVYvazL78dVFJ30ktmxa/6wauAAAAAW9yTlQBz6J3mgAADulJREFUeNrt
3VuMJNddBvDvX32ZXexdJ7Y2CRZR4hCvE2MvjhUbiHiIDSJEwiIQ7wpFgihSIBIIEYUXCyRkSyQO
PPAQCXgId4lE9kZOSJQXZCsRWCSxveQCuyaJA3KMLXvJ2uvsjKe7q875eDjn9Jzpna6+TnfPzPeT
Wj1d3VV1quf861zq9ClARERERERERERERERERERERERERERERERERERERERERERERERERERERERE
REREREREREREREREREREREREdmDLTsCqIWBfvvfexrLTsUjvvPdeZwCXnQ5ZcTzAJwySB/bY6zSX
nYBVQdLMjM/+8Ude3+m59wHoedIKFMtO2u4wEoUZnS/aDf8pM3vuwZMnG6dOn3bLTtoqUYAkp08V
ANwrPXfj61919OPr3S4MBtun51USKMxQmOH8+vrjAJ572+te1wSgAMkoQJLT4cnA3vlLGyid6wJm
QFb1SLV026Ov02ICZjFGgKKFRg8Aetdco3bIAAVIchIhSHxhzVaByrkm6QuYwRDzmg1ktcHSZbVf
My6hGRAb5UVhhqrwBgA37vZ3vAcpQAY1Y7mBfv3K9slp1fJnkjALh6hMMNw+bYGKzIcCZFAV6+gA
QQKhEULs/esEzJ5pZiTCsVbLTtkKU+maxEY6Cs/KexCoYIUB3KpiLbuRPcdGOmIj3ZNF0xcEgHNL
+eJXmwIkiY10wtqvOXIF1rvdtQPRzVsYzl9abwNA+8KFfXq001OAJCcf9IDhR9qNc89e/OE9OAgX
CsO1UVtr8LsA8PXnn1dtS4bTUBMZpC9lgAYrioiIiIiIiIiIiIiIiIiIiIiIiIjIwmg075IMDC+3
ged8ZG3/b7P9Mn/E3qEAGRAz7iQ/JKvGzbgkC2zNA+DNzE+Qrga2/l8TrSvTU4Bk0vSj814vZu6m
mXV3eO8KAK8GcBTAYQANAD0AGwBeBnDRzHo7rNdGmHyhXPb3tp8pQKKUyUleCeA4whSc43w//2Vm
nZ2CJJZGrTyDk/xxAD8D4KcB3ATgjQCuAXDFwP4qAJcAnAfwNID/dM6daTQaj5vZd7PtNQAUChTZ
VTGjgeQdDEqSrubh4+dujus1B7bXf/1/5BGSv0nyYZLrnM0rJP+N5B+SfEu2vyIdg8jcZQHycxNm
2BNxvWbaTratQzEjPzewTkWyEx+9+DoPPBeXlSS72Werge30PPmFsuTP58cxGKwiM9uhBOnFDFrV
PDzJm+J6RZ4xSf4yyacHgiJt03M6Pq6ftpO/8SWSP5vtv73s71T2kSxA7ox5Lq9G1UlVrMPxuSD5
yez97gTbmjRYUsDkJcvfkLw6pqVFzVYyk3066dNChYnfzTZJXgXgqwA+iNDIrgC0Eb7neWdUi48m
tnq+PIAPAHiS5F1mVsaOB7VNpqQAmd0aAJA8BuArAG4D0EHItItsC6QqVQfAawB8nuT9AGBmjmqX
TEUBMrt1ACD5rwDeipBBD2E5XehF3HeJUJrc48nPAoCZVSRby/6y9hoFyOxKkg+Y2Q0ANhEy6Lgq
hKpRFyFTV9kjXz7pVfMWQoD2DHgPQwO+MLNSJclkFCDTSRcE1z3wMQCnEC4sjgoOxs+lOXCbCFWj
NYRM3cwe+fIiW2/cYLG4jQ6AdwJ4GOiXJGqTjEk9HBHJRqyr3wngEYSMmBrCQ1eL7w+50cC2z1UI
7ZIiW/gkvf96URTnAPwvwtASB2DNe38MwJtodqIwu8VCuyLJA2wcqdr3kJm9Nx7vVMNq5ICaoZt3
1OfS9Y/QN+v9WUf+QbfbvQljnqAuXLhwtCzLdznn/pbkxWzbnQm6hbsxsffH41R7RMY3Q4DUfSYP
jG9UVXVqh/02s0cjPvJl26rBGxsb1zrnPsqtISu9mNZx0pk+d1fcty4mynhmCJDaMzZJ58jfH9jX
GidoLJM0hot+7WzZG7z3n8v2V42RphSwz3PrYqIa7TLanAOkG0uN73W73RPZPtYGS4Qp0rktUJzj
h3cIgDqb8fmv8uMWqTXHAOnE4Pjq+fPnr4zbbM87I8ZAKQBgo9O5y/t+UkcFSRoUSZLvyI9dZKg5
BUgqOR5LmZe7WM9nqHqtAcBmWeajkEdVt0I66R+J21F3v9SbQ4CUIdPx6QsXLhyN21pIIzgFSbeq
Tsa0+DHSnoLozrgN9WrJcDMGSP9zG73ebXE7awtOfxsAnHP356XEqIAm+fm4vhrrMtyMAdIlydK5
P4rbWHj3aV5NIvnEGEGSjq3H+MtElSKXU91zdj0AbZLfefRfio8BwH333bfw2ymbmU+BWVX4SFzc
Rv3QlAphKMvdaTOLTrfsEVOWIOkHS+xV1fvj+ks7CzM02gsA8N4/lJUQw6T3vjL4PUigEmQ2FYAm
ie88+8wzD8RlS5uvKo6tagGAc+4TcXELw2/xnEqMW0jeMLBMoACZRRqoCNJ/+rrrruuQbJuZW3K6
egDQarW+TPJMXDYsaBvYGoV8+5LTvZIUINMjQunBoig+u+zEJPEntvFXjpbSNSxADFsjg9+ejov6
HXufAmR68bfoOGtm34zLFt44H8IBgCvwz/H1OF24N4XjWXoJuFIUINNL9fongNA4X6H5ch0AvPjC
C+dIPodQUgxLW8oDb2ScmUW26OLQjLz3/xH/XJlqSaxmFWa24b3/NoBrEYLmshMiicJCyo8BuBrA
s9j+I7ADTSXI9GIDnf+z7IQMUQCAmT0VX++Y4c36gX0EwKuWnehVowCZXgyQxg/i65U843rvXxyR
vrzku3KHZQeaAmR6BgBluZluabCaARJmRumnd4iUdg01GaAAmVVz5ZtxHHiWCShAppemHE09PytZ
LSnizI8jpLR3x/jsgaIAmR4BoGXNY/H1agZIUaTpguqmJAJCN/D6wLIDTwEyvViC+DcvOyFDeAAg
eX18bSM+dwnAS8tO9KpZ+Qr0HvC2+LwqFwnTpHCe5BGG28kBYdzVTjwAmNkLTz31VAoQlSCRSpDp
pesMbz979mx7xab0bABAVVU3G/BahCAY9r9OwfDf119/vdogAxQg0zMAMLM3HT9+PI2EXZUSuQEA
RdF8V3xdN77KAMB7/y2gPwWrSpBIATK9/kjYotl8b1y29GpWHGLSBQAr8J64eNj/2SMGdVEUX8uO
SyIFyBwUwKmL5KtX5PYCLQAg+QsGnECoQtUFSAHg5U6n88SS072SFCCzaSDcv+PaI95/IC5b2hk4
/ty2F/7Gh+PiCiO6eEk+fvjw4e/HZUsvBVeJAmQ2RobMVxTFPdwqRZb1vTbNjGVZ/qIZ3o0QAMM6
Dvoli5n9ExCmKlqhIfsrQQEyIzM0QfYAHPPe/2lcvPDerNj26JFsNJrNP4uLS9T3XjUA/BDAQwBw
5swZBYfsbMZ5scg4U2FVVXfH7UxyK7Z5pL8FAJVzfx7TU3vvEO99mjju7/L1RXY0rwDx3ndI3hi3
tZAgSb9Bryp+ME/LGFMWkeQtcRvL7lyQVTaHACGz+29sbm6+IW5v16YgJVkwThbXrapfzdIx6oY6
HZJ0zj2QH7vIUHMKEHJrhvcXut3uzWnb8zxDM0wQ15/etKqq38j2X45IXxWLD8+tKUcVIFJvjgGS
B0mvqqpfy/bR5gx1/RQYebA5x49PEBz90oPkR+M2VbWS0eYcIGQ25acj/36D/NFsX62Y0cfqRYwl
0La7U/XI2733X8vSOs59CtNk1udSYKj0kLHsQoCQoTqTGswvOefu2yCvHdivcftNO7fd1HMwnb1e
71bn3D8MZPpx78ab3B73rZt4ynhmCJBqxOc8sy5X7/3LlXP/WFXVr5B87Thp65Bvds59yJMPZ7da
y0uEUTy3SrTfjcep4BiDBqZFDKNYHcMdlx5BGHJhGO87chh9cTDNutiv8xO4ZMCTHvg2gGfg/UUU
RQVgDR7XFAWuI3kjzI7b9u2nC4DjVI+IMPxkzXv/l41G47fTsS77O5c9ZMISJC3f8N4/Gv8ep4Gc
7lVecrxrFbm0ziTVvrz0+lR2rBpBMSZ9UdNJpcrhsiw/BOAzCCVDB/W/xjOE77yJcPYnQsnSQ5gw
oZc90usqfi6tM26p7+O6awA+bWbvA/olpYaUjEkBMhtDu+3N7CSARxFuI9DD+CNiDSHjtxEycjt7
pNdNTF4VTlWwFoBPZsHRVNVqMgqQGVlZXhH/vAPAFxEyNhEy6aJ5hFIsXWu5x8x+C+iXHKsy+/ye
oQCZUavVCr/eM6vM7JcAfAKhKtRCyKyL0kMoaQ4B+D6AO83sTxiGoxQqOaajAJldmsR6DQDM7PcA
nALwIkJmTaUJMd/ZQtL2KoRetHZMy18A+Akz+xLDHa+oNsf0FCDzU8WzddPMTgO4AcBfI2TaVnx2
CMHiMF2wcGAbqQ3TIPkwgJ8ys98xs/UYHL0p9yOy3YTdvLkTcb00dMOYjbcieSvJz3jvB7t1e7EL
tpt14Q4+yuxznR26kkvv/RdI3pEfBzW+am70Rc5ZnDIn/ey2MLN/B3A3ybd64NcL4C6E251NO2ix
B+CbCB0CDxZF8STQv7ZhamvMl66kR5z+SvpPmtm3YtWq2mG7TSA04tPrEri1BbwDwG0A3gLgxwAc
xfYTlgewCeAHAJ4GcM45d8Y599ja2tq5PN0IgbiMXrN9TyXILssCow2AMSM/Fh+I713d6XSuOnTo
0GCAvALgJTO7rDcsbg+xnaFSY5coQBYkZuT+GR+xwR17mV5E6PUaKlXZEG/ImbYnu0sBsmCxjeCA
foN+VDWufwMcXehbPAXIEmVz4KordkXpOohIDQXIjNQQ2N8UICI1FCCzUhGyrylAZtRuq4G9nylA
RGooQERqKEBEaihARGooQERqKEBEaihARGooQERqKEBEaihARGooQERqKEBEaihARGooQERqKEBE
amjShsul33c41J9Axr09m+xhCpDLpUw/zY1rZJ9RFetyNvA87udlH9I/NyJpZkaSV1XAzQBcWVU2
OMN0mgA3LW82m98ws1fS+ss+DpFdE2c5XNh6svr0jx0QM/skbbNKJYeIiIiIiIiIiIiIiIiIiIiI
iIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIgcMP8P3DUUL+hmf60A
AACEZVhJZk1NACoAAAAIAAUBEgADAAAAAQABAAABGgAFAAAAAQAAAEoBGwAFAAAAAQAAAFIBKAAD
AAAAAQACAACHaQAEAAAAAQAAAFoAAAAAAAAAwAAAAAEAAADAAAAAAQADoAEAAwAAAAEAAQAAoAIA
BAAAAAEAAADIoAMABAAAAAEAAADIAAAAAIypA88AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDkt
MTNUMTc6MzY6MjMrMDA6MDBVrKGWAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTA5LTEzVDE3OjM2
OjIzKzAwOjAwJPEZKgAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAAMjAyMi0wOS0xM1QxNzozNjoyMysw
MDowMHPkOPUAAAARdEVYdGV4aWY6Q29sb3JTcGFjZQAxD5sCSQAAABJ0RVh0ZXhpZjpFeGlmT2Zm
c2V0ADkwWYzemwAAABh0RVh0ZXhpZjpQaXhlbFhEaW1lbnNpb24AMjAwRHT+NAAAABh0RVh0ZXhp
ZjpQaXhlbFlEaW1lbnNpb24AMjAw2XsfQgAAABJ0RVh0dGlmZjpPcmllbnRhdGlvbgAxt6v8OwAA
AABJRU5ErkJggg=="
    />
  </svg>
)

export default PlongeIcon
