import { useTranslation } from '@yu/i18n'

import { Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useRequiredParams } from '@/hooks'
import { ResponsibleCompany } from '@/types/graphs_blacklists'

type Params = {
  uuid: string
}

const ActionCooldownContext: React.FC = () => {
  const { t } = useTranslation()
  const { uuid } = useRequiredParams<Params>()
  const { data: responsibleCompany } = useQuery<ResponsibleCompany>([
    'client_area',
    'jobs',
    uuid,
    'responsible_company'
  ])

  return (
    <Typography variant="subtitle1">
      {t('report.actions.dialog.cooldown', {
        company: responsibleCompany === 'plonge' ? 'Plongê' : 'Yu'
      })}
    </Typography>
  )
}

export default ActionCooldownContext
