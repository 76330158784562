import { Link } from 'react-router-dom'

import { useTranslation } from '@yu/i18n'
import {
  CardMedia,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  CardActionArea,
  Typography,
  Theme,
  useMediaQuery,
  useTheme
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { yellow } from '@mui/material/colors'

import dayjs from 'dayjs'

import { useMemo } from 'react'
import { truncatedText } from '@/helpers'
import CandidateImage from '@/components/candidates/CandidateImage'
import { Interest, InterestStatus } from '@/types/interest'
import ActionInterestButtons from '@/components/brand/interests/ActionInterestButtons'

export function colorFromStatus(theme: Theme, status?: InterestStatus): string {
  switch (status) {
    case 'in_interview':
    case 'in_offer':
      return theme.palette.success.dark
    case 'placed':
      return theme.palette.primary.light
    case 'approved_by_client':
    case 'available_to_client':
      return yellow[900]
    default:
      return theme.palette.success.dark
  }
}

function colorFromDateReceived(theme: Theme, initial: Date) {
  const today = new Date()
  const defaultColor = theme.palette.getContrastText(theme.palette.background.paper)

  const difference = dayjs(today).diff(dayjs(initial), 'day')

  if (difference >= 14) {
    return theme.palette.error.main
  }

  if (difference >= 7) {
    return yellow[800]
  }

  return defaultColor
}

type Props = {
  interest: Interest
}

type StyleProps = {
  status?: InterestStatus
  isMobile: boolean
  initial_date: Date
  final_date?: Date
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    height: '100%',
    borderLeft: '7px solid red',
    borderRight: 'none',
    borderBottom: 'none',
    borderColor: ({ status }) => colorFromStatus(theme, status),
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    paddingBottom: 0,
    paddingtop: 5,
    paddingLeft: 8
  },
  subHeader: {
    maxWidth: theme.spacing(30),
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  content: {
    paddingLeft: 8,
    paddingBottom: 8
  },
  statusText: {
    color: ({ status }) => colorFromStatus(theme, status),
    fontWeight: theme.typography.fontWeightBold
  },
  sentToClientDaysText: {
    color: ({ initial_date }) => colorFromDateReceived(theme, initial_date),
    fontWeight: theme.typography.fontWeightBold
  },
  actionArea: {
    height: 'initial'
  },
  noTextWrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

const StyledCardHeader = withStyles(() => ({
  title: {
    fontSize: 18
  },
  subheader: {
    fontSize: 13,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))(CardHeader)

const InterestCard: React.FC<Props> = ({ interest }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles({
    status: interest.attributes.status,
    isMobile,
    initial_date: interest.attributes.updated_at
  })
  const { t } = useTranslation(['text', 'candidates', 'grammar'])

  const { person } = interest.attributes
  const daysWithClient = dayjs(new Date()).diff(dayjs(interest.attributes.updated_at), 'day')

  const statusText = useMemo(() => {
    if (!interest.attributes.status) {
      return '' as InterestStatus
    }
    return interest.attributes.status as InterestStatus
  }, [interest.attributes.status])

  return (
    <Card variant="outlined" classes={{ root: classes.card }}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia>
          <Link
            to={`/person/reports/${interest.attributes.person?.data.attributes.uuid}/${interest.id}`}
            target="_blank"
          >
            <CandidateImage src={interest.attributes.person?.data.attributes.avatar || ''} />
          </Link>
        </CardMedia>
      </CardActionArea>

      <StyledCardHeader
        className={classes.header}
        subheader={(
          <div className={classes.subHeader}>
            <Tooltip title={person?.data.attributes.last_job || ''}>
              <span className={classes.noTextWrap}>
                {truncatedText(person?.data.attributes.last_job, isMobile ? 40 : 28)}
              </span>
            </Tooltip>

            <br />

            <Tooltip title={person?.data.attributes.last_company || ''}>
              <span className={classes.noTextWrap}>
                {truncatedText(person?.data.attributes.last_company, isMobile ? 40 : 24)}
              </span>
            </Tooltip>
          </div>
        )}
        title={(
          <Tooltip title={person?.data.attributes.name || ''}>
            <span className={classes.noTextWrap}>
              {truncatedText(person?.data.attributes.name, 16)}
            </span>
          </Tooltip>
        )}
      />

      <CardContent className={classes.content}>
        <Typography variant="caption">
          <strong>
            {t('text:status')}
            :
          </strong>
          {' '}
          <span className={classes.statusText}>
            {t(`text:homepage.tables.yupro.status.${statusText}.label`)}
          </span>
        </Typography>

        <br />

        <Typography variant="caption">
          <strong>
            {/* eslint-disable-next-line i18next/no-literal-string */
            'Demonstrou interesse em:'
            }
          </strong>
          {' '}
          <span className={classes.statusText}>
            {dayjs(interest.attributes.created_at).format('DD/MM/YYYY')}
          </span>
        </Typography>

        <br />

        <Typography variant="caption">
          <Tooltip title={t('candidates:label.see_process_duration')}>
            <strong className={classes.sentToClientDaysText}>
              {daysWithClient}
              {' '}
              {t('candidates:label.days')}
              {' '}
              {t('candidates:label.in_process')}
              {' '}
              {/* eslint-disable-next-line i18next/no-literal-string */}
              {' ⏰'}
            </strong>
          </Tooltip>
        </Typography>

        <br />

        <ActionInterestButtons interest={interest} small />
      </CardContent>
    </Card>
  )
}

export default InterestCard
