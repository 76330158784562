import { Grid } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { getJobGraph } from '@/api/jobs'
import { useRequiredParams } from '@/hooks'
import CandidatesChrolopleth from '@/components/dashboard/CandidatesChrolopleth'
import GraphCard from '@/components/dashboard/GraphCard'
import { textOrNothing } from '@/helpers'
import { CandidatesGeoData } from '@/types/dashboards'
import DefaultSkeleton from './DefaultSkeleton'

type Params = {
  uuid: string
}

type Props = {
  overall?: boolean
}

const CandidatesChroloplethCard: React.FC<Props> = ({ overall }) => {
  const { uuid } = useRequiredParams<Params>()
  const { t } = useTranslation(['text'])

  const { data: geo_data_by_job, isFetched: by_job_fetched } = useQuery(
    [uuid, 'candidates_geolocations'],
    () => getJobGraph(uuid, 'candidates_geolocations'),
    { enabled: !overall }
  )
  const { data: overall_geo_data, isFetched: overall_fetched } = useQuery<CandidatesGeoData>(
    ['client_area', 'overall', 'geolocations'],
    { enabled: overall }
  )

  const geolocationDataBool = !(geo_data_by_job == null && overall_geo_data == null)
  const fetched = by_job_fetched || overall_fetched
  const noGeolocationsData = fetched && !geolocationDataBool
  const data = overall ? overall_geo_data : geo_data_by_job

  return (
    <>
      {noGeolocationsData || (
        <Grid item xs={12} md={6}>
          <GraphCard
            title={textOrNothing(t('text:dashboard.geo_graph'), geolocationDataBool)}
            showInfoDialog
            infoText={t('text:dashboard.info.geo_graph')}
          >
            {geolocationDataBool ? <CandidatesChrolopleth data={data} /> : <DefaultSkeleton />}
          </GraphCard>
        </Grid>
      )}
    </>
  )
}

CandidatesChroloplethCard.defaultProps = {
  overall: false
}

export default CandidatesChroloplethCard
