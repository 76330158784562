import { useMemo } from 'react'
import { useTheme, Grid, useMediaQuery } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { ResponsiveSunburst } from '@nivo/sunburst'
import { ResponsivePie } from '@nivo/pie'
import BasicTextTooltip from '@/components/dashboard/BasicTextTooltip'
import { legendProps } from '@/hooks/nivo'
import { deepClone } from '@/helpers'
import { Rejection } from '@/types/dashboards'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%'
  },
  rejectionGraph: {
    height: '80%',
    marginTop: '3%'
  },
  legendsStyle: {
    marginTop: '2%'
  }
}))

type Props = {
  data: Rejection | undefined
}

const CommonRejectionsSunburst: React.FC<Props> = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const backgroundColor = theme.palette.background.paper
  const borderColor = backgroundColor

  const sm = useMediaQuery(theme.breakpoints.down('sm'))

  const pluckRejectionTypes = useMemo(() => {
    if (!data) return []

    const rejectionTypes: Record<string, any>[] = []
    data.children?.forEach((rejection) => {
      rejectionTypes.push({
        id: rejection.name,
        label: rejection.name,
        value: Math.random()
      })
    })
    return rejectionTypes
  }, [data])

  const parsedDataForMobile = useMemo(() => {
    // Mobile view will not have the extra children layers.
    if (!sm) return data
    if (!data || !data.children) return data

    const newData = deepClone(data)

    newData.children?.forEach((_rejectionType: string, rejectionIndex: number) => {
      if (newData.children) {
        let countRejectionReasons = 0
        // eslint-disable-next-line
        // @ts-ignore
        data.children[rejectionIndex].children.forEach(
          (rejectionByReason: Record<string, string | number>) => {
            countRejectionReasons += rejectionByReason.count as number
          }
        )
        newData.children[rejectionIndex].children = []
        newData.children[rejectionIndex].count = countRejectionReasons
      }
    })

    return newData
  }, [data, sm])

  return (
    <Grid container sx={{ height: sm ? '60%' : '100%' }}>
      <Grid item xs={sm ? 12 : 6} md={sm ? 12 : 6} className={classes.rejectionGraph}>
        <ResponsiveSunburst
          data={parsedDataForMobile}
          margin={{
            top: 0,
            right: 30,
            bottom: 0,
            left: 10
          }}
          id="name"
          value="count"
          cornerRadius={2}
          borderColor={borderColor}
          childColor={{ from: 'color', modifiers: [['brighter', 0.1]] }}
          enableArcLabels
          arcLabelsSkipAngle={10}
          borderWidth={2}
          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 1.4]] }}
          arcLabel={(label) => label.formattedValue.replace(/\.[0-9]*?(?=%)/, '')}
          tooltip={(input) => (
            <BasicTextTooltip
              text={input.id as string}
              value={input.value}
              color={input.color as string}
            />
          )}
        />
      </Grid>
      {/* As of 24 Jun 2021, Sunburst does not support legends. The pie below is
      hidden, only used to generate the legends. */}
      <Grid item xs={sm ? 12 : 6} md={sm ? 12 : 6} className={classes.legendsStyle}>
        <ResponsivePie
          data={pluckRejectionTypes}
          margin={{
            top: sm ? 0 : -10,
            right: 70,
            bottom: 0,
            left: 51
          }}
          layers={['legends']}
          legends={[
            {
              ...legendProps,
              direction: 'column',
              translateY: sm ? 10 : 20,
              anchor: 'top',
              itemTextColor: '#999',
              itemWidth: 70,
              symbolSize: sm ? 10 : 15,
              itemsSpacing: sm ? 10 : 29
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default CommonRejectionsSunburst
