import { useEffect, useMemo } from 'react'

import { useForm, useWatch } from 'react-hook-form'

import { useQuery } from '@tanstack/react-query'

import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import ListIcon from '@mui/icons-material/List'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'

import { useTranslation } from '@yu/i18n'
import { CustomColumn, Option } from '@/types/candidate_csv'

import api from '@/api'
import FormAutocomplete from '../shared/inputs/FormAutocomplete'
import FormTextField from '../shared/inputs/FormTextField'

type CustomColumnItemProps = {
  columnName: string
  questionMeanings: string[]
  onRemove: () => void
  jobApplicationIds?: number[]
}

export const CustomColumnItem: React.FC<CustomColumnItemProps> = ({
  columnName,
  questionMeanings,
  onRemove,
  jobApplicationIds
}) => {
  const { t } = useTranslation()

  const { data: hasAnswers, isInitialLoading } = useQuery<boolean>(
    ['client_area', 'candidate_csvs', 'has_answers', jobApplicationIds, questionMeanings],
    async () => (
      await api.post('client_area/candidate_csvs/has_answers', {
        job_application_ids: jobApplicationIds,
        meanings: questionMeanings
      })
    ).data,
    { enabled: !!jobApplicationIds?.length }
  )

  return (
    <Card variant="outlined" sx={{ mb: 2, p: 1 }}>
      <ListItem
        secondaryAction={(
          <IconButton color="secondary" onClick={onRemove}>
            <ClearIcon />
          </IconButton>
        )}
      >
        <Stack width="100%" display="flex" gap={2} alignItems="center" direction="row">
          <Avatar>
            <ListIcon />
          </Avatar>

          <Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('export_candidates.columns.custom_columns.column')}
              </Typography>
              <Typography>{columnName}</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={1}>
              <Typography sx={{ fontWeight: 'bold' }}>
                {t('export_candidates.columns.custom_columns.questions')}
              </Typography>
              {questionMeanings.map((questionMeaning, index) => (
                <Chip
                  variant="outlined"
                  size="small"
                  key={`export-candidates-custom-meaning-${questionMeaning}}-${index.toString()}`}
                  label={questionMeaning}
                />
              ))}
            </Stack>
          </Stack>

          {!isInitialLoading && !hasAnswers && (
            <>
              <Box flexGrow={1} />

              <Tooltip title={t('export_candidates.columns.custom_columns.no_answers.subtitle')}>
                <Alert
                  variant="outlined"
                  sx={{ justifySelf: 'flex-end', mr: 2 }}
                  severity="warning"
                >
                  {t('export_candidates.columns.custom_columns.no_answers.title')}
                </Alert>
              </Tooltip>
            </>
          )}
        </Stack>
      </ListItem>
    </Card>
  )
}

type CustomColumnsFormProps = {
  addCustomColumn: (body: CustomColumn) => void
  jobApplicationIds: number[]
  loading: boolean
}

export const CustomColumnsForm: React.FC<CustomColumnsFormProps> = ({
  addCustomColumn,
  jobApplicationIds,
  loading
}) => {
  const { t } = useTranslation()

  const {
    control, watch, reset, handleSubmit, setValue
  } = useForm<CustomColumn>({
    defaultValues: {
      question_meanings: []
    }
  })

  const { data: questionMeanings, isInitialLoading } = useQuery<Option<string>[]>(
    ['client_area', 'candidate_csvs', 'question_meanings', jobApplicationIds],
    async () => (
      await api.post('client_area/candidate_csvs/question_meanings', {
        job_application_ids: jobApplicationIds
      })
    ).data,
    {
      enabled: !!jobApplicationIds.length
    }
  )

  const disabled = useMemo(
    () => !watch('column_name') || !watch('question_meanings').length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useWatch({ control })]
  )

  useEffect(() => {
    reset({ question_meanings: [] })
  }, [jobApplicationIds, reset])

  return (
    <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
      <Grid item xs={7}>
        <FormAutocomplete
          setValue={setValue}
          variant="outlined"
          options={questionMeanings}
          loading={isInitialLoading || loading}
          name="question_meanings"
          control={control}
          multiple
          label={t('export_candidates.columns.custom_columns.questions')}
          placeholder={t('export_candidates.columns.custom_columns.questions_example')}
        />
      </Grid>

      <Grid item xs={3}>
        <FormTextField
          variant="outlined"
          loading={isInitialLoading}
          name="column_name"
          control={control}
          label={t('export_candidates.columns.custom_columns.column_name')}
          placeholder={t('export_candidates.columns.custom_columns.column_name_example')}
        />
      </Grid>

      <Grid item xs={2}>
        <LoadingButton
          loading={isInitialLoading}
          fullWidth
          onClick={handleSubmit((body) => {
            addCustomColumn(body)
            reset({ column_name: '', question_meanings: [] })
          })}
          variant="outlined"
          disabled={disabled}
          endIcon={<AddIcon />}
        >
          {t('export_candidates.columns.custom_columns.add')}
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
