import {
  AccordionProps, Box, ListItemText, Tooltip
} from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { useTranslation } from '@yu/i18n'
import { JobPositionAttributes } from '@/types/job_position'
import { APIBasicJobInfoDatum } from '@/types/jobs'
import ListItemLink from '@/components/shared/ListItemLink'
import JobInfoCell from './JobInfoCell'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  backgroundColor: 'transparent',
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: `0 0 0 ${theme.spacing(2)}`,
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  margin: theme.spacing(3),
  marginTop: 0,
  padding: `0 0 0 ${theme.spacing(2)}`,
  border: 'none',
  borderLeft: '1px solid #e0e0e0'
}))

type Props = {
  jobAttributes: JobPositionAttributes
}

const JobTitleCell: React.FC<Props> = ({ jobAttributes }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const getJob = React.useCallback(
    (jobId: number | string) => queryClient.getQueryData<APIBasicJobInfoDatum>(['client_area', 'jobs', 'info', jobId]),
    [queryClient]
  )

  const job = getJob(jobAttributes.job_id)
  const uuid = job?.data?.attributes?.uuid
  const title = job?.data?.attributes?.title
  const comment = job?.data?.attributes?.last_comment

  const innerJobPositionCell = (
    <ListItemText
      primary={(
        <JobInfoCell
          jobId={jobAttributes.job_id}
          fieldKey="title"
          withoutTooltip
          TypographyProps={{
            sx: {
              fontWeight: 'bold'
            }
          }}
        />
      )}
      secondaryTypographyProps={{ variant: 'caption' }}
      secondary={jobAttributes.description}
    />
  )

  if (!uuid) {
    // Job info is still loading
    return innerJobPositionCell
  }

  return (
    <Box>
      <ListItemLink to={`/vaga/${uuid}/candidatos`}>
        <Tooltip
          title={(
            <>
              {title}
              {jobAttributes.description && (
                <>
                  <br />
                  {jobAttributes.description}
                </>
              )}
            </>
          )}
        >
          {innerJobPositionCell}
        </Tooltip>
      </ListItemLink>

      {comment && (
        <Tooltip title={t('homepage.tables.job_positions.tips.last_comment')} followCursor>
          <Accordion>
            <AccordionSummary>
              {t('homepage.tables.job_positions.column.last_comment')}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  width: 500,
                  whiteSpace: 'unset',
                  wordBreak: 'break-word'
                }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: comment }}
              />
            </AccordionDetails>
          </Accordion>
        </Tooltip>
      )}
    </Box>
  )
}

export default JobTitleCell
