import {
  Button, Slide, Box, styled, BoxProps, Tooltip
} from '@mui/material'
import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import {
  forwardRef, ReactElement, Ref, useMemo, useState
} from 'react'
import { useTranslation } from '@yu/i18n'
import { TransitionProps } from 'react-transition-group/Transition'
import { BaseDialog } from 'yu-open-lib'
import { InterestAttributes } from '@/types/interest'
import { APIPersonData, CandidateAttributes } from '@/types/candidates'
import { BaseAPISerializer } from '@/types/api'
import { useViewport } from '@/hooks'
import CompareTable from './CompareTable'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement
    },
    ref: Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
)

type Props = {
  candidates?: CandidateAttributes[]
  interests?: BaseAPISerializer<InterestAttributes>[]
  tribeName?: string
  tribeButton?: boolean
}

const BoxBackground = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  width: '25px',
  backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
  left: 0,
  zIndex: 9999,
  backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))'
}))

const CompareDialog: React.FC<Props> = ({
  candidates, interests, tribeName, tribeButton
}) => {
  const { t } = useTranslation(['candidates'])
  const [open, setOpen] = useState(false)
  const enableCompare = useMemo(() => {
    if (!!candidates && candidates.length >= 2) return true
    if (!!interests && interests.length >= 2) return true
    return false
  }, [candidates, interests])
  const { isMobile } = useViewport()
  const fullScreen = useMemo(() => isMobile, [isMobile])

  const interestCandidates = useMemo(() => {
    if (!interests) return candidates
    return interests.map((interest) => {
      const person = interest?.attributes?.person?.data as APIPersonData
      return {
        id: interest.id,
        name: person?.attributes?.name,
        avatar: person?.attributes?.avatar,
        uuid: person?.attributes?.uuid,
        tribe_name: interest?.attributes?.tribe_name,
        status: interest?.attributes?.status,
        salary: interest?.attributes?.salary,
        person
      }
    })
  }, [interests, candidates])

  const fromInterest = useMemo(() => !!interests, [interests])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {enableCompare && !tribeButton && (
        <Button
          onClick={handleClickOpen}
          sx={{ alignSelf: 'flex-end', marginBottom: '15px' }}
          variant="outlined"
        >
          {t('candidates:compare_button')}
        </Button>
      )}
      {tribeButton && (
        <Tooltip title={t('candidates:compare_button_tooltip')}>
          <Button
            onClick={handleClickOpen}
            variant="outlined"
            disabled={!enableCompare}
            size="small"
            endIcon={<CompareArrowsIcon />}
          >
            {t('candidates:compare_button')}
          </Button>
        </Tooltip>
      )}
      <BaseDialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="lg"
        title={fullScreen ? t('candidates:label.candidates') : t('candidates:compare_title')}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <BoxBackground />
        <CompareTable
          candidates={interestCandidates as CandidateAttributes[]}
          tribeName={tribeName ? [tribeName] : undefined}
          fromInterest={fromInterest}
        />
      </BaseDialog>
    </>
  )
}

export default CompareDialog
