// src/utils/trpc.ts
import { type inferRouterInputs, type inferRouterOutputs } from '@trpc/server'
import { createTRPCReact } from '@trpc/react-query'

import type { AppRouter } from '@yu/client-api'

export const clientApi = createTRPCReact<AppRouter>({})

/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 * */
export type RouterInputs = inferRouterInputs<AppRouter>

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 * */
export type RouterOutputs = inferRouterOutputs<AppRouter>
