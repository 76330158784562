import { useTranslation } from '@yu/i18n'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import { forwardRef, useMemo, useState } from 'react'
import { TransitionProps } from 'react-transition-group/Transition'
import GraphCard from '@/components/dashboard/GraphCard'
import { useGetSavedInterests } from '@/api/interests'
import { APIPersonData, CandidateAttributes } from '@/types/candidates'
import CompareTable from '@/components/candidates/CompareTable/CompareTable'

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
)

type Props = {
  open: boolean
  onClose: () => void
}

const YuProSaveds: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState<string | false>('panel')

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const interests = useGetSavedInterests()

  const interestsGrouped = useMemo(() => {
    if (!interests?.data?.length) {
      return Object.create(null)
    }
    const data = interests?.data?.reduce((r, interest) => {
      const obj = r
      if (interest.attributes.tribe_name) {
        const person = interest?.attributes?.person?.data as APIPersonData
        const interestToCandidate = ({
          id: interest.id,
          name: person?.attributes?.name,
          avatar: person?.attributes?.avatar,
          uuid: person?.attributes?.uuid,
          tribe_name: interest?.attributes?.tribe_name,
          status: interest?.attributes?.status,
          salary: interest?.attributes?.salary,
          person
        } as unknown) as CandidateAttributes
        obj[interest.attributes.tribe_name] = obj[interest.attributes.tribe_name] || []
        obj[interest.attributes.tribe_name].push(interestToCandidate)
      }
      return obj
    }, Object.create(null))
    return data
  }, [interests?.data])

  return (
    <div>
      <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 'bold',
                ml: 2,
                mb: 1,
                mt: 1,
                textTransform: 'uppercase',
                fontSize: '1.5rem'
              }}
            >
              {t('homepage.yupro.saveds_page.title')}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container sx={{ margin: '30px calc(auto-30px)' }}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <GraphCard
              height={220}
              overflowAuto
              headerStyles={{
                marginBottom: '10px'
              }}
              title={t('homepage.yupro.saveds_page.description')}
              cardProps={{
                sx: {
                  width: '100%'
                }
              }}
              cardContentProps={{
                sx: {
                  height: '100% !important',
                  width: '100%'
                }
              }}
            >
              {!!interestsGrouped
                && Object.keys(interestsGrouped).map((tribeName) => (
                  <Accordion
                    expanded={expanded === tribeName}
                    onChange={handleChange(tribeName)}
                    disableGutters
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '98%' }}
                      >
                        <Typography variant="h6">{tribeName}</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          {interestsGrouped[tribeName].length}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardContent>
                          <CompareTable
                            key={tribeName}
                            candidates={interestsGrouped[tribeName]}
                            tribeName={[tribeName]}
                            fromInterest
                          />
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </GraphCard>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}

export default YuProSaveds
