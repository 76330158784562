import { Autocomplete, Box, TextField } from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid'
import { useTranslation } from '@yu/i18n'
import {
  JobPositionsFilters,
  JobPositionsStatusOption,
  TypeOption
} from '../homepage/jobs/JobPositionsTable'

type Props = {
  filters: JobPositionsFilters
  setFilters: React.Dispatch<React.SetStateAction<JobPositionsFilters>>
  statusOptions: JobPositionsStatusOption[]
  membersOptions: TypeOption[] | undefined
  isMobile?: boolean
  search?: string | null
  setSearch?: React.Dispatch<React.SetStateAction<string | null>>
}

const QuickSearchToolbar: React.FC<Props> = ({
  filters,
  setFilters,
  statusOptions,
  membersOptions,
  isMobile = false,
  search,
  setSearch
}) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        ml: '10px',
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box sx={{ display: 'flex', minWidth: '500px', flexDirection: isMobile ? 'column' : 'flex' }}>
        <Autocomplete
          size="small"
          sx={{
            minWidth: '150px',
            mr: '5%',
            width: isMobile ? '50%' : '100%',
            mb: isMobile ? '2%' : 0
          }}
          defaultValue={filters.status}
          options={statusOptions}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              status: value
            }))
          }}
        />
        <Autocomplete
          size="small"
          sx={{
            minWidth: '200px',
            mr: '5%',
            width: isMobile ? '50%' : '100%'
          }}
          defaultValue={filters.membershipEmail}
          options={membersOptions || []}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t('job.members.manager')} />
          )}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              membershipEmail: value
            }))
          }}
        />
      </Box>
      {!isMobile && (
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) => searchInput
            .split(',')
            .map((value) => value.trim())
            .filter((value) => value !== '')}
          placeholder="Vaga"
          variant="outlined"
          size="small"
        />
      )}
    </Box>
  )
}

export default QuickSearchToolbar
