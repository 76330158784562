/* eslint-disable react/require-default-props */
import React, { ReactElement, useEffect } from 'react'

import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch
} from 'react-hook-form'
import { useTranslation } from '@yu/i18n'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete, Checkbox, SxProps, TextField, TextFieldProps, Theme
} from '@mui/material'

import { Option } from '@/types/candidate_csv'

type Props<T extends FieldValues> = {
  options?: Option<any>[]
  control: Control<T>
  name: Path<T>
  setValue: UseFormSetValue<T>
  sx?: SxProps<Theme>
  variant?: 'standard' | 'filled' | 'outlined'
  label?: string
  multiple?: boolean
  loading?: boolean
  i18nKey?: string
  groupByI18nKey?: string
  placeholder?: string
}

const FormAutocomplete = <T extends FieldValues>({
  options = [],
  control,
  name,
  sx = {},
  variant = 'standard',
  multiple = false,
  loading = false,
  label = undefined,
  i18nKey = undefined,
  groupByI18nKey = undefined,
  setValue,
  placeholder = undefined
}: Props<T>): ReactElement<any, any> => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { [`${name}`]: value } = useWatch({ control })

  useEffect(() => {
    if (
      !options?.find((option) => option.value === value)
      && options?.length
      && !!value
      && !multiple
    ) {
      setValue(name, null as PathValue<T, Path<T>>)
    }
  }, [value, options, multiple, setValue, name])

  return (
    <Controller
      render={({ field: { onChange, value: fieldValue, ...field } }) => (
        <Autocomplete
          {...field}
          value={multiple ? fieldValue || [] : fieldValue || ''}
          sx={sx}
          options={options?.map((option) => option.value) || []}
          fullWidth
          onChange={(_e, data) => onChange(data)}
          renderOption={(p, v, { selected }) => {
            let optionLabel = options?.find((option) => (option.value as any) === v)?.label || v

            if (optionLabel && i18nKey) {
              optionLabel = t(`${i18nKey}.${optionLabel}` as any)
            }

            return multiple ? (
              <li {...p}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {optionLabel}
              </li>
            ) : (
              <li {...p}>{optionLabel}</li>
            )
          }}
          groupBy={(v) => {
            const group = options?.find((option) => (option.value as any) === v)?.group_by || ''

            if (groupByI18nKey && group) {
              return t(`${groupByI18nKey}.${group}` as any)
            }

            return group
          }}
          multiple={multiple}
          limitTags={2}
          size="small"
          disabled={loading}
          getOptionLabel={(v) => {
            const optionLabel = options?.find((option) => (option.value as any) === v)?.label || ''

            if (optionLabel && i18nKey) {
              return t(`${i18nKey}.${optionLabel}` as any)
            }

            return optionLabel
          }}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField
              {...params}
              onChange={params.onChange}
              variant={variant}
              placeholder={value?.toString()?.length ? '' : placeholder}
              focused={!!placeholder}
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: loading ? (
                  <LoadingButton sx={{ height: '25px' }} loading disabled />
                ) : (
                  params.InputProps?.endAdornment
                )
              }}
            />
          )}
        />
      )}
      name={name}
      control={control}
    />
  )
}

export default FormAutocomplete
