import { useTranslation } from '@yu/i18n'
import { InterestRejectionTemplate } from '@/types/interest'

export const useInterestRejectionTemplates = (): {
  interestRejectionTemplates: InterestRejectionTemplate[]
} => {
  const { t } = useTranslation('text')

  return {
    interestRejectionTemplates: [
      {
        reason: t('homepage.tables.yupro.rejection.reason.career_move'),
        rejection_type: 'no_interest'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.communication'),
        rejection_type: 'behavior'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.cultural_fit'),
        rejection_type: 'behavior'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.junior'),
        rejection_type: 'seniority'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.leveraged_pay'),
        rejection_type: 'compensation'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.no_availability'),
        rejection_type: 'other'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.no_engagement'),
        rejection_type: 'no_interest'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.no_reply'),
        rejection_type: 'no_interest'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.others'),
        rejection_type: 'other'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.others'),
        rejection_type: 'behavior'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.senior'),
        rejection_type: 'seniority'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.technical_profile_no_job'),
        rejection_type: 'technical'
      },
      {
        reason: t('homepage.tables.yupro.rejection.reason.technical_profile_no_need'),
        rejection_type: 'technical'
      }
    ]
  }
}
