import {
  Grid, Stack, useMediaQuery, useTheme
} from '@mui/material'

import CommonRejectionsSunburstCard from '@/components/dashboard_cards/CommonRejectionsSunburstCard'
import AveragePlacedDays from '../candidates/cards/AveragePlacedDays'
import CandidatesPerPosition from '../candidates/cards/CandidatesPerPosition'
import ClientStepNumber from '../candidates/cards/ClientStepNumber'
import AverageStepDays from './graphs/AverageStepDays'
import PositionPie from './graphs/PositionPie'
import StepsBarGraph from './graphs/StepsBarGraph'
import StepsFunnel from './graphs/StepsFunnel'
import CandidatesChroloplethCard from '../dashboard_cards/CandidatesChroloplethCard'
import DiversityCard from '../dashboard_cards/DiversityCard'

type ChildrenProps = {
  children?: React.ReactNode
}

const ContainerNumberStack: React.FC<ChildrenProps> = ({ children }) => (
  <Stack
    spacing={2}
    direction={{
      lg: 'row',
      md: 'row',
      sm: 'column',
      xs: 'column'
    }}
  >
    {children}
  </Stack>
)

const NumberStack: React.FC<ChildrenProps> = ({ children }) => (
  <Stack
    sx={{
      width: {
        lg: '100%',
        md: '100%',
        sm: '100%',
        xs: '100%'
      },
      height: {
        lg: 230,
        md: 200,
        sm: 180,
        xs: 180
      }
    }}
  >
    {children}
  </Stack>
)

type Props = {
  projectId?: string | number | undefined | null
}

const ExecutiveVision: React.FC<Props> = ({ projectId }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container spacing={2}>
      <Grid
        container
        spacing={2}
        lg={6}
        md={12}
        sm={12}
        xs={12}
        sx={{
          margin: '0'
        }}
      >
        <Grid item xs={12}>
          <ContainerNumberStack>
            <NumberStack>
              <AveragePlacedDays projectId={projectId} />
            </NumberStack>

            <NumberStack>
              <CandidatesPerPosition projectId={projectId} />
            </NumberStack>

            <NumberStack>
              <ClientStepNumber projectId={projectId} />
            </NumberStack>
          </ContainerNumberStack>
        </Grid>
        <Grid item xs={12}>
          <PositionPie projectId={projectId} />
        </Grid>
      </Grid>

      {isMobile ? (
        <Grid
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <StepsBarGraph projectId={projectId} />
          {/* <AverageStepDays projectId={projectId} /> */}
        </Grid>
      ) : (
        <Grid
          container
          lg={6}
          md={12}
          sm={12}
          xs={12}
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: '0'
          }}
        >
          <Grid item xs={12} sx={{ height: '60%' }}>
            <StepsFunnel projectId={projectId} />
          </Grid>
          <Grid item xs={12} sx={{ height: 'calc(40% - 20px)' }}>
            <AverageStepDays projectId={projectId} />
          </Grid>
        </Grid>
      )}
      <CommonRejectionsSunburstCard overall />

      <CandidatesChroloplethCard overall />

      <DiversityCard overall />
    </Grid>
  )
}

export default ExecutiveVision
