import { useEffect, useState } from 'react'

type Props<T> = {
  key: string
  defaultValue: T
}

const useLocalStorageState: <T>(
  props: Props<T>
) => [T, React.Dispatch<React.SetStateAction<T>>] = ({ key, defaultValue }) => {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key)
    if (valueInLocalStorage) {
      try {
        return JSON.parse(valueInLocalStorage)
      } catch (error) {
        window.localStorage.removeItem(key)
      }
    }

    return defaultValue
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, setState]
}

export default useLocalStorageState
