import { useState } from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'

import { BrowserTracing } from '@sentry/tracing'
import { Replay } from '@sentry/replay'
import App from '@/App'
import Error500 from './components/errors/Error500'

type SentryEnvironment = 'development' | 'production' | 'staging'

if (import.meta.env.VITE_APP_SENTRY_DSN) {
  let environment: SentryEnvironment = import.meta.env.MODE

  if (window.location.hostname.includes('staging')) {
    environment = 'staging'
  }

  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new Replay({
        // Capture 10% of all sessions
        sessionSampleRate: 0.1,
        // Of the remaining 90% of sessions, if an error happens start capturing
        errorSampleRate: 1.0
      })
    ],
    ignoreErrors: [/.*ResizeObserver loop limit.*/],
    environment
  })
}

const AppWithErrorBoundary: React.FC = () => {
  const [boundaryError, setBoundaryError] = useState<Error>()

  return (
    <Sentry.ErrorBoundary
      onError={(error) => {
        if (import.meta.env.DEV) {
          setBoundaryError(error)
        }
      }}
      fallback={<Error500 boundaryError={boundaryError} />}
      showDialog
      dialogOptions={{ lang: 'pt-br' }}
    >
      <App />
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.render(<AppWithErrorBoundary />, document.getElementById('root'))
