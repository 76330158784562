import {
  Badge, BadgeProps, Box, ListItemText, Typography, styled
} from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from '@yu/i18n'
import { Link } from 'react-router-dom'
import { JobPosition } from '@/types/job_position'
import { useJobInfo } from '@/hooks/jobs'
import ListItemLink from '@/components/shared/ListItemLink'
import JobInfoCell from './JobInfoCell'
import JobPositionStatusChip from './JobPositionStatusChip'

type Props = {
  position: JobPosition
}

const StatusBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  width: '100%',
  '& .MuiBadge-badge': {
    right: 40,
    top: 30,
    position: 'relative'
  }
}))

const JobPositionItem: React.FC<Props> = ({ position }) => {
  const { t } = useTranslation()
  const finishedAt = position.attributes.canceled_at || position.attributes.placed_at

  const firstLine = finishedAt
    ? t(`position.finished.${position.attributes.status}` as const, {
      date: dayjs(finishedAt).format('LL'),
      days_difference: dayjs(finishedAt).diff(dayjs(position.attributes.open_at), 'day')
    })
    : t('position.open_for', {
      days_difference: dayjs().diff(dayjs(position.attributes.open_at), 'day')
    })

  const { data: job } = useJobInfo(position.attributes.job_id)

  const jobLink = `/vaga/${job?.data.attributes.uuid}/candidatos`

  return (
    <StatusBadge
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      badgeContent={(
        <Link to={jobLink}>
          <JobPositionStatusChip position={position} size="small" />
        </Link>
      )}
    >
      <ListItemLink
        to={jobLink}
        sx={{
          width: '100%'
        }}
      >
        <ListItemText
          primary={
            <JobInfoCell jobId={position.attributes.job_id} fieldKey="title" withoutTooltip />
          }
          secondaryTypographyProps={{ variant: 'caption' }}
          secondary={(
            <>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {position.attributes.description}
              </Typography>

              {position.attributes.description && ' — '}

              {position?.attributes?.status === ('open' || 'open_wait_for_customer')
              && !job?.data?.attributes?.published
                ? ''
                : firstLine}

              <br />

              {t('job.information.candidates.active_and_offer', {
                active: job?.meta?.candidate_counts.active,
                offer: job?.meta?.candidate_counts.offer || 0
              })}

              <div style={{ paddingBottom: 20 }} />

              {job?.data.attributes.last_comment && (
                <Box>
                  <Typography variant="body2">
                    {t('homepage.tables.job_positions.column.last_comment')}
                  </Typography>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: job.data.attributes.last_comment }}
                  />
                </Box>
              )}
            </>
          )}
        />
      </ListItemLink>
    </StatusBadge>
  )
}

export default JobPositionItem
