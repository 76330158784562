import React, { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { useMutation, useQuery } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'

import DownloadIcon from '@mui/icons-material/Download'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from '@mui/material'

import { useToggle } from 'yu-open-lib'

import { useTranslation } from '@yu/i18n'
import useFormPersist from '@/hooks/useFormPersist'
import useLocalStorageState from '@/hooks/useLocalStorageState'
import {
  APICandidateCsvDatum,
  CandidateCsvFiltersOptions,
  CandidateCsvForm,
  CustomColumn
} from '@/types/candidate_csv'

import api from '@/api'
import FormAutocomplete from '../shared/inputs/FormAutocomplete'
import FormToggle from '../shared/inputs/FormToggle'
import ExportCandidateAlert from './ExportCandidateAlert'
import { CustomColumnItem, CustomColumnsForm } from './ExportCandidateCustomColumn'

type Props = {
  open: boolean
  onClose: () => void
}

const ExportCandidateDialog: React.FC<Props> = ({ open, onClose }) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const [customColumns, setCustomColumns] = useLocalStorageState<CustomColumn[]>({
    key: 'export-candidates-custom-columns',
    defaultValue: []
  })

  const [openCsv, setOpenCsv] = useState(false)
  const [openAlertDialog, toggleAlertDialog] = useToggle(false)

  const defaultValues: Partial<CandidateCsvForm> = {
    step: 'client',
    status: 'active'
  }

  const {
    handleSubmit, control, watch, setValue
  } = useFormPersist<CandidateCsvForm>({
    defaultValues,
    storageKey: 'export-candidates-form',
    trigger: open
  })

  const [id, setId] = useState<number | string | null>(null)

  const { data: candidateCsv, isInitialLoading } = useQuery<APICandidateCsvDatum>(
    ['client_area', 'candidate_csvs', id],
    {
      enabled: id !== null && open,
      refetchInterval: 5000
    }
  )

  const {
    user_id, brand_id, step, status, job_id
  } = watch()

  const {
    data: filterOptions, isInitialLoading: isFilterLoading
  } = useQuery<CandidateCsvFiltersOptions>(
    [
      'candidates_csvs',
      'filter_options',
      {
        user_id,
        brand_id,
        step,
        status,
        job_id
      }
    ],
    async () => (await api.post('candidate_csvs/filter_options', watch())).data,
    {
      enabled: open
    }
  )

  useEffect(() => {
    if (candidateCsv?.data.attributes.status === 'finished' && openCsv) {
      toggleAlertDialog()
      setOpenCsv(false)
    }
  }, [candidateCsv?.data?.attributes, openCsv, setOpenCsv, toggleAlertDialog])

  const exportCandidates = useMutation(
    (body: CandidateCsvForm) => api.post('client_area/candidate_csvs/export_candidates', {
      default_columns: body.default_columns,
      custom_columns: customColumns,
      ids: filterOptions?.job_application_ids,
      model: 'JobApplication'
    }),
    {
      onSuccess(response: AxiosResponse<APICandidateCsvDatum>) {
        setId(response?.data.data.id)
        setOpenCsv(true)
      },
      onError() {
        addToast('Erro ao tentar exportar os candidatos, tente novamente mais tarde', {
          appearance: 'error'
        })
      }
    }
  )

  return (
    <>
      <ExportCandidateAlert
        candidateCsv={candidateCsv?.data.attributes}
        open={openAlertDialog}
        onClose={toggleAlertDialog}
      />

      <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
        <Card sx={{ height: '100%', overflowY: 'scroll' }}>
          <DialogTitle>
            <Typography variant="h5">
              <strong>{t('export_candidates.title')}</strong>
            </Typography>
            {' '}
          </DialogTitle>
          <DialogContent>
            <Card variant="outlined" sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {t('export_candidates.filters.title')}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormAutocomplete
                    variant="outlined"
                    setValue={setValue}
                    control={control}
                    options={filterOptions?.steps}
                    loading={isFilterLoading}
                    i18nKey="evaluation_step.type"
                    name="step"
                    label={t('export_candidates.filters.step')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormAutocomplete
                    variant="outlined"
                    setValue={setValue}
                    control={control}
                    options={filterOptions?.statuses}
                    loading={isFilterLoading}
                    i18nKey="job_application.status"
                    name="status"
                    label={t('export_candidates.filters.status')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormAutocomplete
                    variant="outlined"
                    setValue={setValue}
                    control={control}
                    options={filterOptions?.brands}
                    loading={isFilterLoading}
                    name="brand_id"
                    label={t('export_candidates.filters.brand')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormAutocomplete
                    variant="outlined"
                    setValue={setValue}
                    control={control}
                    options={filterOptions?.users}
                    loading={isFilterLoading}
                    name="user_id"
                    label={t('export_candidates.filters.member')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormAutocomplete
                    variant="outlined"
                    setValue={setValue}
                    control={control}
                    options={filterOptions?.jobs}
                    loading={isFilterLoading}
                    groupByI18nKey="job.status"
                    name="job_id"
                    label={t('export_candidates.filters.job')}
                  />
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mt: 2 }}>
                {t('export_candidates.columns.default_columns.title')}
              </Typography>
              <Typography variant="subtitle1" color="GrayText">
                {t('export_candidates.columns.default_columns.subtitle')}
              </Typography>

              <Stack>
                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.manager"
                  control={control}
                  label={t('export_candidates.columns.default_columns.manager')}
                />

                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.job_and_position"
                  control={control}
                  label={t('export_candidates.columns.default_columns.job_and_position')}
                  disabled
                  defaultValue
                />

                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.name"
                  control={control}
                  label={t('export_candidates.columns.default_columns.name')}
                  disabled
                  defaultValue
                />

                <FormToggle
                  name="default_columns.candidate_link"
                  control={control}
                  label={t('export_candidates.columns.default_columns.candidate_link')}
                />

                <FormToggle
                  name="default_columns.diversity"
                  control={control}
                  label={t('export_candidates.columns.default_columns.diversity')}
                />

                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.status"
                  control={control}
                  label={t('export_candidates.columns.default_columns.status')}
                  disabled
                  defaultValue
                />

                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.step"
                  control={control}
                  label={t('export_candidates.columns.default_columns.step')}
                  disabled
                  defaultValue
                />

                <FormToggle
                  name="default_columns.linkedin"
                  control={control}
                  label={t('export_candidates.columns.default_columns.linkedin')}
                />

                <FormToggle
                  name="default_columns.phone_number"
                  control={control}
                  label={t('export_candidates.columns.default_columns.phone_number')}
                />

                <FormToggle
                  disabledText={t('export_candidates.columns.default_columns.disabled_text')}
                  name="default_columns.updated_at"
                  control={control}
                  label={t('export_candidates.columns.default_columns.updated_at')}
                  disabled
                  defaultValue
                />
              </Stack>
            </Card>

            <Box my={2} />

            <Card variant="outlined" sx={{ p: 2 }}>
              <Typography variant="h6">
                {t('export_candidates.columns.custom_columns.title')}
              </Typography>
              <Typography variant="subtitle1" color="GrayText">
                {t('export_candidates.columns.custom_columns.subtitle')}
              </Typography>

              <Box my={2} />

              {customColumns.map((customColumn) => (
                <CustomColumnItem
                  jobApplicationIds={filterOptions?.job_application_ids}
                  key={`CustomColumnItem-${JSON.stringify(customColumn)}`}
                  columnName={customColumn.column_name}
                  questionMeanings={customColumn.question_meanings}
                  onRemove={() => {
                    setCustomColumns((oldColumns) => oldColumns.filter(
                      (oldColumn) => oldColumn.column_name !== customColumn.column_name
                    ))
                  }}
                />
              ))}

              <CustomColumnsForm
                jobApplicationIds={filterOptions?.job_application_ids || []}
                loading={isFilterLoading}
                addCustomColumn={(newColumn) => {
                  setCustomColumns((oldColumns) => {
                    if (
                      // eslint-disable-next-line max-len
                      oldColumns.find(
                        (oldColumn) => oldColumn.column_name === newColumn.column_name
                      )
                    ) {
                      return oldColumns.map((oldColumn) => {
                        if (oldColumn.column_name === newColumn.column_name) {
                          // eslint-disable-next-line no-param-reassign
                          oldColumn.question_meanings = newColumn.question_meanings
                        }
                        return oldColumn
                      })
                    }
                    return [...oldColumns, newColumn]
                  })
                }}
              />
            </Card>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                onClose()
                setId(null)
              }}
            >
              {t('export_candidates.actions.cancel')}
            </Button>
            <LoadingButton
              loading={
                isInitialLoading
                || isFilterLoading
                || exportCandidates.isLoading
                || candidateCsv?.data.attributes.status === 'pending'
              }
              variant="contained"
              loadingPosition="end"
              disabled={!filterOptions?.job_applications_total}
              endIcon={<DownloadIcon />}
              onClick={handleSubmit((body) => {
                exportCandidates.mutate(body)
              })}
            >
              {t('export_candidates.actions.export')}
              {' '}
              {filterOptions?.job_applications_total}
              {' '}
              {t('export_candidates.actions.candidates')}
            </LoadingButton>
          </DialogActions>
        </Card>
      </Dialog>
    </>
  )
}

export default ExportCandidateDialog
