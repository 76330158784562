import {
  Grid, useMediaQuery, Autocomplete, TextField, createTheme
} from '@mui/material'
import { Welcome } from 'yu-open-lib'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import JobPositionsList from '@/components/homepage/jobs/JobPositionsList'
import JobPositionsTable, {
  JobPositionsStatusOption,
  TypeOption
} from '@/components/homepage/jobs/JobPositionsTable'
import { useCurrentUser } from '@/hooks/auth'
import GraphCard from '@/components/dashboard/GraphCard'
import { APIMeetingData } from '@/types/meeting'
import { APIProjectData } from '@/types/projects'
import ExecutiveVision from '@/components/homepage/ExecutiveVision'
import JobInConstructionList from '@/components/homepage/jobs/JobInConstructionList'
import { useTitle } from '@/hooks'
import JobMeetings from '../components/meetings/JobMeetings'

const Homepage: React.FC = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1536
      }
    }
  })
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const { user } = useCurrentUser()
  const { t } = useTranslation()
  const [projectId, setProjectId] = useState<string | undefined>('')

  const { data: meetings } = useQuery<APIMeetingData>([
    'candidates_area',
    'jobs',
    'meetings',
    'user'
  ])
  const { data: projects } = useQuery<APIProjectData>(['client_area', 'jobs', 'projects'])

  useTitle(`YU - ${t('titles.client_area')}`)

  const projectsNames = useMemo(
    () => projects?.data?.map((item) => {
      if (!projects?.data?.length) return { value: '', label: '' }
      const project: TypeOption = {
        value: item?.id,
        label: item?.attributes?.title ? item?.attributes?.title : ''
      }
      return project
    }),
    [projects]
  )

  const jobPositionsStatusOptions: JobPositionsStatusOption[] = [
    {
      value: ['open', 'open_wait_for_customer'],
      label: t('position.status.open')
    },
    {
      value: ['on_hold'],
      label: t('position.status.on_hold')
    },
    {
      value: ['placed', 'closed_internal_indication'],
      label: t('position.status.placed')
    },
    {
      value: ['canceled'],
      label: t('position.status.canceled')
    }
  ]

  const memoizedWelcome = useMemo(() => <Welcome name={user?.first_name || ''} />, [
    user?.first_name
  ])

  return (
    <>
      {/* {isAtsClient && (
        <>
          <Link component="a" sx={{ color: 'white' }} href="ats_job">
            <Button variant="contained">Criar nova vaga</Button>
          </Link>

          <Box mb={2} />
        </>
      )} */}

      <GraphCard height="100%" sx={{ width: '100%', display: 'flex', mb: 1.5 }}>
        {memoizedWelcome}
      </GraphCard>

      {projectsNames && projectsNames.length > 0 && (
        <GraphCard height="100%" sx={{ width: '100%', display: 'flex', mb: 1.5 }}>
          <Autocomplete
            size="small"
            sx={{
              minWidth: '150px',
              mr: '5%',
              width: isMobile ? '50%' : '100%',
              mb: isMobile ? '2%' : 0
            }}
            options={projectsNames as TypeOption[]}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={`${t('homepage.projects.label')}`} />
            )}
            onChange={(event, value) => setProjectId(value?.value)}
          />
        </GraphCard>
      )}

      {meetings?.data && meetings?.data.length !== 0 && (
        <Grid item xs={12} sx={{ mb: 1.5 }}>
          <GraphCard
            height={230}
            mobileHeight={600}
            title={t('home.meetings.meetings')}
            overflowAuto
          >
            <JobMeetings meetings={meetings} showInterviewer={false} showIntervieweeLabel />
          </GraphCard>
        </Grid>
      )}

      <ExecutiveVision projectId={projectId} />

      <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
        {isMobile ? (
          <JobPositionsList
            jobPositionsStatusOptions={jobPositionsStatusOptions}
            projectId={projectId}
          />
        ) : (
          <JobPositionsTable
            jobPositionsStatusOptions={jobPositionsStatusOptions}
            projectId={projectId}
          />
        )}
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
        <JobInConstructionList projectId={projectId} />
      </Grid>
    </>
  )
}

export default Homepage
