import { Autocomplete, Box, TextField } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { JobInConstructionFilter, TypeOption } from './JobInConstructionList'

type Props = {
  filters: JobInConstructionFilter
  setFilters: React.Dispatch<React.SetStateAction<JobInConstructionFilter>>
  membersOptions: TypeOption[] | undefined
  isMobile?: boolean
  search?: string | null
  setSearch?: React.Dispatch<React.SetStateAction<string | null>>
}

const QuickSearchBarInConstruction: React.FC<Props> = ({
  filters,
  setFilters,
  membersOptions,
  isMobile = false,
  search,
  setSearch
}) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box sx={{ display: 'flex', minWidth: '500px', flexDirection: isMobile ? 'column' : 'flex' }}>
        <Autocomplete
          size="small"
          sx={{
            minWidth: '200px',
            width: isMobile ? '200px' : '500px'
          }}
          defaultValue={filters.membershipEmail}
          options={membersOptions || []}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t('job.members.manager')} />
          )}
          onChange={(_, value) => {
            setFilters((prev) => ({
              ...prev,
              membershipEmail: value
            }))
          }}
        />
      </Box>
    </Box>
  )
}

export default QuickSearchBarInConstruction
