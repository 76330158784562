import { QueryObserverResult, RefetchOptions, useQuery } from '@tanstack/react-query'

import * as Sentry from '@sentry/react'

import { CurrentUserResponse } from '@/api/authentication'
import { APIYuriUserData } from '@/types/users'

import { getUserLang, setUserLang } from '@/helpers/language'

interface UseCurrentUserHook {
  user?: APIYuriUserData['attributes']
  loggedIn: boolean
  userRole?: APIYuriUserData['attributes']['role']
  userCanManage: boolean
  isUserYu: boolean
  isUserAdmin: boolean
  isClient: boolean
  isAdminOrClient: boolean
  isYuproClient: boolean
  isAtsClient: boolean
  isInitialLoading: boolean
  savedInterestsIds?: string[]
  error: unknown
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<CurrentUserResponse, unknown>>
}

export function getCookie(key: string): string | undefined {
  const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)
  return b ? b.pop() : ''
}

export const currentUserCacheKey = ['users', 'current']

export const useCurrentUser = (): UseCurrentUserHook => {
  const {
    error, data, refetch, isInitialLoading
  } = useQuery<CurrentUserResponse>({
    queryKey: currentUserCacheKey,
    cacheTime: 1000 * 60 * 15,
    staleTime: 1000 * 60 * 60,
    onSuccess: (newData) => {
      if (newData?.data?.attributes?.locale && getUserLang() !== newData.data.attributes.locale) {
        setUserLang(newData.data.attributes.locale as 'en' | 'pt' | 'es')
      }
    }
  })

  const user = data?.data?.attributes
  const savedInterestsIds = data?.data?.attributes?.saved_interests

  const isUserAdmin = user?.role === 'admin'
  const isUserYu = user?.role === 'team'
  const isClient = user?.role === 'client'
  const isAdminOrClient = !!user && ['client', 'admin'].includes(user.role)
  const userCanManage = user?.role === 'team' || user?.role === 'partner' || user?.role === 'admin'
  const userRole = user?.role
  const isYuproClient = !!user?.is_yu_pro_client
  const isAtsClient = !!user?.authorization_role_titles?.split(', ').includes('Clientes ATS')

  const loggedIn = !!getCookie('client_logged_in')

  if (user) {
    Sentry.setUser({ ...user, id: user.id.toString() })
  }

  return {
    user,
    savedInterestsIds,
    loggedIn,
    isClient,
    userRole,
    userCanManage,
    isAdminOrClient,
    isUserYu,
    isUserAdmin,
    refetch,
    error,
    isYuproClient,
    isInitialLoading,
    isAtsClient
  }
}
