import { GridRowSelectionModel } from '@mui/x-data-grid'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { APIJobPositionData } from '@/types/job_position'

export const useHoverPosition = (): {
  onMouseEnter: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
  selectionModel: GridRowSelectionModel
  clearSelectionModel: () => void
} => {
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  const { data: positions } = useQuery<APIJobPositionData>(['client_area', 'jobs', 'positions'])

  const getPosition = (id: number | string) => positions?.data?.find(
    (p) => p.id.toString() === id.toString()
  )

  const onMouseEnter = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    event.preventDefault()
    const positionId = event.currentTarget.getAttribute('data-id')

    if (positionId) {
      const position = getPosition(positionId)

      if (position && positions?.data) {
        const positionIds = positions.data
          .filter((p) => p.attributes.job_id === position.attributes.job_id)
          .map((p) => p.id)

        if (JSON.stringify(positionIds) !== JSON.stringify(selectionModel)) {
          setSelectionModel(positionIds)
        }
      }
    }
  }

  const clearSelectionModel = () => {
    if (!selectionModel.length) return

    setSelectionModel([])
  }

  return {
    onMouseEnter,
    selectionModel,
    clearSelectionModel
  }
}
