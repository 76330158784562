import { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Tooltip,
  Theme,
  CardActionArea,
  SxProps,
  CardContentProps,
  Skeleton,
  Grid,
  CardProps
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import { useTranslation } from '@yu/i18n'

import { useViewport } from 'yu-open-lib'

import DialogTitle from '@/components/shared/DialogTitle'

type BaseProps = {
  title?: React.ReactNode
  mobileHeight?: number | string
  height?: number | string
  headerStyles?: React.CSSProperties
  SecondaryAction?: React.FC
  overflowAuto?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  cardContentProps?: CardContentProps
  cardProps?: CardProps
  /** @default false */
  loading?: boolean
  subtitle?: string
}

type NoInfoDialogProps = {
  showInfoDialog?: never
  infoText?: never
}

type InfoDialogProps = {
  showInfoDialog: true
  infoText: string
}

type Props = BaseProps &
  (NoInfoDialogProps | InfoDialogProps) & {
    children?: React.ReactNode
  }

type StyleProps = {
  isMobile: boolean
  mobileHeight: number | string
  height: number | string
  overflowAuto?: boolean
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    height: '100%',
    borderRadius: 8
  },
  header: {
    paddingBottom: 0,
    '& > .MuiCardHeader-content': {
      '& > .MuiCardHeader-title': {
        fontSize: ({ isMobile }: { isMobile: boolean }) => (isMobile ? '1.1rem' : '1.5rem')
      }
    }
  },
  infoText: {
    whiteSpace: 'pre-wrap'
  },
  content: {
    whiteSpace: 'pre-wrap',
    height: ({ isMobile, mobileHeight, height }) => (isMobile ? mobileHeight : height),
    width: '100%',
    overflow: ({ overflowAuto }) => (overflowAuto ? 'auto' : 'visible'),
    '&::-webkit-scrollbar': {
      width: '5px !important',
      height: '5px !important'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.divider
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.paper,
      border: () => {
        const color = theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey[400]
        return `1px solid ${color} !important`
      },
      borderColor: theme.palette.background.paper,
      borderRadius: '1px !important'
    }
  }
}))

const GraphCard: React.FC<Props> = ({
  title,
  infoText,
  children,
  headerStyles,
  onClick,
  SecondaryAction,
  showInfoDialog = false,
  height = 400,
  mobileHeight = height,
  overflowAuto = false,
  sx,
  cardContentProps,
  cardProps,
  loading = false,
  subtitle
}) => {
  const [infoDialog, setInfoDialog] = useState(false)
  const { t } = useTranslation()
  const { isMobile } = useViewport()
  const classes = useStyles({
    isMobile,
    mobileHeight,
    height,
    overflowAuto
  })

  const toggleInfoDialog = () => setInfoDialog((dialog) => !dialog)

  const cardContent = loading ? (
    <Grid>
      <Skeleton
        variant="rectangular"
        height={`calc(${typeof height === 'number' ? `${height.toString()}px` : height} - ${
          title ? '55px' : '-15px'
        })`}
      />
    </Grid>
  ) : (
    <>
      {showInfoDialog && (
        <Dialog open={infoDialog} onClose={toggleInfoDialog}>
          <DialogTitle onClose={toggleInfoDialog}>{title}</DialogTitle>

          <DialogContent>
            <Typography variant="body1" className={classes.infoText}>
              {infoText}
            </Typography>
          </DialogContent>
        </Dialog>
      )}

      {!title && showInfoDialog ? (
        <CardHeader
          sx={{
            position: 'relative'
          }}
          color="inherit"
          style={headerStyles}
          action={(
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                right: '5px',
                top: '5px'
              }}
            >
              {SecondaryAction && <SecondaryAction />}
              {showInfoDialog && (
                <Tooltip title={t('dashboard.more_info')}>
                  <IconButton onClick={toggleInfoDialog} size="large">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        />
      ) : (
        <CardHeader
          color="inherit"
          title={title}
          className={classes.header}
          style={headerStyles}
          action={(
            <div style={{ display: 'flex' }}>
              {SecondaryAction && <SecondaryAction />}
              {showInfoDialog && (
                <Tooltip title={t('dashboard.more_info')}>
                  <IconButton onClick={toggleInfoDialog} size="large">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        />
      )}

      {!!subtitle && (
        <Typography
          variant="subtitle1"
          sx={{ marginLeft: '18px', fontWeight: 'fontWeightBold', opacity: '0.8' }}
        >
          {subtitle}
        </Typography>
      )}

      <CardContent className={classes.content} {...cardContentProps}>
        {children}
      </CardContent>
    </>
  )

  if (onClick) {
    return (
      <Card variant="outlined" className={classes.root} sx={sx} {...cardProps}>
        <CardActionArea onClick={onClick} sx={{ width: '100%', height: '100%' }}>
          {cardContent}
        </CardActionArea>
      </Card>
    )
  }

  return (
    <Card variant="outlined" className={classes.root} sx={sx} {...cardProps}>
      {cardContent}
    </Card>
  )
}

export default GraphCard
