import { useReducer, useState } from 'react'

import { CookiesWarning, YuToastProvider } from 'yu-open-lib'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useTranslation, initI18n } from '@yu/i18n'
import dayjs from 'dayjs'
import AdapterDayjs from '@mui/lab/AdapterDayjs'

import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { makeStyles } from '@mui/styles'
import { BrowserRouter } from 'react-router-dom'
import { themeReducer, ThemeContext, theme } from '@/hooks/theme'
import Routes from '@/Routes'
import api, { defaultQueryFn } from '@/api'
import { setupDayJs } from './helpers/dayjs'
import '@/styles/index.scss'

import ApiProfiler from './components/devtools/ApiProfiler'
import { getUserLang } from './helpers/language'
import { usePersistReactQueryWithIndexedDB } from './api/usePersistReactQueryWithIndexedDB'
import { ProvideLocationContext } from './hooks/location'
import { clientApi } from './api/clientApi'
import { httpBatchLink } from '@trpc/client'
import { transformer } from '@yu/client-api/transformer'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

setupDayJs()
initI18n()

const useStyles = makeStyles(() => ({
  // https://github.com/mui/material-ui/issues/16367
  root: {
    '@media (prefers-reduced-motion: reduce)': {
      '& *': {
        animationDuration: '0.001ms !important',
        animationIterationCount: '1 !important',
        transitionDuration: '0.001ms !important'
      }
    }
  }
}))

const App: React.FC = () => {
  const [queryClient] = useState(
    () => new QueryClient({
      defaultOptions: {
        queries: {
          queryFn: defaultQueryFn,
          notifyOnChangeProps: 'all',
          staleTime: 5000,
          // Persistent data cache, keep for 24h
          cacheTime: 1000 * 60 * 60 * 24
        }
      }
    })
  )

  const { i18n } = useTranslation()

  const [trpcClient] = useState(() => clientApi.createClient({
    transformer,
    links: [
      httpBatchLink({
        url: import.meta.env.VITE_APP_CLIENT_API_URL || 'VITE_APP_CLIENT_API_URL_NOT_FOUND',
        fetch(url, options) {
          return fetch(url, {
            ...options,
            credentials: 'include',
            headers: {
              ...options?.headers,
              locale: i18n.language,
              'X-USER-TOKEN': localStorage.getItem('client_api_token') || '',
              'X-USER-EMAIL': localStorage.getItem('client_api_email') || ''
            }
          })
        }
      })
    ]
  }))

  const [state, dispatch] = useReducer(themeReducer, {
    darkMode: JSON.parse(localStorage.getItem('darkTheme') || 'true')
  })
  const classes = useStyles()

  usePersistReactQueryWithIndexedDB(queryClient)

  if (i18n.language !== 'pt') {
    dayjs.locale('en')
    api.defaults.params = { ...api.defaults.params, locale: i18n.language }
  } else {
    dayjs.locale('pt-br')
    api.defaults.params = { ...api.defaults.params, locale: null }
  }

  return (
    <>
      <ThemeContext.Provider value={{ ...state, dispatch }}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={{ ...theme(state.darkMode) }}>
            <CssBaseline />

{/*             <LocalizationProvider dateAdapter={AdapterDayjs}> */}
              <clientApi.Provider client={trpcClient} queryClient={queryClient}>
                <QueryClientProvider client={queryClient}>
                  <div className={classes.root}>
                    <YuToastProvider>
                      <CookiesWarning
                        url="https://jornadayu.com/politica-de-privacidade/"
                        locale={getUserLang()}
                      />
                      <BrowserRouter>
                        <ProvideLocationContext>
                          <Routes />
                        </ProvideLocationContext>
                      </BrowserRouter>
                    </YuToastProvider>

                    {import.meta.env.DEV && (
                      <>
                        <ApiProfiler />

                        {/* react-query DevTools */}
                        <ReactQueryDevtools initialIsOpen={false} />
                      </>
                    )}
                  </div>
                </QueryClientProvider>
              </clientApi.Provider>
{/*             </LocalizationProvider> */}
          </ThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
    </>
  )
}

export default App
