import { BaseAPISerializer, DataAPISerializer } from '@/types/api'
import { PersonAttributes } from '@/types/candidates'
import { APIRejectionData, RejectionType } from './rejection'

export type InterestRejectionTemplate = {
  reason: string
  rejection_type: RejectionType
}

export type InterestStatus =
  | 'placed'
  | 'in_offer'
  | 'in_interview'
  | 'approved_by_client'
  | 'available_to_client'

export const interestsCacheKey = ['client_area', 'interests']

export interface InterestAttributes {
  interest_type?: string
  created_at?: Date
  updated_at: Date
  updated_by_user_name?: string
  updated_by_user_email?: string
  status?: InterestStatus
  rejections?: APIRejectionData
  passed_client?: boolean
  brand_name?: string
  person_id?: number | string
  person?: DataAPISerializer<BaseAPISerializer<Partial<PersonAttributes>>>
  next_status?: InterestStatus
  tribe_name?: string
  id: number | string
  salary?: string
}

export interface InterestTribeCounts {
  tribe_id: number
  total: number
  passed_client: number
}

export type Interest = BaseAPISerializer<InterestAttributes>
export type APIInterestDatum = DataAPISerializer<Interest>
export type APIInterestData = DataAPISerializer<Interest[]>

export type APIInterestTribeCountDatum = InterestTribeCounts
