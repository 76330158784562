import { useEffect, useRef } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { useParams } from 'react-router-dom'

export function useTitle(
  title?: string,
  options = {
    restoreOnUnmount: false
  }
): void {
  const prevTitleRef = useRef(document.title)
  document.title = title || 'YU - Área do Cliente'

  useEffect(() => {
    const { current } = prevTitleRef

    if (options && options.restoreOnUnmount) {
      return () => {
        document.title = current
      }
    }

    return () => null
  }, [prevTitleRef, options])
}

export function useViewport(): { isMobile: boolean } {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return { isMobile }
}

// useRequiredParams hook is used to get the params from the url and make them required
export const useRequiredParams = <T extends Record<string, unknown>>(): T => useParams() as T

export function useIntersectionObserver({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true
}: {
  root: any
  target: any
  onIntersect: any
  threshold: number
  rootMargin: string
  enabled: boolean
}): void {
  // https://github.com/tannerlinsley/react-query/blob/master/examples/load-more-infinite-scroll/hooks/useIntersectionObserver.js
  useEffect(() => {
    if (!enabled) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold
      }
    )

    const el = target && target.current

    if (!el) {
      return
    }

    observer.observe(el)

    // eslint-disable-next-line
    return () => {
      observer.unobserve(el)
    }

    // eslint-disable-next-line
  }, [target.current, enabled])
}
