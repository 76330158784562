import { Grid, List } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { MembershipType } from '@/types/job_position'
import GraphCard from '@/components/dashboard/GraphCard'
import { APIJobData } from '@/types/jobs'
import QuickSearchBarInConstruction from './QuickSearchBarInConstruction'
import JobInConstructionItem from './JobInConstructionItem'

type Props = {
  projectId?: string | number | null
}

type DataGet = {
  data: APIJobData[]
}

export type JobInConstructionFilter = {
  membershipEmail: TypeOption | null
  projectId: TypeOption | null
}

export type TypeOption = {
  label: string
  value: string
}

const JobInConstructionList: React.FC<Props> = ({ projectId }) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string | null>('')
  const [filters, setFilters] = useState<JobInConstructionFilter>({
    membershipEmail: null,
    projectId: null
  })
  const { data: jobs, isInitialLoading } = useQuery<DataGet>([
    'client_area',
    'jobs',
    'in_construction_jobs',
    {
      membershipEmail: filters.membershipEmail?.value,
      project_id: projectId
    }
  ])

  const { data: members } = useQuery<MembershipType[]>(['client_area', 'jobs', 'memberships'])

  const membersOptions: TypeOption[] | undefined = useMemo(
    () => members?.map((member) => ({
      value: member.email,
      label: member.name ? member.name : member.email
    })),
    [members]
  )

  return isInitialLoading
    || (jobs && jobs.data && jobs.data.length > 0)
    || filters.membershipEmail ? (
      <Grid item xs={12}>
        <GraphCard
          height={400}
          title={t('homepage.graphs.title.jobs_in_construction')}
          loading={isInitialLoading}
        >
          <QuickSearchBarInConstruction
            filters={filters}
            setFilters={setFilters}
            membersOptions={membersOptions}
            search={search}
            setSearch={setSearch}
          />
          <List
            sx={{
              maxHeight: '95%',
              width: '100%',
              overflowY: 'auto',
              // Align list text with card header
              ml: -1.9
            }}
          >
            {jobs?.data?.map((job) => (
              <JobInConstructionItem job={job} key={job.id} />
            ))}
          </List>
        </GraphCard>
      </Grid>
    ) : null
}

export default JobInConstructionList
