import api from '@/api'
import { APIYuriUserData } from '@/types/users'
import {
  LINKEDIN_REDIRECT_PATH,
  GITHUB_REDIRECT_PATH,
  GOOGLE_REDIRECT_PATH,
  GOOGLE_CLIENT_ID,
  GITHUB_CLIENT_ID,
  MICROSOFT_REDIRECT_PATH,
  MICROSOFT_CLIENT_ID
} from '@/api/constants'

interface SignInResponse {
  authentication_token: string
  email: string
}

export interface CurrentUserResponse {
  data: APIYuriUserData
}

export const signInWithLinkedIn = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/linkedin_oauth', {
    access_token: accessToken,
    redirect_uri: `${window.location.origin}${LINKEDIN_REDIRECT_PATH}`
  }).then((response) => {
    localStorage.setItem('client_api_token', response.data.authentication_token)
    localStorage.setItem('client_api_email', response.data.email)
  })
}

export const signInWithGoogle = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/google_oauth2', {
    access_token: accessToken,
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}${GOOGLE_REDIRECT_PATH}`
  }).then((response) => {
    localStorage.setItem('client_api_token', response.data.authentication_token)
    localStorage.setItem('client_api_email', response.data.email)
  })
}

export const signInWithGitHub = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/github_oauth', {
    access_token: accessToken,
    client_id: GITHUB_CLIENT_ID,
    redirect_uri: `${window.location.origin}${GITHUB_REDIRECT_PATH}`
  }).then((response) => {
    localStorage.setItem('client_api_token', response.data.authentication_token)
    localStorage.setItem('client_api_email', response.data.email)
  })
}

export const signInWithMicrosoft = async (accessToken: string): Promise<void> => {
  await api.post<SignInResponse>('auth/microsoft_oauth', {
    access_token: accessToken,
    client_id: MICROSOFT_CLIENT_ID,
    redirect_uri: `${window.location.origin}${MICROSOFT_REDIRECT_PATH}`,
    scope: import.meta.env.VITE_APP_MICROSOFT_OAUTH_SCOPE || ''
  }).then((response) => {
    localStorage.setItem('client_api_token', response.data.authentication_token)
    localStorage.setItem('client_api_email', response.data.email)
  })
}

export const logout = async (): Promise<void> => {
  await api.delete('auth/logout')

  window.location.href = '/login'
}

export const getCurrentUser = async (): Promise<CurrentUserResponse> => {
  const { data } = await api.get<CurrentUserResponse>('users/current')

  return data
}
