import { Close, Info, Restore } from '@mui/icons-material'
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { useState, useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { useToggle } from 'yu-open-lib'
import AproveInterestDialog from '@/components/brand/interests/AproveInterestDialog'
import RejectInterestDialog from '@/components/brand/interests/RejectInterestDialog'
import EvaluationCriteriaCheckbox from '@/components/reports/EvaluationCriteriaCheckbox'
import { CandidateAttributes } from '@/types/candidates'
import { APIEvaluationCriterionData } from '@/types/evaluation_criterion'
import { APIQuestionData, Question } from '@/types/question'
import { APIChatbotAnswersDatum } from '@/types/reports'
import personPlaceholderImage from '@/assets/images/person_placeholder.jpg'
import SaveButtonInterest from '@/components/brand/interests/SaveButtonInterest'
import { useRequiredParams, useViewport } from '@/hooks'
import CandidateImage from '../CandidateImage'

type Props = {
  candidates: CandidateAttributes[]
  fromInterest?: boolean
  tribeName?: string[]
}

type Params = {
  uuid: string
}

type PropsCell = {
  candidate: CandidateAttributes
  jobQuestion: Question
  fromInterest?: boolean
}

const FixedCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
  minWidth: '170px',
  maxWidth: '170px',
  width: '170px',
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  left: 0
}))

const AnswersCell: React.FC<PropsCell> = ({ candidate, jobQuestion, fromInterest = false }) => {
  const { t } = useTranslation(['text'])
  const { data: report, isInitialLoading } = useQuery<APIChatbotAnswersDatum>(
    ['client_area', 'reports', 'get', candidate?.uuid, 'questionnaire'],
    { enabled: !fromInterest }
  )
  const cacheKey = fromInterest
    ? ['client_area', 'reports', 'person', candidate?.uuid, 'questionnaire']
    : ['client_area', 'reports', 'get', candidate?.uuid]
  const { data: reportInterest } = useQuery<APIChatbotAnswersDatum>(cacheKey, {
    enabled: fromInterest
  })

  const reportData = useMemo(() => {
    if (fromInterest) return reportInterest
    return report
  }, [report, reportInterest, fromInterest])
  const answers = reportData?.data?.attributes?.questionAnswers || []

  const one = answers.find((answer) => answer.meaning === jobQuestion.attributes.meaning)?.answer
  if (isInitialLoading) {
    return <Skeleton variant="rectangular" height="95%" width="100%" />
  }

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 11
    }
  }))

  return (
    <TableCell key={candidate?.id} sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}>
      <Typography variant="inherit">
        {!one?.length && (
          <LightTooltip title={t('text:without_answer_chatbot_question')} placement="bottom-start">
            <Typography variant="body2" color="text.disabled">
              -
            </Typography>
          </LightTooltip>
        )}

        {one && (
          <LightTooltip
            title={`${t('text:answer_chatbot_question')} ${one}`}
            placement="bottom-start"
          >
            <Typography variant="body2">
              {one?.length > 60 ? `${one?.substring(0, 59)}...` : one}
            </Typography>
          </LightTooltip>
        )}
      </Typography>
    </TableCell>
  )
}

const CompareTable: React.FC<Props> = ({ candidates, tribeName, fromInterest = false }) => {
  const [comparable, setComparable] = useState<CandidateAttributes[]>(
    candidates as CandidateAttributes[]
  )
  const [openedChatbot, setOpenedChatbot] = useState(false)
  const [aproveDialog, toggleAproveDialog] = useToggle(false)
  const [rejectDialog, toggleRejectDialog] = useToggle(false)
  const [interestId, setInterestId] = useState<string | number>('')
  const { t } = useTranslation(['candidates'])
  const jobId = useMemo(() => {
    if (!!candidates && candidates.length > 0) {
      return candidates[0].job_id
    }
    return null
  }, [candidates])
  const evaluationCriteriaCacheKey = ['evaluation_criteria', { job_id: jobId }]
  const { data: evaluationCriteria } = useQuery<APIEvaluationCriterionData>(
    evaluationCriteriaCacheKey,
    {
      enabled: !!jobId
    }
  )
  const { uuid } = useRequiredParams<Params>()
  const { data: jobQuestions } = useQuery<APIQuestionData>(
    ['client_area', 'questionnaires', uuid, 'questions'],
    { enabled: !fromInterest }
  )
  const { data: interestsQuestions } = useQuery<APIQuestionData>(
    [
      'client_area',
      'interests',
      'questions',
      { from_interest: fromInterest, tribe_name: tribeName }
    ],
    { enabled: fromInterest }
  )

  const handleRemove = (id: string | number) => {
    setComparable((before) => before.filter((candidate) => candidate.id !== id))
  }
  const { isMobile } = useViewport()

  const questions = useMemo(() => {
    if (fromInterest) {
      return interestsQuestions
    }
    return jobQuestions
  }, [fromInterest, interestsQuestions, jobQuestions])

  useMemo(() => {
    if (fromInterest) {
      setComparable(candidates)
    }
  }, [fromInterest, candidates])

  const linkToCandidate = (personUUID: string, reportId: string | number) => {
    if (fromInterest) {
      return `/person/reports/${personUUID}/${reportId}`
    }
    return `/reports/${personUUID}`
  }

  return (
    <>
      {comparable.length !== candidates?.length && (
        <Button
          variant="outlined"
          endIcon={<Restore />}
          sx={{ margin: '10px' }}
          onClick={() => {
            setComparable(candidates)
          }}
        >
          {t('candidates:refresh_candidates', { quant: candidates.length })}
        </Button>
      )}
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            margin: '10px'
          }}
          color="text.disabled"
        >
          <Info />
          <Typography variant="body2">{t('candidates:mobile_compare_text')}</Typography>
        </Box>
      )}
      <Table sx={{ width: '500px', maxWidth: '500px' }}>
        <TableBody>
          <TableRow sx={{ height: '200px' }}>
            <TableCell
              variant="head"
              sx={{ width: '170px', minWidth: '170px', maxWidth: '170px' }}
            />
            {comparable.map((candidate) => (
              <FixedCell key={candidate.id}>
                <Link target="_blank" to={linkToCandidate(candidate?.uuid, candidate?.id)}>
                  <Box>
                    {fromInterest ? (
                      <CandidateImage src={personPlaceholderImage} />
                    ) : (
                      <CandidateImage src={candidate.avatar} />
                    )}
                  </Box>
                </Link>
                {comparable.length > 2 && (
                  <Tooltip title={t('candidates:remove_comparable')}>
                    <IconButton
                      sx={{
                        backgroundColor: '#ff6e6e',
                        ':hover': {
                          backgroundColor: '#fc8d8d'
                        },
                        border: '1px solid white',
                        position: 'absolute',
                        top: '20px',
                        right: '20px'
                      }}
                      onClick={() => handleRemove(candidate.id)}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                )}
                {fromInterest && (
                  <Box sx={{ position: 'absolute', bottom: '20px', right: '20px' }}>
                    <SaveButtonInterest id={String(candidate.id)} smaller />
                  </Box>
                )}
              </FixedCell>
            ))}
          </TableRow>
          <TableRow>
            <FixedCell variant="head" sx={{ position: isMobile ? 'relative' : 'sticky' }}>
              <strong>{t('candidates:table_labels.name')}</strong>
            </FixedCell>
            {comparable.map((candidate) => (
              <TableCell
                key={candidate.id}
                sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
              >
                {candidate.person.attributes.name}
              </TableCell>
            ))}
          </TableRow>
          {!!evaluationCriteria?.data?.length && (
            <TableRow>
              <FixedCell variant="head" sx={{ position: isMobile ? 'relative' : 'sticky' }}>
                <strong>{t('candidates:table_labels.critical_points')}</strong>
              </FixedCell>
              {comparable.map((candidate) => (
                <TableCell
                  key={candidate.id}
                  sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                >
                  {evaluationCriteria.data.map((criterion) => (
                    <EvaluationCriteriaCheckbox
                      key={criterion.id}
                      criterion={criterion.attributes}
                      checked={candidate.evaluation_criteria
                        .map((crit) => crit.id)
                        .includes(Number(criterion.id))}
                    />
                  ))}
                </TableCell>
              ))}
            </TableRow>
          )}
          <TableRow>
            <FixedCell variant="head" sx={{ position: isMobile ? 'relative' : 'sticky' }}>
              <strong>{t('candidates:table_labels.recomendation')}</strong>
            </FixedCell>
            {comparable.map((candidate) => (
              <TableCell
                key={candidate.id}
                sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
              >
                {candidate.fit_to_profile ? (
                  <Typography variant="inherit">{candidate.fit_to_profile}</Typography>
                ) : (
                  <Typography variant="inherit" sx={{ color: 'text.disabled' }}>
                    {t('candidates:without_recomendation')}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <FixedCell variant="head" sx={{ position: isMobile ? 'relative' : 'sticky' }}>
              <strong>{t('candidates:table_labels.salary')}</strong>
            </FixedCell>
            {comparable.map((candidate) => (
              <TableCell
                key={candidate.id}
                sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
              >
                {candidate.salary ? (
                  <Typography variant="inherit">{`R$ ${candidate.salary}`}</Typography>
                ) : (
                  <Typography variant="inherit" sx={{ color: 'text.disabled' }}>
                    {t('candidates:without_salary')}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
          {fromInterest && (
            <>
              <TableRow>
                <FixedCell
                  variant="head"
                  sx={{ zIndex: 999, position: isMobile ? 'relative' : 'sticky' }}
                >
                  <strong>{t('candidates:table_labels.status')}</strong>
                </FixedCell>
                {comparable.map((candidate) => (
                  <TableCell
                    key={candidate.id}
                    sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography variant="inherit">
                        {t(`candidates:${candidate.status}`)}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <FixedCell
                  variant="head"
                  sx={{ zIndex: 999, position: isMobile ? 'relative' : 'sticky' }}
                >
                  <strong>{t('candidates:table_labels.tribe')}</strong>
                </FixedCell>
                {comparable.map((candidate) => (
                  <TableCell
                    key={candidate.id}
                    sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography variant="inherit">{candidate.tribe_name}</Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <FixedCell
                  variant="head"
                  sx={{ zIndex: 999, position: isMobile ? 'relative' : 'sticky' }}
                >
                  <strong>{t('candidates:table_labels.actions')}</strong>
                </FixedCell>
                {comparable.map((candidate) => (
                  <TableCell
                    key={candidate.id}
                    sx={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
                  >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title={t('candidates:disaprove_interest_tooltip')}>
                        <Button
                          color="error"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setInterestId(candidate?.id)
                            toggleRejectDialog()
                          }}
                        >
                          {t('candidates:disaprove_interest_button')}
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('candidates:aprove_interest_tooltip')}>
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setInterestId(candidate?.id)
                            toggleAproveDialog()
                          }}
                        >
                          {t('candidates:aprove_interest_button')}
                        </Button>
                      </Tooltip>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </>
          )}
          <TableRow>
            <FixedCell variant="head" sx={{ position: isMobile ? 'relative' : 'sticky' }}>
              <Tooltip title="clique para ver respostas" placement="bottom-start">
                <Accordion
                  onClick={() => {
                    setOpenedChatbot(!openedChatbot)
                  }}
                  sx={{ backgroundColor: 'tra', boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                  >
                    {t('candidates:table_labels.chatbot')}
                  </AccordionSummary>
                </Accordion>
              </Tooltip>
            </FixedCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table sx={{ width: '500px', maxWidth: '500px' }}>
        {openedChatbot && (
          <TableBody>
            {questions?.data.map((jobQuestion) => (
              <TableRow>
                <FixedCell sx={{ position: isMobile ? 'relative' : 'sticky' }}>
                  <strong>{jobQuestion.attributes.meaning}</strong>
                </FixedCell>
                {candidates.map((candidate) => (
                  <AnswersCell
                    candidate={candidate}
                    jobQuestion={jobQuestion}
                    fromInterest={fromInterest}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      <AproveInterestDialog
        open={aproveDialog}
        onClose={toggleAproveDialog}
        interestId={interestId}
      />
      <RejectInterestDialog
        open={rejectDialog}
        onClose={toggleRejectDialog}
        interestId={interestId}
      />
    </>
  )
}

export default CompareTable
