import i18n from 'i18next'
import {
  initReactI18next, useTranslation, Trans, I18nContext, I18nextProvider
} from 'react-i18next'

import candidates from './pt/candidates.json'
import grammar from './pt/grammar.json'
import jobValidation from './pt/job_validation.json'
import validation from './pt/validation.json'
import text from './pt/text.json'
import jobFields from './pt/job_fields.json'

import enCandidates from './en/candidates.json'
import enGrammar from './en/grammar.json'
import enJobValidation from './en/job_validation.json'
import enValidation from './en/validation.json'
import enText from './en/text.json'
import enJobFields from './en/job_fields.json'

import esJobValidation from './es/job_validation.json'
import esValidation from './es/validation.json'
import esText from './es/text.json'
import esJobFields from './es/job_fields.json'
import esCandidates from './es/candidates.json'
import esGrammar from './es/grammar.json'

export const resources = {
  pt: {
    text,
    jobValidation,
    jobFields,
    candidates,
    grammar,
    validation
  },
  en: {
    text: enText,
    jobValidation: enJobValidation,
    jobFields: enJobFields,
    candidates: enCandidates,
    grammar: enGrammar,
    validation: enValidation
  },
  es: {
    text: esText,
    jobValidation: esJobValidation,
    candidates: esCandidates,
    grammar: esGrammar,
    jobFields: esJobFields,
    validation: esValidation
  }
} as const

const initI18n = (language?: string) => {
  let lng

  if (typeof (localStorage) !== 'undefined') {
    lng = localStorage.getItem('yuri_language')

    if (!lng && typeof (navigator) !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      lng = navigator.language.split('-')[0]
    }
  }

  if (!lng) {
    lng = process.env.locale
  }

  const interpolation = {
    defaultVariables: {
      yupro_name: 'YuPro'
    }
  }

  i18n
    .use(initReactI18next)
    .init({
      lng: language || lng || 'pt',
      ns: ['jobValidation', 'jobFields', 'text', 'candidates', 'grammar', 'validation'],
      defaultNS: 'text',
      resources,
      interpolation,
      returnNull: false
      // debug: true
    })
    .then()
}

const { t } = i18n

export {
  t, useTranslation, Trans, I18nContext, I18nextProvider, initI18n
}
