import { ChipProps, Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import { Trans, useTranslation } from '@yu/i18n'
import { StatusChip } from 'yu-open-lib'
import { JobPosition } from '@/types/job_position'

type Props = {
  position: JobPosition
} & ChipProps

const JobPositionStatusChip: React.FC<Props> = ({ position, ...chipProps }) => {
  const { t } = useTranslation()
  const finishedAt = position.attributes.canceled_at
    || position.attributes.placed_at
    || position.attributes.onhold_at

  const tooltip = finishedAt
    ? t(`position.finished.${position.attributes.status}` as const, {
      date: dayjs(finishedAt).format('LL'),
      days_difference: dayjs(finishedAt).diff(dayjs(position.attributes.open_at), 'day')
    })
    : t('position.open_for', {
      days_difference: dayjs().diff(dayjs(position.attributes.open_at), 'day')
    })

  return (
    <StatusChip
      value={position.attributes.status}
      label={(
        <Trans
          i18nKey={`position.status.${position.attributes.status}`}
          defaults="<skeleton />"
          components={{ skeleton: <Skeleton width={40} /> }}
        />
      )}
      tooltip={tooltip}
      successValues={['open', 'open_wait_for_customer']}
      primaryValues={['placed', 'closed_internal_indication']}
      errorValues={['canceled']}
      warningValues={['on_hold']}
      {...chipProps}
    />
  )
}

export default JobPositionStatusChip
