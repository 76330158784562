import { Fab, Menu, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useState } from 'react'
import { t } from 'i18next'
import { GroupingType } from '@/types/candidates'

type Props = {
  groupings: GroupingType[]
  selectedGrouping: GroupingType
  setSelectedGrouping: React.Dispatch<React.SetStateAction<GroupingType>>
}

const useStyles = makeStyles((theme) => ({
  groupByButton: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: 10
  }
}))

const FilterList: React.FC<Props> = ({ groupings, selectedGrouping, setSelectedGrouping }) => {
  const classes = useStyles()
  const [groupByAnchor, setGroupByAnchor] = useState<null | HTMLElement>(null)
  const handleCloseGroupBy = () => setGroupByAnchor(null)
  // eslint-disable-next-line max-len
  const handleGroupByClick = (event: React.MouseEvent<HTMLButtonElement>) => setGroupByAnchor(event.currentTarget)
  const handleSetGroupBy = (grouping: GroupingType) => {
    handleCloseGroupBy()
    setSelectedGrouping(grouping)
  }

  return (
    <>
      <Menu
        disableScrollLock
        anchorEl={groupByAnchor}
        keepMounted
        open={Boolean(groupByAnchor)}
        onClose={handleCloseGroupBy}
      >
        {groupings.map((grouping) => (
          <MenuItem
            selected={selectedGrouping === grouping}
            onClick={() => handleSetGroupBy(grouping)}
            key={grouping}
          >
            {t('candidates:by')}
            {' '}
            {t(`candidates:filters.${grouping}` as const)}
          </MenuItem>
        ))}
      </Menu>

      <Fab
        color="primary"
        size="medium"
        className={classes.groupByButton}
        onClick={handleGroupByClick}
      >
        <FilterListIcon />
      </Fab>
    </>
  )
}

export default FilterList
