import { Link as RouteLink } from 'react-router-dom'
import { Link } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from '@yu/i18n'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { NotificationItem, NotificationMenu } from 'yu-open-lib'
import {
  APINotificationData,
  NotificationAttributes,
  NotificationType
} from '@/types/notifications'
import { useCurrentUser } from '@/hooks/auth'
import api from '@/api'

const Notification: React.FC = () => {
  const queryClient = useQueryClient()
  const { loggedIn } = useCurrentUser()
  const { t } = useTranslation()
  const notificationCacheKey = ['client_area', 'notifications']
  const { data: notifications } = useQuery<APINotificationData>(notificationCacheKey, {
    enabled: loggedIn
  })
  const [NotifyAnchorEl, setNotifyAnchorEl] = useState<
    null | Element |(EventTarget & SVGSVGElement)
      >(null)

  const notificationMutation = useMutation(
    async (id: (string | number)[]) => {
      await api.post('client_area/notifications/mark_as_viewed', { ids: id })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(notificationCacheKey)
      }
    }
  )
  const handleNotification = (ids: (string | number)[]) => {
    const notifys = ids.map((i) => notifications?.data.find((n) => n.id === i))
    if (!notifys) return
    notifys.forEach((n) => {
      if (n?.attributes?.viewed) {
        ids.splice(ids.indexOf(n.id), 1)
      }
    })
    if (ids.length === 0) return
    notificationMutation.mutate(ids)
  }

  const notificationsData: NotificationAttributes[] = useMemo(() => {
    if (!notifications) return []
    return notifications?.data.map((n) => ({
      id: n.id,
      title: n.attributes.title,
      description: n.attributes.description,
      viewed: n.attributes.viewed,
      created_at: n.attributes.created_at,
      updated_at: n.attributes.updated_at,
      viewed_at: n.attributes.viewed_at,
      notifiable_id: n.attributes.notifiable_id,
      notifiable_type: n.attributes.notifiable_type,
      notifiable_uuid: n.attributes.notifiable_uuid,
      notification_type: n.attributes.notification_type
    }))
  }, [notifications])

  return (
    <NotificationMenu
      title={t('header.buttons.notifications')}
      emptyNotificationText={t('notifications.empty')}
      markAllAsReadText={t('notifications.mark_all_as_readed')}
      showOnlyUnreadText={t('notifications.show_only_unread')}
      notifications={notificationsData || []}
      handleNotifications={handleNotification}
      open={Boolean(NotifyAnchorEl)}
      onClose={() => setNotifyAnchorEl(null)}
      setAnchorEl={setNotifyAnchorEl}
      anchorEl={NotifyAnchorEl}
      NotificationItem={(props) => (
        <NotificationItem
          {...props}
          actionButton={
            (props.notification.notification_type as NotificationType) === 'chatbot_validation'
            || (props.notification.notification_type as NotificationType) === 'job_post_validation' ? (
              <Link
                component={RouteLink}
                to={`/vaga/${props.notification.notifiable_uuid}/alignment`}
                target="_blank"
              >
                {t('notifications.action_button')}
              </Link>
              ) : undefined
          }
        />
      )}
    />
  )
}

export default Notification
