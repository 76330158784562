import { useMemo } from 'react'
import { ResponsiveBar, BarDatumWithColor } from '@nivo/bar'
import { truncatedText } from 'yu-open-lib'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { OrdinalColorScaleConfig } from '@nivo/colors'
import { useNivoTheme } from '@/hooks/nivo'
import BasicTextTooltip from '@/components/dashboard/BasicTextTooltip'

import { asPercentage } from '@/helpers'

import { DiversityData } from '@/types/dashboards'
import DefaultSkeleton from '../dashboard_cards/DefaultSkeleton'

type Props = {
  isMobile: boolean
  data: DiversityData | undefined
}

const useStyles = makeStyles(() => ({
  mobileDiversityContainer: {
    height: '15%'
  }
}))

interface typeSharedPropsDesktopAndMobile {
  groupMode: 'stacked'
  colors: OrdinalColorScaleConfig<any> | undefined
  theme: any
  keys: string[]
  animate: boolean
  label: any
}

const DiversityBarGraph: React.FC<Props> = ({ isMobile, data }) => {
  const nivoTheme = useNivoTheme()
  const classes = useStyles()

  const maxSizeOfData: number = useMemo(() => {
    if (!data) return 0

    const maxArray = data.map((datum) => Object.values(datum).reduce((a, b) => (typeof b === 'number' ? a + b : a), 0))
    return Math.max(...maxArray)
  }, [data])

  const formattedData = useMemo(() => {
    if (!data) return []

    return data.filter((datum) => {
      const total = Object.values(datum).reduce((a, b) => (typeof b === 'number' ? a + b : a), 0)
      return total > maxSizeOfData * 0.8 // only show data that is greater than 80% of the max
    })
  }, [data, maxSizeOfData])

  const keys = useMemo(() => {
    // nivo's API requires each key to be individually listed, so the code below
    // does just that (except for question (which should be the indexer rather
    // than a key) and total (which is only used to calculate the percentage)).
    const mappedKeys: Set<string> = new Set()
    if (!formattedData) return mappedKeys

    formattedData.forEach((datum) => {
      Object.keys(datum).forEach((key) => mappedKeys.add(key))
    })

    mappedKeys.delete('question')
    mappedKeys.delete('total')

    return mappedKeys
  }, [formattedData])

  function barLabel(datum: BarDatumWithColor) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const labelPercentage = datum.value / datum.data.total
    const label = `${truncatedText(datum.id.toString(), 20)}: ${asPercentage(labelPercentage)}`

    return label
  }

  function diversityTooltip(input: any) {
    return <BasicTextTooltip text={input.id as string} value={input.value} color={input.color} />
  }

  const sharedPropsDesktopAndMobile: typeSharedPropsDesktopAndMobile = {
    groupMode: 'stacked',
    colors: { scheme: 'set3' },
    theme: nivoTheme,
    keys: Array.from(keys),
    animate: true,
    label: (datum: BarDatumWithColor) => barLabel(datum)
  }

  if (!formattedData || !keys) {
    return <DefaultSkeleton />
  }

  if (isMobile) {
    return (
      <>
        {formattedData.map((datum) => (
          <>
            <Typography variant="subtitle2">{datum.question.toString()}</Typography>
            <div className={classes.mobileDiversityContainer}>
              <ResponsiveBar
                {...sharedPropsDesktopAndMobile}
                data={[(datum as unknown) as Record<string, unknown>]}
                tooltip={diversityTooltip}
                margin={{
                  top: 40,
                  right: 0,
                  bottom: 40,
                  left: 0
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  format: () => ''
                }}
                layout="horizontal"
                labelSkipWidth={64}
              />
            </div>
          </>
        ))}
      </>
    )
  }

  return (
    <span style={{ cursor: 'pointer' }} role="button">
      <ResponsiveBar
        {...sharedPropsDesktopAndMobile}
        data={(formattedData as unknown) as Record<string, unknown>[]}
        tooltip={diversityTooltip}
        indexBy="question"
        margin={{
          top: 30,
          right: 40,
          bottom: 15,
          left: 40
        }}
        axisTop={{}}
        axisBottom={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          format: () => ''
        }}
        layout="vertical"
        padding={0.3}
        labelSkipHeight={15}
      />
    </span>
  )
}

export default DiversityBarGraph
