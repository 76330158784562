import { useQuery } from '@tanstack/react-query'

import { Skeleton, Typography } from '@mui/material'

import { ApiTribeData } from '@/types/tribe'

type Props = {
  tribeId: string | number
  brandId: string | number
  isDiversity: boolean
}
const YuProTribesTitleCell: React.FC<Props> = ({ tribeId, brandId, isDiversity }) => {
  const { data: tribe, isInitialLoading } = useQuery<ApiTribeData>(
    ['client_area', 'tribes', tribeId],
    {
      enabled: !!tribeId
    }
  )

  if (isInitialLoading) {
    return <Skeleton width={100} height={50} />
  }

  return (
    <>
      <Typography style={{ fontWeight: 'bold' }} sx={{ color: 'success.main' }}>
        {tribe?.data?.attributes?.name}
      </Typography>
    </>
  )
}

export default YuProTribesTitleCell
