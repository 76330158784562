import { useEffect } from 'react'
import { useTranslation } from '@yu/i18n'
import './index.scss'

type Props = {
  boundaryError?: Error
}

const Error500: React.FC<Props> = ({ boundaryError }) => {
  const { t } = useTranslation(['text'])
  const ErrorOverlay = customElements.get('vite-error-overlay')

  useEffect(() => {
    localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE')

    // Render error stack trace if present and in development environment
    // https://github.com/vitejs/vite/issues/2076
    if (import.meta.env.DEV && boundaryError && ErrorOverlay) {
      // Render error stack trace if present and in development environment
      // https://github.com/vitejs/vite/issues/2076

      // eslint-disable-next-line no-console
      console.error(boundaryError)
      const overlay = new ErrorOverlay(boundaryError)

      document.body.appendChild(overlay)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Production error message
  return (
    <>
      <div className="errorMessage">
        <p>
          {t('text:generic_error.label')}
          <span className="gradient-underline">
            <a href="http://yuri.jornadayu.com">{t('text:generic_error.call_to_safety')}</a>
          </span>
        </p>
      </div>

      <div className="errorContainer">
        <div className="error404page">
          <div className="newcharacter404">
            <div className="chair404" />
            <div className="leftshoe404" />
            <div className="rightshoe404" />
            <div className="legs404" />
            <div className="torso404">
              <div className="body404" />
              <div className="leftarm404" />
              <div className="rightarm404" />
              <div className="head404">
                <div className="eyes404" />
              </div>
            </div>
            <div className="laptop404" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Error500
