import { useEffect } from 'react'

import {
  DeepPartial, FieldValues, UseFormReturn, useForm, useWatch
} from 'react-hook-form'

interface Props<T> {
  storageKey: string
  defaultValues: DeepPartial<T> | undefined
  trigger: any
}

function useFormPersist<T extends FieldValues>({
  storageKey,
  defaultValues,
  trigger
}: Props<T>): UseFormReturn<any> {
  const form = useForm<T>({ defaultValues })
  const watchedFields = useWatch({ control: form.control })

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    const valueInLocalStorage = localStorage.getItem(storageKey)
    if (valueInLocalStorage) {
      try {
        const parsedValue = JSON.parse(valueInLocalStorage)
        form.reset(parsedValue)
      } catch (error) {
        form.reset(defaultValues)
        // eslint-disable-next-line no-restricted-syntax
        localStorage.setItem(storageKey, JSON.stringify(defaultValues))
      }
    } else {
      form.reset(defaultValues)
      // eslint-disable-next-line no-restricted-syntax
      localStorage.setItem(storageKey, JSON.stringify(defaultValues))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey, trigger])

  useEffect(() => {
    if (JSON.stringify(watchedFields) !== '{}' && !!window.localStorage.getItem(storageKey)) {
      // eslint-disable-next-line no-restricted-syntax
      localStorage.setItem(storageKey, JSON.stringify(watchedFields))
    }
  }, [watchedFields, storageKey])

  return form
}

export default useFormPersist
