import { Theme } from '@mui/material'
import { createContext } from 'react'
import { AppTheme } from 'yu-open-lib'

export type ThemeState = {
  /** @default false */
  darkMode: boolean
}

export type SetThemeAction = {
  type: 'setDarkMode'
  value: boolean
}

export type ToggleThemeAction = {
  type: 'toggleTheme'
}

export type ThemeAction = SetThemeAction | ToggleThemeAction

export interface ThemeContextType extends ThemeState {
  dispatch: (action: ThemeAction) => ThemeState
}

export const theme: (theme: boolean) => Theme = (darkMode: boolean) => AppTheme({
  darkMode,
  options: {
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            color: 'unset'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none'
          }
        }
      }
    }
  }
})

export const ThemeContext = createContext({})

export function themeReducer(state: ThemeState, action: ThemeAction): ThemeState {
  switch (action.type) {
    case 'setDarkMode':
      localStorage.setItem('darkTheme', action.value.toString())
      return { ...state, darkMode: action.value as boolean }
    case 'toggleTheme': {
      localStorage.setItem('darkTheme', (!state.darkMode).toString())
      return { ...state, darkMode: !state.darkMode }
    }
    default:
      throw new Error()
  }
}
