import { useEffect, Suspense, lazy } from 'react'
import {
  Route, RouteProps, useLocation, Navigate, Routes as ReactRoutes
} from 'react-router-dom'

import { Container } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { NotFound, BackdropLoading } from 'yu-open-lib'

import { useCurrentUser } from '@/hooks/auth'
import { CandidatesStore } from '@/hooks/candidates'
import { ProvideFilterContext } from '@/hooks/filters'
import YuProTable from '@/components/homepage/yupro/YuProTable'
import { ProvideHistoryContext } from './hooks/jobs/history'
import Homepage from './pages/Homepage'
import WrongLoginPage from './pages/WrongLoginPage'
import MicrosoftPopup from './components/microsoft/MicrosoftPopup'
import ImpersonationButton from './components/shared/ImpersonationButton'
import { useLocationContext } from './hooks/location'

const MagicLinkLogin = lazy(() => import('@/pages/MagicLinkLogin'))
const ImpersonateLogin = lazy(() => import('@/pages/ImpersonateLogin'))
const AtsJobCreation = lazy(() => import('@/pages/AtsJobCreation'))
const JobTimelinePage = lazy(() => import('@/pages/JobTimelinePage'))
const JobAlignmentsPage = lazy(() => import('@/pages/JobAlignmentsPage'))
const SignIn = lazy(() => import('@/pages/SignIn'))
const Report = lazy(() => import('@/pages/Report'))
const Carometro = lazy(() => import('@/pages/Carometro'))
const JobDashboard = lazy(() => import('@/pages/JobDashboard'))
const JobHistory = lazy(() => import('@/pages/JobHistory'))
const Availabilities = lazy(() => import('@/pages/Availabilities'))
const Header = lazy(() => import('@/components/base/Header'))
const JobBottomNavigation = lazy(() => import('@/components/base/JobBottomNavigation'))
const LinkedInPopUp = lazy(() => import('@/components/linkedin/LinkedInPopUp'))
const GitHubPopUp = lazy(() => import('@/components/github/GitHubPopUp'))
const GooglePopUp = lazy(() => import('@/components/google/GooglePopUp'))

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    maxWidth: '100%'
  }
}))

type RouteContainerProps = {
  style?: React.CSSProperties
  children?: React.ReactNode
}

const RouteContainer: React.FC<RouteContainerProps> = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Container className={classes.container} {...props}>
      <>{children}</>
    </Container>
  )
}

export const ScrollToTopControlller: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      // https://github.com/MiniProfiler/rack-mini-profiler#using-in-spa-applications
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.MiniProfiler !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.MiniProfiler.pageTransition()
      }
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  return null
}

/**
 * Redirect user to login if he's not already authenticated, then redirect
 * them back after they have finished logging in
 */

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const { loggedIn, userRole } = useCurrentUser()

  if (userRole === 'candidate') {
    return (
      <Navigate
        to={{
          pathname: '/loginerror'
        }}
        replace
      />
    )
  }

  if (!loggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login'
        }}
        replace
      />
    )
  }

  return children as React.ReactElement
}

const AtsRoute: React.FC<RouteProps> = ({ children }) => {
  const { isAtsClient, isInitialLoading } = useCurrentUser()

  if (!isInitialLoading && !isAtsClient) {
    return (
      <Navigate
        to={{
          pathname: '/'
        }}
        replace
      />
    )
  }

  return children as React.ReactElement
}

const JobRoute: React.FC<RouteProps> = ({ children }) => (
  <PrivateRoute>
    <CandidatesStore>
      <ProvideHistoryContext>
        <ProvideFilterContext>
          <JobBottomNavigation />

          <RouteContainer style={{ marginBottom: 75 }}>{children}</RouteContainer>
        </ProvideFilterContext>
      </ProvideHistoryContext>
    </CandidatesStore>
  </PrivateRoute>
)

const Routes: React.FC = () => {
  const { displayLocation } = useLocationContext()

  return (
    <Suspense fallback={<BackdropLoading open />}>
      <ImpersonationButton />

      <ReactRoutes location={displayLocation}>
        <Route
          path="/"
          element={(
            <PrivateRoute>
              <RouteContainer>
                <Header />
                <Homepage />
              </RouteContainer>
            </PrivateRoute>
          )}
        />

        <Route
          path="/loginerror"
          element={(
            <RouteContainer>
              <Header />
              <WrongLoginPage />
            </RouteContainer>
          )}
        />

        <Route
          path="/login"
          element={(
            <RouteContainer>
              <Header />
              <SignIn />
            </RouteContainer>
          )}
        />

        <Route
          path="/users/auth/google_oauth2/callback"
          element={(
            <RouteContainer>
              <GooglePopUp />
            </RouteContainer>
          )}
        />

        <Route
          path="/users/auth/linkedin/callback"
          element={(
            <RouteContainer>
              <LinkedInPopUp />
            </RouteContainer>
          )}
        />

        <Route
          path="/users/auth/microsoft/callback/"
          element={(
            <RouteContainer>
              <MicrosoftPopup />
            </RouteContainer>
          )}
        />

        <Route
          path="/users/auth/github/callback"
          element={(
            <RouteContainer>
              <GitHubPopUp />
            </RouteContainer>
          )}
        />

        <Route
          path="/magic_link_login"
          element={(
            <RouteContainer>
              <MagicLinkLogin />
            </RouteContainer>
          )}
        />

        <Route
          path="/vaga/:uuid/candidatos"
          element={(
            <JobRoute>
              <Carometro />
            </JobRoute>
          )}
        />

        <Route
          path="/vaga/:uuid/dashboard"
          element={(
            <JobRoute>
              <JobDashboard />
            </JobRoute>
          )}
        />

        <Route
          path="/vaga/:uuid/history"
          element={(
            <JobRoute>
              <JobHistory />
            </JobRoute>
          )}
        />

        <Route
          path="/vaga/:uuid/timeline"
          element={(
            <JobRoute>
              <JobTimelinePage />
            </JobRoute>
          )}
        />

        <Route
          path="/vaga/:uuid/alignment"
          element={(
            <JobRoute>
              <JobAlignmentsPage />
            </JobRoute>
          )}
        />

        <Route
          path="/yupro"
          element={(
            <PrivateRoute>
              <YuProTable />
            </PrivateRoute>
          )}
        />

        <Route
          path="/reports/:uuid"
          element={(
            <PrivateRoute>
              <Report />
            </PrivateRoute>
          )}
        />

        <Route
          path="/person/reports/:uuid/:interest_id"
          element={(
            <PrivateRoute>
              <Report fromInterest />
            </PrivateRoute>
          )}
        />

        <Route
          path="/availabilities"
          element={(
            <PrivateRoute>
              <RouteContainer>
                <Header />
                <Availabilities />
              </RouteContainer>
            </PrivateRoute>
          )}
        />

        <Route
          path="/impersonate_login"
          element={(
            <RouteContainer>
              <ImpersonateLogin />
            </RouteContainer>
          )}
        />

        <Route
          path="/ats_job"
          element={(
            <RouteContainer>
              <PrivateRoute>
                <AtsRoute>
                  <Header />
                  <AtsJobCreation />
                </AtsRoute>
              </PrivateRoute>
            </RouteContainer>
          )}
        />

        <Route
          path="/ats_job/:slug"
          element={(
            <RouteContainer>
              <PrivateRoute>
                <AtsRoute>
                  <Header />
                  <AtsJobCreation />
                </AtsRoute>
              </PrivateRoute>
            </RouteContainer>
          )}
        />

        <Route
          element={(
            <RouteContainer>
              <Header />
              <NotFound url="/" />
            </RouteContainer>
          )}
        />
      </ReactRoutes>
    </Suspense>
  )
}

export default Routes
