import { useTranslation } from '@yu/i18n'
import {
  Button, Card, Grid, Tooltip, Typography
} from '@mui/material'
import { useToggle, useViewport } from 'yu-open-lib'
import { useMemo, useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import GraphCard, { useStyles } from '@/components/dashboard/GraphCard'
import { useCurrentUser } from '@/hooks/auth'
import Header from '@/components/base/Header'
import { useGetInterests } from '@/api/interests'
import CarometroGrouping from '@/components/candidates/CarometroGrouping'
import { Interest, InterestStatus } from '@/types/interest'
import InterestCard from '@/components/homepage/yupro/InterestCard'
import FilterList from '@/components/shared/FilterList'
import { usePermission } from '@/hooks/permission'
import YuProTribesTable from '@/components/tribes_table/YuProTribesTable'
import { GroupingType } from '@/types/candidates'
import YuProTribeInterests from './YuProTribeInterests'
import YuProExportDialog from './YuProExportDialog'

type InterestsGroupedType = {
  [key: string]: Interest[]
}

const YuProTable: React.FC = () => {
  const { t } = useTranslation()
  const { isMobile } = useViewport()
  const height = 220
  const overflowAuto = true
  const mobileHeight = height

  const classes = useStyles({
    isMobile,
    mobileHeight,
    height,
    overflowAuto
  })

  const interests = useGetInterests()
  const { user } = useCurrentUser()
  const can_export_candidates = usePermission('candidate_csv', 'exportYuproCSV')
  const groupings: GroupingType[] = ['status', 'tribe_name']
  const [selectedGrouping, setSelectedGrouping] = useState<GroupingType>('tribe_name')
  const [timedOut, setTimedOut] = useState(false) // useToggle doesn't work inside set timeout
  const [exportDialog, toggleExportDialog] = useToggle(false)

  const interestsGrouped = useMemo(() => {
    const reduced = interests?.data?.reduce((r, interest) => {
      const obj: InterestsGroupedType = r

      if (selectedGrouping === 'tribe_name' && interest.attributes.tribe_name) {
        obj[interest.attributes.tribe_name] = obj[interest.attributes.tribe_name] || []
        obj[interest.attributes.tribe_name].push(interest)
      } else if (selectedGrouping === 'status' && interest.attributes.status) {
        obj[interest.attributes.status] = obj[interest.attributes.status] || []
        obj[interest.attributes.status].push(interest)
      }
      return obj
    }, Object.create(null))

    return reduced
  }, [interests, selectedGrouping])

  const sortedInterestsKeys = useMemo(() => {
    if (!interestsGrouped) {
      return []
    }
    const sorted = Object.keys(interestsGrouped).sort((a, b) => {
      if (interestsGrouped?.[a]?.length > interestsGrouped?.[b]?.length) {
        return -1
      }
      if (interestsGrouped?.[a]?.length < interestsGrouped?.[b]?.length) {
        return 1
      }
      return 0
    })
    return sorted
  }, [interestsGrouped])

  if (!interests?.data?.length) {
    return null
  }

  return (
    <div>
      <Header />

      <YuProExportDialog
        open={exportDialog}
        onClose={toggleExportDialog}
        categories={sortedInterestsKeys}
        selectedGrouping={selectedGrouping}
        interests={interests}
        timedOut={timedOut}
        setTimedOut={setTimedOut}
      />

      <FilterList
        groupings={groupings}
        selectedGrouping={selectedGrouping}
        setSelectedGrouping={setSelectedGrouping}
      />

      <Grid container sx={{ width: '96%', margin: 'auto' }} spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                ml: 2,
                mb: 1,
                mt: 1
              }}
            >
              {t('homepage.yupro.title')}
            </Typography>
          </Card>
        </Grid>
        <Grid item>
          <Card className={classes.root}>
            <Typography
              sx={{
                ml: 2,
                mb: 2,
                mt: 2,
                mr: 2
              }}
              variant="body1"
              color="text.secondary"
            >
              {t('homepage.yupro.description')}
            </Typography>
          </Card>
        </Grid>
        <Grid item lg={4} sm={12}>
          <GraphCard
            height="auto"
            overflowAuto
            headerStyles={{
              marginBottom: '10px'
            }}
          >
            <YuProTribesTable />
          </GraphCard>
        </Grid>
        <Grid item lg={8} sm={12}>
          <GraphCard
            height={220}
            overflowAuto
            headerStyles={{
              marginBottom: '10px'
            }}
            SecondaryAction={() => {
              if (can_export_candidates) {
                return (
                  <Tooltip title={timedOut ? t('text:homepage.yupro.export.timeout') : ''}>
                    {/* span used so that tooltip shows when button is disabled */}
                    <span>
                      <Button
                        variant="outlined"
                        onClick={toggleExportDialog}
                        size="large"
                        disabled={timedOut}
                      >
                        <Typography style={{ marginRight: '5px' }}>
                          {t('homepage.yupro.export.button')}
                        </Typography>
                        <FileDownloadIcon />
                      </Button>
                    </span>
                  </Tooltip>
                )
              }
              return <></>
            }}
            title={t('homepage.tables.yupro.info.call_to_action', {
              interests_count: interests?.data?.length,
              company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
            })}
            cardProps={{
              sx: {
                width: '100%'
              }
            }}
            cardContentProps={{
              sx: {
                height: '100% !important',
                width: '100%'
              }
            }}
          >
            <Grid container gap={2} justifyContent="center">
              {!!interestsGrouped
                && sortedInterestsKeys.map((category) => (
                  <YuProTribeInterests
                    key={`interests-${category}`}
                    title={category}
                    status={selectedGrouping === 'status'}
                    count={interestsGrouped[category].length}
                    interests={interestsGrouped[category]}
                  />
                ))}
            </Grid>
          </GraphCard>
        </Grid>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Card className={classes.root}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                ml: 2,
                mb: 1,
                mt: 1
              }}
            >
              {t('homepage.tables.yupro.info.in_process')}
            </Typography>
            {sortedInterestsKeys.map((group, index) => (
              <CarometroGrouping
                id={`${group}-${index}`}
                candidateStatus={
                  selectedGrouping === 'status' ? (group as InterestStatus) : undefined
                }
                title={group}
                size={interestsGrouped[group].length}
              >
                <Grid container spacing={2}>
                  {interestsGrouped[group].map((interest: Interest) => (
                    <Grid item xs={12} sm={6} md={2} key={interest.id}>
                      <InterestCard interest={interest} />
                    </Grid>
                  ))}
                </Grid>
              </CarometroGrouping>
            ))}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default YuProTable
