import { useToasts } from 'react-toast-notifications'
import { useTranslation } from '@yu/i18n'

import {
  Fab, Tooltip, Stack, keyframes, Button, Box
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Check as CheckIcon, ContentCopy as CopyIcon } from '@mui/icons-material'

import { useToggle } from 'yu-open-lib'

import styled from '@emotion/styled'
import { useMemo } from 'react'
import { Interest } from '@/types/interest'
import RejectInterestDialog from './RejectInterestDialog'
import AproveInterestDialog from './AproveInterestDialog'
import SaveButtonInterest from './SaveButtonInterest'

const growAnim = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`

const ApproveButton = styled(Button)`
  border-radius: 25px;
  animation: ${growAnim} 500ms linear;
`

type Props = {
  interest: Interest
  small?: boolean
}

const ActionInterestButtons: React.FC<Props> = ({ interest, small = false }) => {
  const { t } = useTranslation()

  const { addToast } = useToasts()

  const [approveDialog, toggleApproveDialog] = useToggle(false)
  const [rejectDialog, toggleRejectDialog] = useToggle(false)

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)
      addToast(t('report.toasts.success.copy_link'), { appearance: 'success' })
    } catch (error) {
      addToast(t('report.toasts.errors.copy_link'), { appearance: 'error' })
    }
  }

  const texts = useMemo(() => {
    const defaultTexts = {
      approve: t('report.tooltip.interest.approve'),
      reject: t('report.tooltip.interest.reject'),
      save: t('report.tooltip.save_interest'),
      remove: t('report.tooltip.remove_interest')
    }
    if (!interest) return defaultTexts
    if (interest.attributes.status !== 'available_to_client') {
      return {
        ...defaultTexts,
        approve: t('report.tooltip.interest.approve_2'),
        reject: t('report.tooltip.interest.reject_2'),
        save: t('report.tooltip.save_candidate'),
        remove: t('report.tooltip.remove_candidate')
      }
    }
    return defaultTexts
  }, [interest, t])

  return (
    <>
      <AproveInterestDialog
        open={approveDialog}
        onClose={toggleApproveDialog}
        interestId={interest?.id}
        status={interest?.attributes.status}
      />
      <RejectInterestDialog
        open={rejectDialog}
        onClose={toggleRejectDialog}
        interestId={interest?.id}
      />

      {interest.attributes.status !== 'placed' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: small ? 'row' : 'column',
            alignItems: 'center'
          }}
        >
          <Box mb={1}>
            {!small && (
              <Tooltip title={texts.approve}>
                <ApproveButton
                  variant="contained"
                  color="success"
                  size="large"
                  endIcon={<CheckIcon />}
                  onClick={toggleApproveDialog}
                >
                  {texts.approve}
                </ApproveButton>
              </Tooltip>
            )}
          </Box>
          <Stack gap={small ? 2 : 3} direction={small ? 'row-reverse' : 'row'} mt={1}>
            {small && (
              <Tooltip title={texts.approve}>
                <Fab onClick={toggleApproveDialog} size="small" color="success">
                  <CheckIcon />
                </Fab>
              </Tooltip>
            )}
            <Tooltip title={texts.reject}>
              <Fab onClick={toggleRejectDialog} size="small" color="error">
                <ClearIcon />
              </Fab>
            </Tooltip>
            {interest?.id && <SaveButtonInterest id={interest?.id} />}

            {!small && (
              <Tooltip title={t('report.tooltip.copyLink')}>
                <Fab onClick={() => copyToClipboard()} size="small" color="info">
                  <CopyIcon color="action" />
                </Fab>
              </Tooltip>
            )}
          </Stack>
        </Box>
      )}
    </>
  )
}

export default ActionInterestButtons
