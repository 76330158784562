import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { APIBasicJobInfoDatum, JobAttributes } from '@/types/jobs'

export type JobInfoAttributes = Pick<JobAttributes, 'uuid' | 'title' | 'created_at' | 'status'>

export const useJobInfo = (jobId?: number | string): UseQueryResult<APIBasicJobInfoDatum> => {
  const query = useQuery<APIBasicJobInfoDatum>(['client_area', 'jobs', 'info', jobId], {
    enabled: !!jobId
  })

  return query
}
