// based in https://dev.to/fazliddin04/react-router-v6-animated-transitions-diy-3e6l
import { Box } from '@mui/material'
import {
  useContext, createContext, useState, useEffect
} from 'react'
import { Location, useLocation } from 'react-router-dom'

export type LocationContent = {
  displayLocation?: Location
}

export const LocationContext = createContext<LocationContent>({})

type Props = {
  children?: React.ReactNode
}

export const ProvideLocationContext: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransistionStage] = useState('fadeIn')

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage('fadeOut')
  }, [location, displayLocation])

  const context = {
    displayLocation
  }

  return (
    <LocationContext.Provider value={context}>
      <Box
        className={`${transitionStage}`}
        onAnimationEnd={() => {
          if (transitionStage === 'fadeOut') {
            setTransistionStage('fadeIn')
            setDisplayLocation(location)
          }
        }}
      >
        {children}
      </Box>
    </LocationContext.Provider>
  )
}

export const useLocationContext = (): LocationContent => useContext(LocationContext)
