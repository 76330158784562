import { Grid, List } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { APIJobPositionData, JobPositionStatus, MembershipType } from '@/types/job_position'
import { useCurrentUser } from '@/hooks/auth'
import GraphCard from '@/components/dashboard/GraphCard'
import QuickSearchToolbar from '@/components/shared/QuickSearchToolbar'
import JobPositionItem from './JobPositionItem'
import { JobPositionsFilters, JobPositionsStatusOption, TypeOption } from './JobPositionsTable'

type Props = {
  status?: JobPositionStatus
  jobPositionsStatusOptions: JobPositionsStatusOption[]
  projectId?: string | number | null
}

const JobPositionsList: React.FC<Props> = ({ status, jobPositionsStatusOptions, projectId }) => {
  const { t } = useTranslation()
  const { user } = useCurrentUser()
  const [search, setSearch] = useState<string | null>('')
  const [filters, setFilters] = useState<JobPositionsFilters>({
    status: jobPositionsStatusOptions[0],
    membershipEmail: null,
    projectId: null
  })
  const { data: positions } = useQuery<APIJobPositionData>([
    'client_area',
    'jobs',
    'positions',
    {
      status: filters.status?.value,
      membershipEmail: filters.membershipEmail?.value,
      project_id: projectId
    }
  ])

  const { data: members } = useQuery<MembershipType[]>(['client_area', 'jobs', 'memberships'])

  const membersOptions: TypeOption[] | undefined = useMemo(
    () => members?.map((member) => ({
      value: member.email,
      label: member.name ? member.name : member.email
    })),
    [members]
  )

  return (
    <Grid item xs={12}>
      <GraphCard
        height={800}
        title={t('homepage.graphs.title.job_positions_table', {
          company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
        })}
        loading={!positions}
      >
        <QuickSearchToolbar
          filters={filters}
          setFilters={setFilters}
          statusOptions={jobPositionsStatusOptions}
          membersOptions={membersOptions}
          isMobile
          search={search}
          setSearch={setSearch}
        />
        <List
          sx={{
            maxHeight: '95%',
            width: '100%',
            overflowY: 'auto',
            // Align list text with card header
            ml: -1.9
          }}
        >
          {positions?.data?.map((position) => (
            <JobPositionItem position={position} key={position.id} />
          ))}
        </List>
      </GraphCard>
    </Grid>
  )
}

export default JobPositionsList
