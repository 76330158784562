import { Box, ListItemText, Typography } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import ListItemLink from '@/components/shared/ListItemLink'
import { APIJobData } from '@/types/jobs'
import JobInfoCell from './JobInfoCell'

type Props = {
  job: APIJobData
}

const JobInConstructionItem: React.FC<Props> = ({ job }) => {
  const { t } = useTranslation()

  const jobLink = `/vaga/${job.attributes.uuid}/candidatos`

  return (
    <ListItemLink
      to={jobLink}
      sx={{
        width: '100%'
      }}
    >
      <ListItemText
        primary={<JobInfoCell jobId={job.id} fieldKey="title" withoutTooltip />}
        secondaryTypographyProps={{ variant: 'caption' }}
        secondary={(
          <>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="caption"
              color="text.primary"
            >
              {job.attributes.description?.body}
            </Typography>

            {job.attributes.last_comment && (
              <Box
                sx={{
                  mt: 1
                }}
              >
                <Typography variant="body2">
                  {t('homepage.tables.job_positions.column.last_comment')}
                </Typography>
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: job.attributes.last_comment }}
                />
              </Box>
            )}
          </>
        )}
      />
    </ListItemLink>
  )
}

export default JobInConstructionItem
