/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */
import { Component, createRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import common from '@mui/material/colors/common'
import grey from '@mui/material/colors/grey'
import BrokenImage from '@mui/icons-material/BrokenImage'

type Props = {
  /** Duration of the fading animation, in milliseconds. */
  animationDuration?: number
  /** Override aspect ratio. */
  aspectRatio?: number
  /** Override the object fit to cover. */
  cover?: boolean
  /** Override the background color. */
  color?: string
  /** Disables the error icon if set to true. */
  disableError?: boolean
  /** Disables the loading spinner if set to true. */
  disableSpinner?: boolean
  /** Disables the transition after load if set to true. */
  disableTransition?: boolean
  /** Override the error icon. */
  errorIcon?: React.ReactNode
  /** Override the inline-styles of the container
   * that contains the loading spinner and the error icon.
   */
  iconContainerStyle?: React.CSSProperties
  /** Override the inline-styles of the image. */
  imageStyle?: React.CSSProperties
  /** Override the loading component. */
  loading?: React.ReactNode
  /** Fired when the user clicks on the image happened. */
  onClick?: () => void
  /** Fired when the image failed to load. */
  onError?: (error?: any) => void
  /** Fired when the image finished loading. */
  onLoad?: (event?: any) => void
  /** Specifies the URL of an image. */
  src: string
  /** Override the inline-styles of the root element. */
  style?: React.CSSProperties
}

type State = {
  src: string
  imageError: boolean
  imageLoaded: boolean
}

/**
 * Fork from: https://www.npmjs.com/package/@jy95/material-ui-image
 *
 * Images are ugly until they're loaded.
 * Materialize it with material image!
 * It will fade in like the material image loading pattern suggests.
 * @see [Image loading patterns](https://material.io/guidelines/patterns/loading-images.html)
 */
export default class Image extends Component<Props, State> {
  image: React.Ref<HTMLImageElement>

  static defaultProps = {
    animationDuration: 3000,
    aspectRatio: 1,
    color: common.white,
    disableError: false,
    disableSpinner: false,
    disableTransition: false,
    errorIcon: <BrokenImage style={{ width: 48, height: 48, color: grey[300] }} />,
    loading: <CircularProgress size={48} />
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      imageError: false,
      imageLoaded: false,
      src: this.props.src
    }
    this.image = createRef()
  }

  static getDerivedStateFromProps(props: Props, state: State): State | null {
    if (state.src !== props.src) {
      return {
        imageError: false,
        imageLoaded: false,
        src: props.src
      }
    }

    return null
  }

  componentDidMount(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const img = this.image?.current

    if (img && img.complete) {
      // image loaded before the component rendered (e.g. SSR), see #43 and #51
      if (img.naturalWidth === 0) {
        this.handleImageError()
      } else {
        this.handleLoadImage()
      }
    }
  }

  getStyles() {
    const {
      animationDuration,
      aspectRatio,
      cover,
      color,
      imageStyle,
      disableTransition,
      iconContainerStyle,
      style
    } = this.props

    const imageTransition = !disableTransition && {
      opacity: this.state.imageLoaded ? 1 : 0,
      filterBrightness: this.state.imageLoaded ? 100 : 0,
      filterSaturate: this.state.imageLoaded ? 100 : 20,
      transition: `
        filterBrightness ${(animationDuration || 3000) * 0.75}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        filterSaturate ${animationDuration}ms cubic-bezier(0.4, 0.0, 0.2, 1),
        opacity ${(animationDuration || 3000) / 2}ms cubic-bezier(0.4, 0.0, 0.2, 1)`
    }

    const styles = {
      root: {
        backgroundColor: color,
        paddingTop: `calc(1 / ${aspectRatio} * 100%)`,
        position: 'relative' as const,
        ...style
      },
      image: {
        width: '100%',
        height: '100%',
        position: 'absolute' as const,
        objectFit: (cover ? 'cover' : 'inherit') as 'cover' | 'inherit',
        top: 0,
        left: 0,
        ...imageTransition,
        ...imageStyle
      },
      iconContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute' as const,
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none' as const,
        ...iconContainerStyle
      }
    }

    return styles
  }

  handleLoadImage = (e?: any): void => {
    this.setState({ imageLoaded: true, imageError: false })
    if (this.props.onLoad) {
      this.props.onLoad(e)
    }
  }

  handleImageError = (e?: any): void => {
    if (this.props.src) {
      this.setState({ imageError: true })
      if (this.props.onError) {
        this.props.onError(e)
      }
    }
  }

  render() {
    const styles = this.getStyles()

    const {
      animationDuration,
      aspectRatio,
      color,
      cover,
      disableError,
      disableSpinner,
      disableTransition,
      errorIcon,
      iconContainerStyle,
      imageStyle,
      loading,
      onClick,
      style,
      ...image
    } = this.props

    return (
      <div style={styles.root} onClick={onClick}>
        {image.src && (
          <img
            {...image}
            ref={this.image}
            style={styles.image}
            onLoad={this.handleLoadImage}
            onError={this.handleImageError}
          />
        )}
        <div style={styles.iconContainer}>
          {!disableSpinner && !this.state.imageLoaded && !this.state.imageError && loading}
          {!disableError && this.state.imageError && errorIcon}
        </div>
      </div>
    )
  }
}
