import styled from '@emotion/styled'
import {
  Alert, Button, Stack, Typography
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from '@yu/i18n'
import { useCurrentUser } from '@/hooks/auth'
import { logout } from '@/api/authentication'

const MainContainer = styled.div`
  z-index: 999999;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
`

const ImpersonationButton: React.FC = () => {
  const { user } = useCurrentUser()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const handleLogout = async () => {
    await logout()
    queryClient.invalidateQueries(['users', 'current'])
  }

  if (user?.is_impersonating) {
    return (
      <MainContainer>
        <Alert
          sx={{
            height: '71px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          severity="info"
        >
          <Stack flexDirection="row" alignItems="center">
            <Typography sx={{ fontSize: '14px' }}>
              {t('impersonation.is_impersonating', { userName: user?.name })}
            </Typography>
            <Button sx={{ fontSize: '14px' }} onClick={() => handleLogout()}>
              {t('impersonation.stop')}
            </Button>
          </Stack>
        </Alert>
      </MainContainer>
    )
  }
  return <></>
}

export default ImpersonationButton
