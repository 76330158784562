import styled from '@emotion/styled'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { Link } from 'react-router-dom'
import { InterestAttributes, InterestStatus } from '@/types/interest'
import { BaseAPISerializer } from '@/types/api'
import CompareDialog from '@/components/candidates/CompareTable/CompareDialog'

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-sizeSmall {
    padding: 0px;
  }
`

type Props = {
  count: number
  title: string
  interests: BaseAPISerializer<InterestAttributes>[]
  status: boolean
}

const YuProTribeInterests: React.FC<Props> = ({
  count, title, interests, status
}) => {
  const { t } = useTranslation(['candidates', 'text'])
  const emojis = ['👩🏻‍💻', '👨🏻‍💻', '👩🏿‍💻', '👨🏿‍💻', '👩🏽‍💻', '👨🏽‍💻', '👩🏾‍💻', '👨🏾‍💻', '👩🏼‍💻', '👨🏼‍💻']

  return (
    <Grid item xs={12} md={3} sm={12} sx={(theme) => ({ minWidth: { xs: 280, md: 400 } })}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between'
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            pt: 1
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            mb={2}
            dangerouslySetInnerHTML={{
              __html: status
                ? `${t(`candidates:${title as InterestStatus}`, {
                  defaultValue: status
                })} (${count})`
                : t('text:homepage.tables.yupro.info.tribe_count', {
                  name: title,
                  count,
                  emoji: emojis[Math.floor(Math.random() * emojis.length)]
                })
            }}
          />
          <Stack flexDirection="row" maxWidth="100%" flexWrap="wrap" gap={2} mb={1}>
            {interests?.map((interest) => (
              <Tooltip
                key={interest.id}
                title={t('text:homepage.tables.yupro.actions.see_profile', {
                  name: interest?.attributes?.person?.data?.attributes?.name
                })}
              >
                <Link
                  to={`/person/reports/${interest?.attributes?.person?.data?.attributes?.uuid}/${interest?.attributes?.id}`}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledIconButton size="small">
                    <Avatar
                      alt={interest?.attributes?.person?.data?.attributes?.name}
                      src={interest?.attributes?.person?.data?.attributes?.avatar}
                    />
                  </StyledIconButton>
                </Link>
              </Tooltip>
            ))}
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: 'right', alignSelf: 'end' }}>
          <CompareDialog interests={interests} tribeName={title} tribeButton />
        </CardActions>
      </Card>
    </Grid>
  )
}

export default YuProTribeInterests
