import { Grid } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import { useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { Datum } from 'yu-open-lib/dist/components/graphs/EChartsFunnelGraph'
import { useNivoTheme } from '@/hooks/nivo'
import { useCurrentUser } from '@/hooks/auth'
import DefaultSkeleton from '@/components/dashboard_cards/DefaultSkeleton'
import GraphCard from '@/components/dashboard/GraphCard'
import BasicTextTooltip from '@/components/dashboard/BasicTextTooltip'

type Props = {
  projectId?: string | number | undefined | null
}

const StepsBarGraph: React.FC<Props> = ({ projectId }) => {
  const { data: stepsData, isFetched } = useQuery<Datum[]>([
    'client_area',
    'overall',
    'steps_funnel',
    { project_id: projectId }
  ])
  const { t } = useTranslation()

  const { user } = useCurrentUser()

  const colors = useMemo(() => {
    if (!stepsData) return []

    const firstClientIndex = stepsData.findIndex((step) => step.id === 'client')

    return stepsData.map((step, index) => {
      // Data is in reverse order, steps after client have a darker color
      if (index > firstClientIndex) return '#32a5bf'

      return '#146375'
    })
  }, [stepsData])

  const nivoTheme = useNivoTheme()

  if (isFetched && !stepsData) {
    return null
  }

  return (
    <Grid item xs={12} lg={8} xl={6} sx={{ mt: 2, mb: 2 }}>
      <GraphCard
        height={320}
        title={t('homepage.graphs.title.steps_funnel', {
          company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
        })}
        loading={!stepsData}
        cardContentProps={{
          sx: {
            height: '500px !important',
            width: '100% !important'
          }
        }}
      >
        {stepsData ? (
          <ResponsiveBar
            theme={nivoTheme}
            data={stepsData
              .map((step) => ({
                [step.label]: step.value,
                ...step
              }))
              .reverse()}
            keys={stepsData.map((step) => step.label)}
            indexBy="label"
            margin={{
              top: 20,
              right: 62,
              bottom: 0,
              left: 14
            }}
            padding={0}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={colors.reverse()}
            axisTop={null}
            labelSkipWidth={0}
            labelSkipHeight={0}
            labelTextColor="white"
            axisBottom={null}
            axisLeft={null}
            tooltip={(input) => (
              <BasicTextTooltip
                text={input.id.toString()}
                value={input.value}
                color={input.color}
              />
            )}
            axisRight={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              // Line-breaks instead of spaces for legends
              format: (v) => v
                .split(' ')
                .map((word: string) => word[0].toUpperCase() + word.slice(1))
                .join('\n'),
              legendPosition: 'middle',
              legendOffset: -40
            }}
          />
        ) : (
          <DefaultSkeleton />
        )}
      </GraphCard>
    </Grid>
  )
}

export default StepsBarGraph
