import {
  Grid, Skeleton, Theme, Typography, TypographyProps
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

type Props = {
  subtext?: string
  errorMessage?: string
  value?: number | string
  text: string
  marginTop?: number
  loading?: boolean
  ValueTypographyProps?: Partial<TypographyProps>
  LabelTypographyProps?: Partial<TypographyProps>
}

type StyleProps = {
  marginTop: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: ({ marginTop }) => marginTop
  },
  value: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 38,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    fontSize: 15
  }
}))

const ChartInfoLabel: React.FC<Props> = ({
  value,
  text,
  marginTop = 0,
  loading = false,
  ValueTypographyProps,
  LabelTypographyProps,
  subtext,
  errorMessage
}) => {
  const classes = useStyles({ marginTop })

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton className={classes.value} width="40%" sx={{ ml: '30%' }} />
        ) : (
          <>
            {errorMessage && <Typography sx={{ opacity: '0.7' }}>{errorMessage}</Typography>}
            {!errorMessage && (
              <Typography className={classes.value} {...ValueTypographyProps}>
                {value}
                {subtext && (
                  <Typography variant="h6" sx={{ paddingBottom: '5px', marginTop: '-15px' }}>
                    {subtext}
                  </Typography>
                )}
              </Typography>
            )}
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label} {...LabelTypographyProps}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ChartInfoLabel
