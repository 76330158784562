import { useNavigate } from 'react-router-dom'

type DelayedRedirect = (route: string) => void

export const useRedirectTo = (delayInSeconds?: number): Record<string, DelayedRedirect> => {
  let delay = 0
  if (delayInSeconds) {
    delay = delayInSeconds * 1000
  }

  const navigate = useNavigate()
  // eslint-disable-next-line max-len
  const delayedRedirect: DelayedRedirect = (route: string) => setTimeout(() => navigate(route), delay)

  return { delayedRedirect }
}

export const jobCandidates = (uuid: string): string => `/vaga/${uuid}/candidatos`
