import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { ResponsiveChoropleth } from '@nivo/geo'

import { useNivoTheme } from '@/hooks/nivo'
import brazilGeoData from '@/data/geo/brazil.json'
import DefaultSkeleton from '@/components/dashboard_cards/DefaultSkeleton'
import { CandidatesGeoData } from '@/types/dashboards'

const colors = ['#c6dbef', '#6baed6', '#2171b5', '#08519c', '#08306b']

type Props = {
  data: CandidatesGeoData | undefined
}

const CandidatesChrolopleth: React.FC<Props> = ({ data }) => {
  const nivoTheme = useNivoTheme()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!data) {
    return <DefaultSkeleton />
  }

  return (
    <ResponsiveChoropleth
      theme={nivoTheme}
      data={data.counts}
      features={brazilGeoData.features}
      margin={{
        top: isMobile ? -110 : -130,
        right: 0,
        bottom: 0,
        left: isMobile ? 380 : 480
      }}
      colors={colors}
      domain={[0, data.total]}
      unknownColor="#666666"
      label="properties.name"
      valueFormat=".1s"
      projectionScale={isMobile ? 380 : 480}
      graticuleLineColor="#dddddd"
      borderWidth={0.5}
      borderColor="#152538"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      legends={[
        {
          anchor: 'bottom-left',
          direction: 'column',
          justify: true,
          translateX: isMobile ? -370 : -450,
          translateY: 110,
          itemsSpacing: 0,
          itemWidth: 94,
          itemHeight: 18,
          itemDirection: 'left-to-right',
          itemTextColor: '#fff',
          itemOpacity: 0.85,
          symbolSize: 18,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}

export default CandidatesChrolopleth
