import React from 'react'

import { Control, Controller } from 'react-hook-form'

import { LoadingButton } from '@mui/lab'
import { SxProps, TextField, Theme } from '@mui/material'

type Props = {
  control: Control<any>
  name: string
  sx?: SxProps<Theme>
  variant?: 'standard' | 'filled' | 'outlined'
  label?: string
  loading?: boolean
  placeholder?: string
}

const FormTextField: React.FC<Props> = ({
  control,
  name,
  sx,
  variant = 'standard',
  loading = false,
  placeholder,
  label
}) => (
  <Controller
    render={({ field: { onChange, ...field } }) => (
      <TextField
        {...field}
        sx={sx}
        onChange={onChange}
        variant={variant}
        label={label}
        disabled={loading}
        fullWidth
        size="small"
        placeholder={placeholder}
        focused={!!placeholder}
        InputProps={{
          endAdornment: loading && <LoadingButton sx={{ height: '25px' }} loading disabled />
        }}
      />
    )}
    name={name}
    control={control}
  />
)

export default FormTextField
