import { useQuery } from '@tanstack/react-query'

import { UserPermissionsModel, userPermissions } from '@/types/user_permissions'

import { useCurrentUser } from './auth'

// defines dynamic type 'Model' in order to
// implement autocomplete for hook params
export const usePermission = <Model extends UserPermissionsModel>(
  model: Model,
  // access keys of specific object defined by first param
  actionKey: keyof typeof userPermissions[Model],
  requireLogin = true
): boolean | undefined => {
  const { loggedIn } = useCurrentUser()

  const actionName = userPermissions[model][actionKey]
  const { data } = useQuery<{ can: boolean }>(
    ['client_area', 'authorization', 'permissions', 'can_access', { model, actionName }],
    {
      enabled: !requireLogin || loggedIn
    }
  )

  if (requireLogin && !loggedIn) {
    return false
  }

  return data?.can
}
