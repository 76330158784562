import { useQueryClient } from '@tanstack/react-query'
import api from '@/api'
import { useCurrentUser } from '@/hooks/auth'

/**
 * For debugging purposes, impersonate other users in Dev or staging, for Admins
 */
const UserIdSelector: React.FC = () => {
  const queryClient = useQueryClient()
  const { isUserAdmin } = useCurrentUser()

  if ((import.meta.env.DEV || window.location.hostname.includes('staging')) && isUserAdmin) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 90,
          right: 20,
          zIndex: 9999
        }}
      >
        <input
          placeholder="user_id"
          type="text"
          name="user_id"
          onChange={(event) => {
            api.defaults.params = { ...api.defaults.params, user_id: event.target.value }
            queryClient.invalidateQueries()
          }}
        />
      </div>
    )
  }

  return null
}

export default UserIdSelector
