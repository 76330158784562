import { Link } from 'react-router-dom'
import {
  Grid, Link as MuiLink, Tooltip, IconButton
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { useTranslation } from '@yu/i18n'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AccountCircle } from '@mui/icons-material'
import { useCallback, useMemo, useState } from 'react'
import { APIBasicJobInfoDatum } from '@/types/jobs'
import {
  APIJobPositionData,
  JobPosition,
  JobPositionStatus,
  MembershipType
} from '@/types/job_position'
import { useDataGrid } from '@/hooks/datagrid'
import GraphCard from '@/components/dashboard/GraphCard'
import QuickSearchToolbar from '@/components/shared/QuickSearchToolbar'
import { useCurrentUser } from '@/hooks/auth'
import JobInfoCell from './JobInfoCell'
import { useHoverPosition } from './hooks/positionsTable'
import JobPositionStatusChip from './JobPositionStatusChip'
import JobDateCell from './JobDateCell'
import JobTitleCell from './JobTitleCell'

type Props = {
  status?: JobPositionStatus
  jobPositionsStatusOptions: JobPositionsStatusOption[]
  projectId?: number | string | null
}

export type JobPositionsFilters = {
  status: JobPositionsStatusOption | null
  membershipEmail: TypeOption | null
  projectId: TypeOption | null
}

export type TypeOption = {
  label: string
  value: string
}

export type JobPositionsStatusOption = {
  value: JobPositionStatus[]
  label: string
}

const JobPositionsTable: React.FC<Props> = ({ status, jobPositionsStatusOptions, projectId }) => {
  const { t } = useTranslation()

  const [filters, setFilters] = useState<JobPositionsFilters>({
    status: jobPositionsStatusOptions[0],
    membershipEmail: null,
    projectId: null
  })
  const { data: positions } = useQuery<APIJobPositionData>([
    'client_area',
    'jobs',
    'positions',
    {
      status: filters.status?.value,
      membershipEmail: filters.membershipEmail?.value,
      project_id: projectId
    }
  ])

  const { data: members } = useQuery<MembershipType[]>([
    'client_area',
    'jobs',
    'memberships',
    { project_id: projectId }
  ])

  const { data: averagePlacedDays } = useQuery<{ value: number }>([
    'client_area',
    'overall',
    'average_placed_days'
  ])

  const membersOptions: TypeOption[] | undefined = useMemo(
    () => members?.map((member) => ({
      value: member.email,
      label: member.name ? member.name : member.email
    })),
    [members]
  )

  const { user } = useCurrentUser()

  const dataGridProps = useDataGrid()

  const queryClient = useQueryClient()

  const getJob = useCallback(
    (jobId: number | string) => queryClient.getQueryData<APIBasicJobInfoDatum>(['client_area', 'jobs', 'info', jobId]),
    [queryClient]
  )

  const { onMouseEnter, clearSelectionModel } = useHoverPosition()

  const columns = useMemo<GridColDef<JobPosition>[]>(
    () => [
      {
        field: 'job_title',
        headerName: t('homepage.tables.job_positions.column.job_title', {
          ns: 'text'
        }),
        valueGetter(cell) {
          return getJob(cell.row.attributes.job_id)?.data?.attributes?.title
        },
        renderCell(cell) {
          return <JobTitleCell jobAttributes={cell.row.attributes} />
        },
        flex: 1,
        width: 800
      },
      {
        field: 'status',
        align: 'center',
        headerAlign: 'center',
        headerName: t('homepage.tables.job_positions.column.position_status'),
        valueGetter(cell) {
          return t(`position.status.${cell.row.attributes.status as JobPositionStatus}`)
        },
        type: 'singleSelect',
        valueOptions: [
          t('position.status.canceled'),
          t('position.status.on_hold'),
          t('position.status.open'),
          t('position.status.placed')
        ] as JobPositionStatus[],
        renderCell(cell) {
          return <JobPositionStatusChip position={cell.row} />
        },
        width: 160
      },
      {
        field: 'days_open',
        align: 'center',
        headerAlign: 'center',
        headerName: t('homepage.tables.job_positions.column.days_open'),
        valueGetter(cell) {
          const finishedAt = cell.row.attributes.canceled_at || cell.row.attributes.placed_at

          return dayjs(finishedAt || new Date()).diff(dayjs(cell.row.attributes.open_at), 'days')
        },
        renderCell(cell) {
          const finishedAt = cell.row.attributes.canceled_at || cell.row.attributes.placed_at

          return (
            <JobDateCell
              date={finishedAt || new Date()}
              openAt={cell.row.attributes.open_at}
              averageDays={averagePlacedDays?.value}
              positionStatus={cell.row.attributes.status}
              jobId={cell.row.attributes.job_id}
            />
          )
        },
        width: 140
      },
      {
        field: 'yu_interview',
        headerName: t('homepage.tables.job.column.yu_interview', {
          company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
        }),
        valueGetter(cell) {
          return getJob(cell.row.attributes.job_id)?.meta?.candidate_counts?.yu_interview || 0
        },
        align: 'center',
        headerAlign: 'center',
        renderCell(cell) {
          return (
            <JobInfoCell
              jobId={cell.row.attributes.job_id}
              fieldKey="yu_interview"
              fromMeta
              tooltip={t('position.tooltips.candidate_numbers')}
            />
          )
        },
        width: 160
      },
      {
        field: 'shortlist',
        headerName: t('homepage.tables.job.column.shortlist'),
        valueGetter(cell) {
          return getJob(cell.row.attributes.job_id)?.meta?.candidate_counts?.shortlist || 0
        },
        align: 'center',
        headerAlign: 'center',
        renderCell(cell) {
          return (
            <JobInfoCell
              jobId={cell.row.attributes.job_id}
              fieldKey="shortlist"
              fromMeta
              tooltip={t('position.tooltips.candidate_numbers')}
            />
          )
        },
        width: 160
      },
      {
        field: 'client_interview',
        headerName: t('homepage.tables.job.column.client_interview'),
        valueGetter(cell) {
          return getJob(cell.row.attributes.job_id)?.meta?.candidate_counts?.client_interview || 0
        },
        align: 'center',
        headerAlign: 'center',
        renderCell(cell) {
          return (
            <JobInfoCell
              jobId={cell.row.attributes.job_id}
              fieldKey="client_interview"
              fromMeta
              tooltip={t('position.tooltips.candidate_numbers')}
            />
          )
        },
        width: 160
      },
      {
        field: 'offer',
        headerName: t('homepage.tables.job.column.offer'),
        valueGetter(cell) {
          return getJob(cell.row.attributes.job_id)?.meta?.candidate_counts?.offer || 0
        },
        align: 'center',
        headerAlign: 'center',
        renderCell(cell) {
          return (
            <JobInfoCell
              jobId={cell.row.attributes.job_id}
              fieldKey="offer"
              fromMeta
              tooltip={t('position.tooltips.candidate_numbers')}
            />
          )
        },
        width: 160
      },
      {
        field: 'candidate',
        headerName: t('homepage.tables.job_positions.column.candidate'),
        align: 'center',
        valueGetter(cell) {
          return cell.row.meta?.name
        },
        renderCell(cell) {
          if (!cell.row.meta?.job_application_uuid || !cell.row.meta?.name) {
            return null
          }

          return (
            <Tooltip title={cell.row.meta.name}>
              <MuiLink component={Link} to={`/reports/${cell.row.meta.job_application_uuid}`}>
                <IconButton>
                  <AccountCircle />
                </IconButton>
              </MuiLink>
            </Tooltip>
          )
        },
        minWidth: 80
      }
    ],
    [getJob, t, user?.client_type, averagePlacedDays]
  )

  return (
    <>
      <Grid item xs={12}>
        <GraphCard
          height="100%"
          sx={{ minHeight: 800 }}
          title={t('homepage.graphs.title.job_positions_table', {
            company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
          })}
          loading={!positions}
          cardContentProps={{
            onMouseLeave() {
              clearSelectionModel()
            }
          }}
        >
          <DataGrid
            components={{
              Toolbar() {
                return (
                  <QuickSearchToolbar
                    filters={filters}
                    setFilters={setFilters}
                    statusOptions={jobPositionsStatusOptions}
                    membersOptions={membersOptions}
                  />
                )
              }
            }}
            getRowHeight={() => 'auto'}
            sx={{
              minHeight: 700,
              height: '95%',
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal'
              }
            }}
            componentsProps={{
              row: {
                onMouseEnter
              },
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
            pageSizeOptions={[5, 10, 20, 50]}
            // paginationModel={{ pageSize: 5 }}
            initialState={
            status
              ? {
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                    page: 0
                  }
                },
                filter: {
                  filterModel: {
                    items: [
                      {
                        field: 'status',
                        operator: 'is',
                        value: t(`position.status.${status as JobPositionStatus}`)
                      }
                    ]
                  }
                }
              }
              : {
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                    page: 0
                  }
                }
              }
          }
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
            columns={columns}
            rows={positions?.data || []}
            loading={!positions}
            {...dataGridProps}
          />
        </GraphCard>
      </Grid>
    </>
  )
}

export default JobPositionsTable
