import api from '@/api'
import { APICandidatesOverviewData } from '@/types/candidates'
import { DataAPISerializer, BaseAPISerializer } from '@/types/api'
import { JobAttributes } from '@/types/jobs'

export type APICandidatesMeta = {
  job: BaseAPISerializer<JobAttributes>
}

export type CandidateResponse = DataAPISerializer<APICandidatesOverviewData, APICandidatesMeta>

export const getCandidate = async (uuid: string): Promise<CandidateResponse> => {
  const { data } = await api.get(`client_area/candidates/get/${uuid}`)

  return data
}
