import { Favorite } from '@mui/icons-material'
import { Fab, Tooltip } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useToasts } from 'react-toast-notifications'
import { useCurrentUser } from '@/hooks/auth'
import { useSaveInterests } from '@/api/interests'

type Props = {
  id: string
  smaller?: boolean
}

const SaveButtonInterest: React.FC<Props> = ({ id, smaller = false }) => {
  const { saveInterest, removeInterest } = useSaveInterests(id)
  const { savedInterestsIds } = useCurrentUser()
  const { t } = useTranslation()

  const { addToast } = useToasts()

  const isSaved = useMemo(() => {
    if (!id || !savedInterestsIds) return false
    return savedInterestsIds?.includes?.(id)
  }, [savedInterestsIds, id])

  const handleSaveInterest = (remove = false) => {
    const objCallback = {
      onSuccess: () => addToast(
        remove
          ? t('report.toasts.success.remove_interest')
          : t('report.toasts.success.save_interest'),
        { appearance: 'success' }
      ),
      onError: () => addToast(
        remove
          ? t('report.toasts.errors.remove_interest')
          : t('report.toasts.errors.save_interest'),
        { appearance: 'error' }
      )
    }
    if (remove) {
      removeInterest(undefined, objCallback)
    } else {
      saveInterest(undefined, objCallback)
    }
  }

  const texts = {
    save: t('report.tooltip.save_interest'),
    remove: t('report.tooltip.remove_interest')
  }

  return (
    <Tooltip title={isSaved ? texts.remove : texts.save}>
      <Fab
        sx={{
          border: smaller ? '1px solid #fff' : 'none',
          width: smaller ? '35px' : undefined,
          height: smaller ? '35px' : undefined,
          '&:hover': {
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.8)'
          }
        }}
        onClick={() => handleSaveInterest(isSaved)}
        size="small"
      >
        <Favorite color={isSaved ? 'error' : undefined} />
      </Fab>
    </Tooltip>
  )
}

export default SaveButtonInterest
