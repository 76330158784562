import {
  Box, Button, Grid, Typography
} from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from '@yu/i18n'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Navigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ThemeContext, ThemeContextType } from '@/hooks/theme'
import { useCurrentUser } from '@/hooks/auth'
import { logout } from '@/api/authentication'
import { ResponsibleCompany } from '@/types/graphs_blacklists'
import { useRequiredParams } from '@/hooks'
import yuriPisca from '../assets/images/loginError/yuri-pisca.gif'
import yuriColor from '../assets/images/loginError/yuri-colorido.gif'

type Params = {
  uuid: string
}

const WrongLoginPage: React.FC = () => {
  const { darkMode } = useContext(ThemeContext) as ThemeContextType
  const { user, refetch, loggedIn } = useCurrentUser()
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const { uuid } = useRequiredParams<Params>()
  const queryClient = useQueryClient()
  const { data: responsibleCompany } = useQuery<ResponsibleCompany>([
    'client_area',
    'jobs',
    uuid,
    'responsible_company'
  ])

  const logoutMutation = useMutation(logout, {
    onSuccess() {
      queryClient.invalidateQueries(['users', 'current'])
      window.location.href = 'https://jobs.jornadayu.com/'
    },
    onError() {
      addToast(t('auth.errors.sign_out'), { appearance: 'error' })
    },
    onSettled() {
      refetch()
    }
  })

  if (!loggedIn) {
    return <Navigate replace to="/login" />
  }

  return (
    <Grid
      spacing={0}
      display="flex"
      direction="row"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 200px)"
    >
      <Box sx={{ width: '30%', display: 'flex', justifyContent: 'center' }}>
        {darkMode ? (
          <img height="300px" src={yuriPisca} alt="Yu Logo piscando" />
        ) : (
          <img height="300px" src={yuriColor} alt="Yu Logo Colorida" />
        )}
      </Box>
      <Box
        maxWidth="800px"
        sx={{
          widht: '70%',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box>
          <Typography variant="h6">
            {t('login_error.email_message_one')}
            <span style={{ color: darkMode ? 'orange' : '#ee7600' }}>{user?.email}</span>
            {t('login_error.email_message_two')}
          </Typography>
          <Typography variant="h6" sx={{ marginTop: '20px' }}>
            {t('login_error.paragraph_text', {
              company: responsibleCompany === 'plonge' ? 'Plongê' : 'Yu'
            })}
            <Button
              sx={{
                color: darkMode ? 'orange' : '#ee7600',
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '1.25rem',
                lineHeight: '1.6rem'
              }}
              onClick={() => {
                logoutMutation.mutate()
              }}
            >
              {t('login_error.paragraph_button')}
            </Button>
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default WrongLoginPage
