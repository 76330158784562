import {
  Grid, Typography, useMediaQuery, useTheme
} from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import { useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { legendProps } from '@/hooks/nivo'
import GraphCard from '@/components/dashboard/GraphCard'

type LabelIdType = 'process' | 'canceled' | 'on_hold' | 'placed' | 'offer'

type DataPosition = {
  id: LabelIdType
  label: LabelIdType
  value: number
}

type Props = {
  projectId?: string | number | undefined | null
}

const PositionPie: React.FC<Props> = ({ projectId }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('md'))

  const { data: positionData, isInitialLoading } = useQuery<DataPosition[]>([
    'client_area',
    'overall',
    'positions_by_status',
    { project_id: projectId }
  ])
  const { t } = useTranslation()

  const pieData = useMemo(() => {
    if (!positionData) return []

    const total = positionData.reduce((acc, curr) => acc + curr.value, 0)

    return positionData.map((item) => ({
      id: t(`homepage.graphs.label.${item.id}`),
      label: t(`homepage.graphs.label.${item.label}`),
      value: item.value,
      percentage: `${Math.round((item.value / total) * 100)}%`
    }))
  }, [positionData, t])

  return (
    <GraphCard
      title={t('homepage.graphs.title.positions')}
      loading={isInitialLoading}
      infoText={t('homepage.graphs.info.positions')}
      showInfoDialog
      height={sm ? '600px' : undefined}
    >
      <Grid
        container
        sx={{
          height: sm ? '60%' : '100%'
        }}
      >
        {pieData.length > 0 ? (
          <>
            <Grid item xs={sm ? 12 : 7} md={sm ? 12 : 7} sx={{ height: '100%' }}>
              <ResponsivePie
                tooltip={({ datum: { label, data } }) => (
                  <Typography
                    variant="body2"
                    sx={{
                      background: '#fff',
                      color: '#000',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
                      borderRadius: '4px',
                      fontSize: '14px',
                      padding: '10px'
                    }}
                  >
                    {label}
                    :
                    {data.percentage}
                  </Typography>
                )}
                theme={{
                  tooltip: {
                    container: {
                      background: '#fff',
                      color: '#000',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
                      borderRadius: '4px',
                      fontSize: '14px',
                      padding: '10px'
                    }
                  },
                  labels: {
                    text: {
                      fontSize: '14px'
                    }
                  }
                }}
                colors={{ scheme: 'blues' }}
                data={pieData}
                margin={{
                  top: 40,
                  right: 80,
                  bottom: 80,
                  left: 80
                }}
                innerRadius={0.2}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#999"
                arcLinkLabelsThickness={4}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                enableArcLinkLabels={!(sm || md)}
              />
            </Grid>
            <Grid item xs={sm ? 12 : 5} md={sm ? 12 : 5}>
              <ResponsivePie
                data={pieData}
                margin={{
                  top: 0,
                  right: 70,
                  bottom: 0,
                  left: 51
                }}
                layers={['legends']}
                legends={[
                  {
                    ...legendProps,
                    direction: 'column',
                    translateY: sm ? 0 : 20,
                    anchor: 'top',
                    itemTextColor: '#999',
                    itemWidth: 70,
                    symbolSize: sm ? 10 : 15,
                    itemsSpacing: sm ? 10 : 29
                  }
                ]}
                theme={{
                  legends: {
                    text: {
                      fontSize: '14px'
                    }
                  }
                }}
                colors={{ scheme: 'blues' }}
              />
            </Grid>
          </>
        ) : (
          <Typography variant="h5" sx={{ opacity: 0.7 }}>
            {t('generic_error.no_positions')}
          </Typography>
        )}
      </Grid>
    </GraphCard>
  )
}

export default PositionPie
