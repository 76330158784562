import { Grid } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import GraphCard from '@/components/dashboard/GraphCard'
import ChartInfoLabel from '@/components/dashboard/ChartInfoLabel'

type Props = {
  projectId?: string | number | undefined | null
}

const ClientStepNumber: React.FC<Props> = ({ projectId }) => {
  const { data: offerNumberInfo } = useQuery<{ value: number }>([
    'client_area',
    'overall',
    'job_numbers',
    'candidates_with_client_interview',
    { project_id: projectId }
  ])
  const { t } = useTranslation('text')

  return (
    <GraphCard
      showInfoDialog
      infoText={t('homepage.cards.infos.candidates_with_client_interview')}
      height="100%"
      mobileHeight={150}
      onClick={undefined}
      headerStyles={{ padding: 0 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100% !important' }}
      >
        <Grid item>
          <ChartInfoLabel
            loading={!offerNumberInfo}
            errorMessage={
              offerNumberInfo?.value === 0 ? t('generic_error.no_data_enough') : undefined
            }
            subtext={t('homepage.graphs.label.candidates')}
            value={offerNumberInfo?.value}
            text={t('homepage.cards.label.candidates_with_client_interview')}
          />
        </Grid>
      </Grid>
    </GraphCard>
  )
}

export default ClientStepNumber
