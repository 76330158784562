import { Theme, useMediaQuery } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { EChartsFunnelGraph } from 'yu-open-lib'
import { Datum } from 'yu-open-lib/dist/components/graphs/EChartsFunnelGraph'
import { useCurrentUser } from '@/hooks/auth'
import DefaultSkeleton from '@/components/dashboard_cards/DefaultSkeleton'
import GraphCard from '@/components/dashboard/GraphCard'

type Props = {
  projectId?: string | number | undefined | null
}

const StepsFunnel: React.FC<Props> = ({ projectId }) => {
  const { data: stepsData, isFetched } = useQuery<Datum[]>([
    'client_area',
    'overall',
    'steps_funnel',
    { project_id: projectId }
  ])

  const { user } = useCurrentUser()

  const md = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'))
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const xs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  const { t } = useTranslation()

  const seriesOptions = useMemo(() => {
    if (xs) {
      return {
        top: '5',
        left: '0',
        height: '98%'
      }
    }

    if (sm) {
      return {
        top: '75',
        left: '33',
        height: '65%'
      }
    }

    if (md) {
      return {
        top: '75',
        left: '33',
        height: '65%'
      }
    }

    return {
      top: '90',
      left: '33',
      height: '70%'
    }
  }, [sm, xs, md])

  const colors = useMemo(() => {
    if (!stepsData?.length) return []

    const firstClientIndex = stepsData.findIndex((step) => step.id === 'client')

    return stepsData.map((step, index) => {
      // Data is in reverse order, steps after client have a darker color
      if (index > firstClientIndex) return '#32a5bf'

      return '#146375'
    })
  }, [stepsData])

  if (isFetched && !stepsData) {
    return null
  }

  return (
    <GraphCard
      height={450}
      title={t('homepage.graphs.title.steps_funnel', {
        company: user?.client_type === 'client_plonge' ? 'Plongê' : 'Yu'
      })}
      infoText={t('homepage.graphs.info.steps_funnel')}
      showInfoDialog
      loading={!stepsData}
      cardProps={{
        sx: {
          width: '100%'
        }
      }}
      cardContentProps={{
        sx: {
          height: '100% !important',
          width: '100%'
        }
      }}
    >
      {stepsData ? (
        <EChartsFunnelGraph
          allowEmptyValues
          withLegends={!xs}
          data={stepsData}
          colors={colors}
          seriesOptions={seriesOptions}
        />
      ) : (
        <DefaultSkeleton />
      )}
    </GraphCard>
  )
}

export default StepsFunnel
