import {
  Chip,
  Skeleton,
  Tooltip,
  Typography,
  Link as MuiLink,
  TypographyProps as MuiTypographyProps
} from '@mui/material'
import { Props as StatusChipProps } from 'yu-open-lib/dist/components/chips/StatusChip'
import { StatusChip } from 'yu-open-lib'
import { Link } from 'react-router-dom'
import { BasicJobInfoAttributes, JobInfoMeta } from '@/types/jobs'
import { useJobInfo } from '@/hooks/jobs'

type BaseProps = {
  jobId: number | string
  /**
   * Disable tooltip on hover
   *
   * @default false
   */
  withoutTooltip?: boolean
  /**
   * Cell becomes a link to the Job's page
   *
   * @default false
   */
  hyperLink?: boolean
  /**
   * Tooltip to replace the regular value tooltip
   */
  tooltip?: React.ReactNode
  TypographyProps?: MuiTypographyProps
}

type AsMetaProps = {
  fromMeta: true
  fieldKey: keyof JobInfoMeta['candidate_counts']
}

type AsAttributeProps = {
  fromMeta?: never
  fieldKey: keyof BasicJobInfoAttributes
}

type AsChipProps = {
  asChip: true
  ChipProps: Partial<StatusChipProps>
}

type AsTextProps = {
  asChip?: never
  ChipProps?: never
}

type Props = BaseProps & (AsMetaProps | AsAttributeProps) & (AsChipProps | AsTextProps)

const JobInfoCell: React.FC<Props> = ({
  jobId,
  fieldKey,
  hyperLink = false,
  fromMeta = false,
  withoutTooltip = false,
  asChip = false,
  tooltip,
  ChipProps,
  TypographyProps
}) => {
  const { data: job } = useJobInfo(jobId)

  if (!job) {
    if (asChip) {
      return (
        <Chip
          label={(
            <Skeleton
              sx={{
                borderRadius: 4
              }}
              width={Math.floor(Math.random() * (95 - 80 + 1)) + 80}
            />
          )}
        />
      )
    }

    return (
      <Typography variant="body1">
        <Skeleton width={120} height={50} />
      </Typography>
    )
  }

  const value: string = (fromMeta
    ? job.meta?.candidate_counts?.[fieldKey as keyof JobInfoMeta['candidate_counts']]
    : job.data.attributes[fieldKey as keyof BasicJobInfoAttributes]
  )?.toString() || ''

  if (asChip) {
    return (
      <StatusChip
        value={value}
        tooltip={withoutTooltip ? undefined : ChipProps?.tooltip || value}
        {...ChipProps}
      />
    )
  }

  const Text = (
    <Tooltip title={tooltip || value || false} open={withoutTooltip ? false : undefined}>
      <Typography variant="body1" {...TypographyProps}>
        {value}
      </Typography>
    </Tooltip>
  )

  if (hyperLink) {
    return (
      <MuiLink
        sx={{ color: 'white', fontWeight: 'bold' }}
        component={Link}
        to={`/vaga/${job.data.attributes.uuid}/candidatos`}
      >
        {Text}
      </MuiLink>
    )
  }

  return Text
}

export default JobInfoCell
