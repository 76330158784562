import { memo } from 'react'

import { Skeleton, useTheme } from '@mui/material'
import personPlaceholderImage from '@/assets/images/person_placeholder.jpg'
import Image from '../base/Image'

type Props = {
  /** Path to image src */
  src: string
}

const CandidateImage: React.FC<Props> = ({ src }) => {
  const theme = useTheme()

  return (
    <Image
      color={theme.palette.background.default}
      src={src}
      loading={<Skeleton variant="rectangular" width="100%" height="100%" animation={false} />}
      errorIcon={<img style={{ width: '100%' }} src={personPlaceholderImage} alt="placeholder" />}
    />
  )
}

export default memo(CandidateImage)
