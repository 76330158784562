/* eslint-disable i18next/no-literal-string */
import { useQuery } from '@tanstack/react-query'

import {
  Box, Skeleton, Tooltip, Typography
} from '@mui/material'

import { useTranslation } from '@yu/i18n'
import { APIInterestTribeCountDatum } from '@/types/interest'

type Props = {
  tribeId: string | number
  brandId: string | number
  isDiversity: boolean
}
const YuProTribesCountCell: React.FC<Props> = ({ tribeId, brandId, isDiversity }) => {
  const { t } = useTranslation()

  const cacheKey = [
    'client_area',
    'interests',
    'tribe_count',
    brandId,
    tribeId,
    { is_diversity: isDiversity }
  ]
  const { data: interestsCount, isInitialLoading } = useQuery<APIInterestTribeCountDatum>(
    cacheKey,
    {
      enabled: !!tribeId && !!brandId
    }
  )

  if (isInitialLoading) {
    return <Skeleton width={100} height={50} />
  }

  return (
    <Tooltip
      title={`${interestsCount?.passed_client} ${t('homepage.yupro.passed_and_total')} ${
        interestsCount?.total
      }`}
    >
      <Box flexDirection="row" display="flex" alignItems="center">
        <Typography style={{ color: 'green', fontWeight: 'bold' }}>
          {interestsCount?.passed_client}
        </Typography>
        &nbsp; / &nbsp;
        <Typography>{interestsCount?.total}</Typography>
      </Box>
    </Tooltip>
  )
}

export default YuProTribesCountCell
