import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useToggle } from 'yu-open-lib'

import { useTranslation } from '@yu/i18n'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import { AxiosResponse } from 'axios'
import { Download as DownloadIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { APICandidateCsvDatum } from '@/types/candidate_csv'
import api from '@/api'
import { useCurrentUser } from '@/hooks/auth'
import { APIInterestData, InterestStatus } from '@/types/interest'
import { GroupingType } from '@/types/candidates'

type Props = {
  categories: string[]
  open: boolean
  onClose: () => void
  selectedGrouping: GroupingType
  interests: APIInterestData
  timedOut: boolean
  setTimedOut: (a: boolean) => void
}

const YuProExportDialog: React.FC<Props> = ({
  categories,
  open,
  onClose,
  selectedGrouping,
  interests,
  timedOut,
  setTimedOut
}) => {
  const { addToast } = useToasts()
  const { user } = useCurrentUser()
  const { t } = useTranslation(['candidates', 'text'])
  const [selectedExports, setSelectedExports] = useState(categories.concat('all'))
  const [csvId, setCsvId] = useState<number | string | null>(null)
  const [openAlert, toggleOpenAlert] = useToggle(false)
  const default_columns = {
    name: true,
    salary: true,
    tribe: true,
    job_and_position: true,
    status: true,
    days_in_process: true,
    updated_at: true
  }

  const { data: candidateCsv, isInitialLoading } = useQuery<APICandidateCsvDatum>(
    ['client_area', 'candidate_csvs', csvId],
    {
      enabled: csvId !== null && open,
      refetchInterval: 5000
    }
  )

  const selectAll = () => {
    setSelectedExports(categories.concat('all'))
  }

  useEffect(selectAll, [categories])

  const extractCheckboxState = (label: string) => selectedExports.includes(label)

  const updateCheckboxState = (label: string) => {
    // when unselecting checkbox
    if (selectedExports.includes(label)) {
      // when unselecting 'all' checkbox
      if (label === 'all') {
        setSelectedExports([])
      } else {
        // when unselecting any other checkbox
        setSelectedExports(
          selectedExports
            .filter((element) => element !== label)
            .filter((element) => element !== 'all')
        )
      }
    } else if (label === 'all') {
      // when selecting 'all' checkbox
      setSelectedExports(categories.concat('all'))
    } else if (selectedExports.length === categories.length - 1) {
      // when selecting last available checkbox
      setSelectedExports(selectedExports.concat([label, 'all']))
    } else {
      // when selecting any checkbox other than all
      setSelectedExports(selectedExports.concat(label))
    }
  }

  // csvId is returned when csv-generating job starts
  // candidateCsv is refetched every 5s and useEffect checks if job is finished
  // when it's finished, open alert dialog and set timeout to disable export button
  useEffect(() => {
    if (candidateCsv?.data.attributes.status === 'finished' && !openAlert) {
      toggleOpenAlert()
      setTimedOut(true)
      setTimeout(() => setTimedOut(false), 300000) // disable button for 5 minutes
    }
  }, [candidateCsv?.data?.attributes, toggleOpenAlert, setTimedOut, openAlert])

  const closeDialogs = () => {
    onClose()
    toggleOpenAlert()
  }

  const selectedInterestsIds = useMemo(() => {
    if (interests) {
      return interests.data
        .filter((interest) => {
          if (selectedGrouping === 'tribe_name') {
            return selectedExports.includes(interest.attributes.tribe_name || '')
          }
          return selectedExports.includes(interest.attributes.status || '')
        })
        .map((interest) => interest.attributes.id)
    }
    return []
  }, [interests, selectedExports, selectedGrouping])

  const exportCandidates = useMutation(
    () => api.post('/client_area/candidate_csvs/export_candidates', {
      default_columns,
      custom_columns: [],
      ids: selectedInterestsIds,
      model: 'Interest'
    }),
    {
      onSuccess(response: AxiosResponse<APICandidateCsvDatum>) {
        setCsvId(response?.data.data.id)
      },
      onError() {
        addToast(t('text:report.toasts.errors.export_interests'), {
          appearance: 'error'
        })
      }
    }
  )

  return (
    <>
      {/* Alert and download dialog */}
      <Dialog
        open={openAlert}
        onClose={() => {
          toggleOpenAlert()
          setTimeout(() => {
            setCsvId(null)
          }, 1000)
        }}
      >
        <DialogTitle>{t('text:homepage.yupro.export.alert_title')}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t('text:homepage.yupro.export.dialog_body', { email: user?.email })}
          </Typography>
          <Alert style={{ marginTop: '15px' }} severity="warning" color="info" variant="outlined">
            {t('text:homepage.yupro.export.dialog_alert')}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Link
            component="a"
            download={candidateCsv?.data.attributes.file?.name || ''}
            href={candidateCsv?.data.attributes.file?.url || ''}
            aria-label="download"
          >
            <Button onClick={closeDialogs} endIcon={<DownloadIcon />}>
              {t('text:homepage.yupro.export.confirm')}
            </Button>
          </Link>
        </DialogActions>
      </Dialog>

      {/* Category selection dialog */}
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setCsvId(null)
        }}
      >
        <DialogTitle>{t('text:homepage.yupro.export.dialog_title')}</DialogTitle>

        <DialogContent>
          <Typography style={{ marginTop: '15px' }} variant="h6">
            {t('text:homepage.yupro.export.dialog_choices')}
          </Typography>
          <Typography color="text.disabled">{t('text:homepage.yupro.export.tip')}</Typography>
          <Stack flexDirection="row" alignItems="center">
            <Checkbox
              checked={extractCheckboxState('all')}
              onChange={() => updateCheckboxState('all')}
            />
            <Typography>{t('text:homepage.yupro.export.all')}</Typography>
          </Stack>
          {categories.map((category) => (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={extractCheckboxState(category)}
                onChange={() => updateCheckboxState(category)}
              />
              <Typography>
                {selectedGrouping === 'tribe_name'
                  ? category
                  : t(`candidates:${category as InterestStatus}`)}
              </Typography>
            </Stack>
          ))}
        </DialogContent>
        <DialogActions>
          <Tooltip title={timedOut ? t('text:homepage.yupro.export.timeout') : ''}>
            <LoadingButton
              loading={
                isInitialLoading
                || exportCandidates.isLoading
                || candidateCsv?.data.attributes.status === 'pending'
              }
              onClick={() => exportCandidates.mutate()}
              disabled={timedOut}
            >
              {t('text:homepage.yupro.export.download')}
            </LoadingButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default YuProExportDialog
