/* eslint-disable i18next/no-literal-string */
import React from 'react'

import { Info as InfoIcon } from '@mui/icons-material'
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useToggle } from 'yu-open-lib'

type PropsWithField = {
  title?: string
  help?: string
  icon?: React.ReactElement
  /** @default 'job' */
  isClickable?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

type PropsWithoutField = {
  field?: never
  i18nKey?: never
  title: string
  help: string
  icon?: React.ReactElement
  isClickable?: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const useStyles = makeStyles(() => ({
  badge: {
    width: '90%'
  },
  content: {
    whiteSpace: 'pre-wrap'
  }
}))

const InfoBadge: React.FC<PropsWithField | PropsWithoutField> = ({
  title,
  help,
  children,
  icon,
  onClick
}) => {
  const [dialog, toggleDialog] = useToggle(false)
  const classes = useStyles()

  return (
    <>
      <Dialog open={dialog} onClose={toggleDialog} maxWidth="md" fullWidth>
        <Typography sx={{ padding: 2, pb: 0 }} variant="h5">
          {title}
        </Typography>

        <DialogContent className={classes.content}>
          {help || (
            <p>
              Nenhuma informação cadastrada sobre esse campo, alguma dúvida? Entre em contato com
              alguém de tecnologia.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="error">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Badge
        onClick={onClick}
        className={classes.badge}
        badgeContent={(
          <IconButton onClick={toggleDialog} size="large">
            {icon || <InfoIcon />}
          </IconButton>
        )}
      >
        {children}
      </Badge>
    </>
  )
}

export default InfoBadge
