import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

export const setupDayJs = (): void => {
  dayjs.locale('pt-br')
  dayjs.extend(relativeTime)
  dayjs.extend(weekOfYear)
  dayjs.extend(localizedFormat)
  dayjs.extend(isBetween)
}
