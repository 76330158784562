import { Component } from 'react'

class MicrosoftPopup extends Component {
  componentDidMount(): void {
    const params = Object.fromEntries(new URLSearchParams(window.location.search))
    if (params.error) {
      const errorMessage = params.error_description || 'Login failed. Please try again.'

      if (window.opener) {
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: 'Microsoft'
          },
          window.location.origin
        )
      }

      // Close tab if user cancelled login
      if (params.error === 'user_cancelled_login') {
        window.close()
      }
    }

    if (params.code) {
      if (window.opener) {
        window.opener.postMessage(
          { code: params.code, state: params.state, from: 'Microsoft' },
          window.location.origin
        )
      }
    }

    if (params.microsoft_redirect_url) {
      window.location.href = params.microsoft_redirect_url as string
    }
  }

  render(): null {
    return null
  }
}

export default MicrosoftPopup
