import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import {
  UseMutateFunction, useMutation, useQuery, useQueryClient
} from '@tanstack/react-query'
import { APIInterestData, interestsCacheKey } from '@/types/interest'
import { currentUserCacheKey } from '@/hooks/auth'
import api from '.'

export const useGetInterests = (enabled = true): APIInterestData | undefined => {
  const { data: interests } = useQuery<APIInterestData>(interestsCacheKey, { enabled })
  return interests
}

const interestsSavedCacheKey = [...interestsCacheKey, 'saved']

export const useGetSavedInterests = (enabled = true): APIInterestData | undefined => {
  const { data: interests } = useQuery<APIInterestData>(interestsSavedCacheKey, { enabled })
  return interests
}

type UseSaveInterestsType = {
  saveInterest: UseMutateFunction<AxiosResponse<any>, unknown, void, unknown>
  removeInterest: UseMutateFunction<AxiosResponse<any>, unknown, void, unknown>
}

export const useSaveInterests = (interestId: string | number | undefined): UseSaveInterestsType => {
  const queryClient = useQueryClient()

  const saveMutation = useMutation(() => api.post(`client_area/interests/save/${interestId}`), {
    onSuccess() {
      queryClient.invalidateQueries(currentUserCacheKey)
      queryClient.invalidateQueries(interestsSavedCacheKey)
    },
    onError(error) {
      Sentry.captureException(error)
    }
  })

  const removeMutation = useMutation(
    () => api.post(`client_area/interests/save/${interestId}?remove=true`),
    {
      onSuccess() {
        queryClient.invalidateQueries(currentUserCacheKey)
        queryClient.invalidateQueries(interestsSavedCacheKey)
      },
      onError(error) {
        Sentry.captureException(error)
      }
    }
  )
  return {
    saveInterest: saveMutation.mutate,
    removeInterest: removeMutation.mutate
  }
}
