import { forwardRef, useMemo } from 'react'
import { ListItem, ListItemProps as MuiListItemProps } from '@mui/material'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'

type Props = {
  to: string
} & Partial<MuiListItemProps>

// https://mui.com/material-ui/guides/routing/#list
const ListItemLink: React.FC<Props> = ({ to, children, ...ListItemProps }) => {
  const renderLink = useMemo(
    () => forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
      <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
    )),
    [to]
  )

  const fixedListItemProps = {
    component: renderLink,
    // Ts typings for ListItem with component prop are whack
    button: true as false
  }

  return (
    <ListItem {...ListItemProps} {...fixedListItemProps}>
      {children}
    </ListItem>
  )
}

export default ListItemLink
