import { Box, FormControlLabel, Switch } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { APIBrandDatum } from '@/types/brand'
import { useCurrentUser } from '@/hooks/auth'
import InfoBadge from './InfoBadge'
import YuProTribesCountCell from './YuProTribesCountCell'
import YuProTribesTitleCell from './YuProTribesTitleCell'

const YuProTribesTable: React.FC = () => {
  const cacheKey = ['client_area', 'interests', 'tribe_ids', 17]
  const { data: tribeIds } = useQuery<number[]>(cacheKey)

  const { user } = useCurrentUser()
  const brandId = user?.brand.id
  const { data: brand } = useQuery<APIBrandDatum>(['client_area', 'brands', brandId])
  const [showOnlyDiversity, setShowOnlyDiversity] = useState(false)
  const { t } = useTranslation()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Tribo',
      width: 170,
      renderCell: function NameCell({ row }) {
        if (!brandId) {
          return null
        }

        return (
          <YuProTribesTitleCell
            brandId={brandId}
            tribeId={row.id}
            isDiversity={showOnlyDiversity}
          />
        )
      }
    },
    {
      field: 'total',
      headerName: `👩‍💻 ${t('homepage.yupro.candidate_interest_count', {
        article: brand?.data.attributes.article
      })} ${brand?.data?.attributes.name}`,
      width: 200,
      renderCell: function NameCell({ row }) {
        if (!brandId) {
          return null
        }

        return (
          <YuProTribesCountCell
            tribeId={row.id}
            brandId={brandId}
            isDiversity={showOnlyDiversity}
          />
        )
      },
      headerAlign: 'center',
      align: 'center'
    }
  ]

  const rows = useMemo(() => {
    if (!tribeIds) {
      return []
    }
    return tribeIds?.map((id) => ({
      id
    }))
  }, [tribeIds])

  return (
    <Box>
      <Box sx={{ width: '400px' }}>
        <InfoBadge
          title={t('homepage.yupro.badge_diversity_title')}
          help={t('homepage.yupro.badge_diversity_help')}
        >
          <FormControlLabel
            value="homepage"
            control={(
              <Switch
                color="primary"
                checked={showOnlyDiversity}
                onClick={() => {
                  setShowOnlyDiversity((before) => !before)
                }}
              />
            )}
            label={t('homepage.yupro.see_only_diversity')}
            labelPlacement="end"
          />
        </InfoBadge>
      </Box>

      <DataGrid
        density="compact"
        rows={rows}
        columns={columns}
        autoHeight
        pageSizeOptions={[8, 20, 50]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
              page: 0
            }
          }
        }}
        disableRowSelectionOnClick
        filterMode="server"
        disableColumnMenu
      />
    </Box>
  )
}

export default YuProTribesTable
