/**
 * Key to get config value of stopwords for wordcloud graphs
 *
 * Get from '/api/client_area/${STOPWORDS_CONFIG_KEY}'
 *
 * ```tsx
 * const { data } = useQuery<APIConfigDatum>(['client_area', 'configs', STOPWORDS_CONFIG_KEY])
 * ```
 */
export const STOPWORDS_CONFIG_KEY = 'stopwords'
export const STAND_BY_RECOVERY_DAYS = 'stand_by_recovery_days'
export const EXPORT_CANDIDATES_CONFIG_KEY = 'client_area_export_candidates'
