import axios from 'axios'

import { history } from '@/routing'

const api = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_URL || '/api'}`,
  xsrfCookieName: 'CSRF-TOKEN',
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true
})

export interface JobDocument {
  name: string
  url: string
}

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      await api.delete('auth/logout')

      localStorage.removeItem('client_api_token')
      localStorage.removeItem('client_api_email')

      history.push('/login')
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

/**
 * https://react-query.tanstack.com/guides/default-query-function
 *
 * ```tsx
 * // GET /brands/stuff
 * const { data } = useQuery(['brands', 'stuff'])
 *
 * // GET /jobs
 * const { data } = useQuery('jobs')
 *
 * // GET /brands/stuff?someFilter=123&otherFilter=456
 * const { data } = useQuery(['brands', 'stuff', { someFilter: 123, otherFilter: 456 }])
 * ```
 */
export const defaultQueryFn = async ({
  queryKey
}: {
  queryKey: readonly unknown[] | [string]
}): Promise<void> => {
  // Only add non-object (non-filter) keys to the URL
  // ['brands', 'stuff', { key: 123 }] => '/brands/stuff'
  let url = `${queryKey.filter((key) => typeof key !== 'object').join('/')}?`

  queryKey.forEach((key) => {
    // Add URL parameters to URL
    // Ex.: ['brands', 'stuff', { filter: 123 }] => '/brands/stuff?filter=123'
    if (key && typeof key === 'object') {
      url += Object.keys(key)
        .filter((paramKey) => {
          const value = (key as Record<string, any>)[paramKey]

          return value !== undefined && value !== null
        })
        .map((paramKey) => `${paramKey}=${(key as Record<string, any>)[paramKey]}`)
        .join('&')
    }
  })

  if (url.endsWith('?')) {
    // Remove trailing '?' from url (meaning no URL params were passed)
    url = url.slice(0, -1)
  }

  const { data } = await api.get(url)

  return data
}

export default api
