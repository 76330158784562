import { Grid } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { useViewport } from 'yu-open-lib'
import { useRequiredParams } from '@/hooks'
import GraphCard from '@/components/dashboard/GraphCard'
import { getJobGraph } from '@/api/jobs'
import { textOrNothing } from '@/helpers'
import { DiversityData } from '@/types/dashboards'
import DefaultSkeleton from './DefaultSkeleton'
import DiversityBarGraph from '../dashboard/DiversityBarGraph'

type Params = {
  uuid: string
}

type Props = {
  overall?: boolean
}

const DiversityCard: React.FC<Props> = ({ overall }) => {
  const { uuid } = useRequiredParams<Params>()
  const { t } = useTranslation(['text'])
  const { isMobile } = useViewport()

  const { data: diversity_by_job, isFetched: by_job_fetched } = useQuery(
    [uuid, 'diversity_counts'],
    () => getJobGraph(uuid, 'diversity_counts'),
    { enabled: !overall }
  )
  const { data: overall_diversity, isFetched: overall_fetched } = useQuery<DiversityData>(
    ['client_area', 'overall', 'diversity'],
    { enabled: overall }
  )

  const diversityDataBool = !(diversity_by_job == null && overall_diversity == null)
  const fetched = by_job_fetched || overall_fetched
  const noDiversityData = fetched && !diversity_by_job && !overall_diversity
  const data = overall ? overall_diversity : diversity_by_job

  return (
    <>
      {noDiversityData || (
        <Grid item xs={12} md={12}>
          <GraphCard
            showInfoDialog
            infoText={t('text:dashboard.info.diversity')}
            title={textOrNothing(t('text:dashboard.diversity'), diversityDataBool)}
          >
            {diversityDataBool ? (
              <DiversityBarGraph isMobile={isMobile} data={data} />
            ) : (
              <DefaultSkeleton />
            )}
          </GraphCard>
        </Grid>
      )}
    </>
  )
}

DiversityCard.defaultProps = {
  overall: false
}

export default DiversityCard
