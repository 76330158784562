import React from 'react'

import { Control, Controller } from 'react-hook-form'

import {
  FormControlLabel, FormGroup, Switch, Tooltip
} from '@mui/material'

type Props = {
  control: Control<any>
  name: string
  label?: string
  defaultValue?: boolean
  disabled?: boolean
  disabledText?: string
}

const FormToggle: React.FC<Props> = ({
  control,
  name,
  label = '',
  defaultValue = false,
  disabled = false,
  disabledText = ''
}) => (
  <Controller
    render={({ field: { value, onChange } }) => (
      <Tooltip title={disabled ? disabledText : ''}>
        <FormGroup sx={{ width: 'fit-content' }}>
          <FormControlLabel
            checked={value}
            onChange={(_e, checked) => {
              if (!disabled) {
                onChange(checked)
              }
            }}
            control={<Switch disabled={disabled} defaultChecked />}
            label={label}
          />
        </FormGroup>
      </Tooltip>
    )}
    name={name}
    control={control}
    defaultValue={defaultValue}
  />
)

export default FormToggle
