import { Chip, Tooltip } from '@mui/material'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from '@yu/i18n'
import { useJobInfo } from '@/hooks/jobs'
import { JobPositionStatus } from '@/types/job_position'

type Props = {
  date?: Date
  openAt?: Date
  averageDays?: number
  positionStatus?: JobPositionStatus
  jobId?: number
}

const JobDateCell: React.FC<Props> = ({
  date, openAt, averageDays, positionStatus, jobId
}) => {
  const averagePlacedDays = useMemo(() => averageDays || 0, [averageDays])
  const diffDays = useMemo(() => dayjs(date).diff(dayjs(openAt), 'days'), [date, openAt])
  const { t } = useTranslation()

  const { data: job } = useJobInfo(jobId as number)

  if (!date) {
    return null
  }

  const daysThreshold = 5

  if (
    positionStatus === ('open' || 'open_wait_for_customer')
    && !job?.data?.attributes?.published
  ) {
    return <>-</>
  }

  if (diffDays + daysThreshold < averagePlacedDays) {
    return (
      <Tooltip title={`${t('homepage.tables.job_positions.tips.below_average')}`}>
        <Chip label={diffDays} color="success" sx={{ color: 'white' }} size="medium" />
      </Tooltip>
    )
  }
  if (diffDays > averagePlacedDays) {
    return (
      <Tooltip title={`${t('homepage.tables.job_positions.tips.above_average')}`}>
        <Chip label={diffDays} color="error" sx={{ color: 'white' }} size="medium" />
      </Tooltip>
    )
  }
  return (
    <Tooltip title={`${t('homepage.tables.job_positions.tips.in_average')}`}>
      <Chip label={diffDays} color="warning" sx={{ color: 'white' }} size="medium" />
    </Tooltip>
  )
}

export default JobDateCell
