import {
  ptBR, enUS, esES, GridLocaleText, DataGridProps
} from '@mui/x-data-grid'
import { useTranslation } from '@yu/i18n'

interface Localization {
  components: {
    MuiDataGrid: {
      defaultProps: {
        localeText: Partial<GridLocaleText>
      }
    }
  }
}

export interface UseDataGridReturn extends Partial<DataGridProps> {
  localeText: Partial<GridLocaleText>
}

/**
 * Returns default props to be used for DataGrid, like locale
 *
 * @example
 * const dataGridProps = useDataGrid()
 *
 * return <DataGrid columns={columns} rows={rows} {...dataGridProps} />
 */
export const useDataGrid = (): UseDataGridReturn => {
  const { i18n } = useTranslation()

  let locale: Localization = ptBR

  if (i18n.language === 'en') {
    locale = enUS
  } else if (i18n.language === 'es') {
    locale = esES
  }

  return {
    localeText: locale.components.MuiDataGrid.defaultProps.localeText
  }
}
