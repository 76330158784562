import { useTranslation } from '@yu/i18n'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToasts } from 'react-toast-notifications'
import * as Sentry from '@sentry/react'
import { BackdropLoading } from 'yu-open-lib'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { InterestRejectionTemplate, interestsCacheKey } from '@/types/interest'
import api from '@/api'
import { RejectionType } from '@/types/rejection'
import { useInterestRejectionTemplates } from '@/hooks/interests'

type Inputs = {
  comment: string
  rejections: InterestRejectionTemplate[]
}

type Props = {
  interestId: string | number
  onClose: () => void
  open: boolean
}

const RejectInterestDialog: React.FC<Props> = ({ interestId, open, onClose }) => {
  const { interestRejectionTemplates } = useInterestRejectionTemplates()

  const { t } = useTranslation('text')

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({ mode: 'onSubmit' })

  const { addToast } = useToasts()
  const queryClient = useQueryClient()

  const rejectMutation = useMutation(
    (data: Inputs) => api.post(`client_area/interests/reject/${interestId}`, data),
    {
      onSuccess() {
        queryClient.invalidateQueries(interestsCacheKey)
      },
      onError(error) {
        onClose()

        addToast(t('report.toasts.errors.reject_interest'), { appearance: 'error' })
        Sentry.captureException(error)
      }
    }
  )

  const onSubmit = (data: Inputs) => {
    rejectMutation.mutate(data)
    onClose()
  }

  return (
    <>
      <BackdropLoading open={rejectMutation.isLoading} />
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('report.dialog.interest.reject.title')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  multiple
                  options={interestRejectionTemplates}
                  onChange={(_event, value: any) => {
                    onChange(value)
                  }}
                  getOptionLabel={(option) => option.reason}
                  groupBy={(option) => t(
                    `homepage.tables.yupro.rejection.type.${
                        option.rejection_type as RejectionType
                    }`
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={!!errors.rejections}
                      inputRef={ref}
                      variant="outlined"
                      {...params}
                      label="Motivos"
                    />
                  )}
                />
              )}
              name="rejections"
              rules={{ required: true }}
              control={control}
            />

            <Box mt={2} />

            <Controller
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  variant="outlined"
                  label="Comentário"
                  fullWidth
                  multiline
                  error={!!errors.comment}
                />
              )}
              name="comment"
              rules={{ required: true }}
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={rejectMutation.isLoading} onClick={onClose}>
              {t('misc.close')}
            </Button>
            <Button disabled={rejectMutation.isLoading} type="submit" color="secondary">
              {t('misc.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default RejectInterestDialog
