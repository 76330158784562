import { Word } from 'react-wordcloud'
import dayjs from 'dayjs'
import api from '@/api'
import { BaseAPISerializer, DataAPISerializer } from '@/types/api'
import {
  APICandidatesOverviewData,
  APILiteCandidatesOverview,
  CandidatesMeta
} from '@/types/candidates'
import { APIJobData, JobStatusFilter } from '@/types/jobs'
import {
  EvaluationStepGraphData,
  GeneralData,
  JobDashboardData,
  JobStatusFunnel,
  IndustryTreeMap,
  SalaryChartData,
  SalaryDistributionData,
  CandidatesGeoData,
  KeywordsData,
  Rejection,
  WaffleDatum,
  HuntingEvolutionChartDatum,
  DiversityData,
  AverageStepDaysData
} from '@/types/dashboards'
import { APITimelineRecordData } from '@/types/timeline_record'

type APIJobMeta = { previousId: number; nextId: number }

export const getClientJobs = async (
  page: number,
  status: JobStatusFilter
): Promise<DataAPISerializer<APIJobData[], APIJobMeta>> => {
  const { data } = await api.get(`client_area/jobs?status=${status}&page=${page}`)

  return data
}

type CandidatesOverviewResponse = DataAPISerializer<APICandidatesOverviewData[], CandidatesMeta>
type LiteCandidatesOverviewResponse = DataAPISerializer<APILiteCandidatesOverview[]>

type JobSalaryRangeResponse = DataAPISerializer<
  BaseAPISerializer<{
    target_salary_min: string
    target_salary_max: string
  }>
>

export const getClientJobCandidates = async (uuid: string): Promise<CandidatesOverviewResponse> => {
  const { data } = await api.get(`client_area/candidates/overview/${uuid}`)

  return data
}

export const getJobDashboard = async (uuid: string): Promise<JobDashboardData> => {
  const { data } = await api.get(`client_area/jobs/dashboard/${uuid}`)

  return data
}

export const getJobSalaryRange = async (uuid: string): Promise<JobSalaryRangeResponse> => {
  const { data } = await api.get(`client_area/jobs/salary_range/${uuid}`)

  return data
}

export const getJobTimeline = async (uuid: string): Promise<APITimelineRecordData> => {
  const { data } = await api.get(`client_area/jobs/timeline/${uuid}`)

  return data
}

export const getActiveCandidates = async (
  uuid: string
): Promise<LiteCandidatesOverviewResponse> => {
  const { data } = await api.get(`client_area/jobs/active_candidates/${uuid}`)

  return data
}

export const getPlacedCandidates = async (
  uuid: string
): Promise<LiteCandidatesOverviewResponse> => {
  const { data } = await api.get(`client_area/jobs/placed_candidates/${uuid}`)

  return data
}

type GraphType =
  | 'job_status_funnel'
  | 'job_status_funnel_colors'
  | 'evaluation_steps'
  | 'job_application_statuses'
  | 'general_data'
  | 'conversion_percentage'
  | 'industries_treemap'
  | 'last_company_wordcloud'
  | 'salary_chart_data'
  | 'salary_distribution_data'
  | 'candidates_geolocations'
  | 'keyword_counts'
  | 'common_rejections'
  | 'conversion_waffle'
  | 'hunting_evolution'
  | 'diversity_counts'
  | 'average_steps_days'

export type JobGraphOptions = {
  asDate?: Date
}

type GetJobGraph = {
  (uuid: string, graph: 'job_status_funnel', options?: JobGraphOptions): Promise<JobStatusFunnel[]>
  (uuid: string, graph: 'job_status_funnel_colors', options?: JobGraphOptions): Promise<string[]>
  (uuid: string, graph: 'evaluation_steps', options?: JobGraphOptions): Promise<
    EvaluationStepGraphData[]
  >
  (uuid: string, graph: 'job_application_statuses', options?: JobGraphOptions): Promise<string[]>
  (uuid: string, graph: 'general_data', options?: JobGraphOptions): Promise<GeneralData>
  (uuid: string, graph: 'conversion_percentage', options?: JobGraphOptions): Promise<number>
  (uuid: string, graph: 'industries_treemap', options?: JobGraphOptions): Promise<IndustryTreeMap>
  (uuid: string, graph: 'last_company_wordcloud', options?: JobGraphOptions): Promise<Word[]>
  (uuid: string, graph: 'salary_chart_data', options?: JobGraphOptions): Promise<SalaryChartData>
  (
    uuid: string,
    graph: 'salary_distribution_data',
    options?: JobGraphOptions
  ): Promise<SalaryDistributionData>
  (
    uuid: string,
    graph: 'candidates_geolocations',
    options?: JobGraphOptions
  ): Promise<CandidatesGeoData>
  (uuid: string, graph: 'keyword_counts', options?: JobGraphOptions): Promise<KeywordsData>
  (uuid: string, graph: 'common_rejections', options?: JobGraphOptions): Promise<Rejection>
  (uuid: string, graph: 'conversion_waffle', options?: JobGraphOptions): Promise<WaffleDatum[]>
  (uuid: string, graph: 'hunting_evolution', options?: JobGraphOptions): Promise<
    HuntingEvolutionChartDatum[]
  >
  (uuid: string, graph: 'diversity_counts'): Promise<DiversityData>
  (
    uuid: string,
    graph: 'average_steps_days',
    options?: JobGraphOptions
  ): Promise<AverageStepDaysData>
}

export const getJobGraph: GetJobGraph = async (
  uuid: string,
  graph: GraphType,
  options?: JobGraphOptions
) => {
  let url = `client_area/jobs/dashboard_graph/${uuid}/${graph}`

  if (options?.asDate) {
    url += `?asDate=${dayjs(options.asDate).format('DD/MM/YYYY')}`
  }

  const { data } = await api.get(url)

  return data[graph]
}
