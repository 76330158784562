import { useContext, createContext, useState } from 'react'

export type FiltersContent = {
  filters?: string[]
  setFilters?: any
}

export const FiltersContext = createContext<FiltersContent>({})

type Props = {
  children?: React.ReactNode
}

export const ProvideFilterContext: React.FC<Props> = ({ children }) => {
  const [filters, setFilters] = useState([])

  const context = {
    filters,
    setFilters
  }

  return <FiltersContext.Provider value={context}>{children}</FiltersContext.Provider>
}

export const useFiltersContext = (): FiltersContent => useContext(FiltersContext)
