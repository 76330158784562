import { Button, Grid } from '@mui/material'
import { MeetingCard, useToggle } from 'yu-open-lib'
import { useTranslation } from '@yu/i18n'
import { Cancel, EventRepeat } from '@mui/icons-material'
import { useMemo, useState } from 'react'
import { APIMeetingData, MeetingStatus } from '@/types/meeting'
import { useCurrentUser } from '@/hooks/auth'
import CancelMeetingDialog from './CancelMeetingDialog'
import ReScheduleMeetingDialog from './ReScheduleMeetingDialog'

type Props = {
  meetings: APIMeetingData
  showInterviewer?: boolean
  showInterviewee?: boolean
  showInterviewerLabel?: boolean
  showIntervieweeLabel?: boolean
}

const JobMeetings: React.FC<Props> = ({
  meetings,
  showInterviewer = true,
  showInterviewee = true,
  showInterviewerLabel = false,
  showIntervieweeLabel = false
}) => {
  const { t } = useTranslation()
  const [cancelDialog, toggleCancelDialog] = useToggle(false)
  const [reScheduleDialog, toggleReScheduleDialog] = useToggle(false)

  const [selectedMeetingId, setSelectedMeetingId] = useState<string>()

  const selectedMeeting = useMemo(() => {
    if (!selectedMeetingId || !meetings) return null

    return meetings.data.find((m) => m.id === selectedMeetingId)
  }, [selectedMeetingId, meetings])

  const { user } = useCurrentUser()

  const statusChipColor = (status: MeetingStatus) => {
    switch (status) {
      case 'scheduled':
        return 'success'
      case 'finished':
        return 'primary'
      case 'error':
        return 'error'
      case 'scheduled_without_invite':
        return 'warning'
      case 'canceled':
        return 'error'
      case 'pending':
        return 'default'
      default:
        return 'default'
    }
  }

  return (
    <Grid container spacing={1}>
      {selectedMeeting && (
        <>
          <ReScheduleMeetingDialog
            meetingId={selectedMeeting.id}
            open={reScheduleDialog}
            onClose={toggleReScheduleDialog}
          />

          <CancelMeetingDialog
            meetingId={selectedMeeting.id}
            open={cancelDialog}
            onClose={toggleCancelDialog}
            jobApplicationUUID={selectedMeeting.attributes.job_application_uuid}
          />
        </>
      )}

      {meetings?.data.map((meeting) => (
        <Grid item xs={12} md={3} sx={{ minWidth: 250 }}>
          <MeetingCard
            sx={{ height: '100%', width: '100%' }}
            key={meeting?.id}
            label=""
            statusLabel={
              t(`home.meetings.meetings_status.${meeting.attributes.status}`) || 'pending'
            }
            interviewerName={meeting.attributes.interviewer.data.attributes.name}
            interviewerAvatarUrl={meeting.attributes.interviewer.data.attributes.avatar || ''}
            intervieweeName={meeting.attributes.interviewee.data.attributes.name}
            intervieweeAvatarUrl={meeting.attributes.interviewee.data.attributes.avatar || ''}
            showInterviewer={showInterviewer}
            showInterviewee={showInterviewee}
            statusChipColor={statusChipColor(meeting.attributes.status)}
            interviewerLabel={showInterviewerLabel ? t('home.meetings.interviewer') : ''}
            intervieweeLabel={showIntervieweeLabel ? t('home.meetings.interviewee') : ''}
            dateLabel={`${t('home.meetings.date')}`}
            startAt={meeting.attributes.start_at}
            endAt={meeting.attributes.end_at}
          >
            <Grid container spacing={1} sx={{ mt: 1 }}>
              {(meeting.attributes.status === 'scheduled'
                || meeting.attributes.status === 'pending') && (
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => {
                        setSelectedMeetingId(meeting.id)
                        toggleCancelDialog()
                      }}
                      color="error"
                      fullWidth
                      endIcon={<Cancel />}
                    >
                      {t('meetings.actions.cancel')}
                    </Button>
                  </Grid>
              )}

              {meeting.attributes.status === 'scheduled' && (
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    endIcon={<EventRepeat />}
                    onClick={() => {
                      setSelectedMeetingId(meeting.id)
                      toggleReScheduleDialog()
                    }}
                    disabled={
                      user?.id.toString() !== meeting.attributes.interviewer.data.id.toString()
                    }
                  >
                    {t('meetings.actions.re_schedule')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </MeetingCard>
        </Grid>
      ))}
    </Grid>
  )
}

export default JobMeetings
