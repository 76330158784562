import { Grid } from '@mui/material'
import { useTranslation } from '@yu/i18n'
import { useQuery } from '@tanstack/react-query'
import { getJobGraph } from '@/api/jobs'
import { useRequiredParams } from '@/hooks'
import GraphCard from '@/components/dashboard/GraphCard'
import CommonRejectionsSunburst from '@/components/dashboard/CommonRejectionsSunburst'
import { textOrNothing } from '@/helpers'
import { Rejection } from '@/types/dashboards'
import DefaultSkeleton from './DefaultSkeleton'

type Params = {
  uuid: string
}

type Props = {
  overall?: boolean
}

const CommonRejectionsSunburstCard: React.FC<Props> = ({ overall }) => {
  const { uuid } = useRequiredParams<Params>()
  const { t } = useTranslation()

  const { data: rejections_by_job, isFetched: by_job_fetched } = useQuery(
    [uuid, 'common_rejections'],
    () => getJobGraph(uuid, 'common_rejections'),
    { enabled: !overall }
  )

  const { data: overall_rejections, isFetched: overall_fetched } = useQuery<Rejection>(
    ['client_area', 'overall', 'rejections'],
    { enabled: overall }
  )

  const rejectionDataBool = !(rejections_by_job == null && overall_rejections == null)
  const fetched = by_job_fetched || overall_fetched
  const noRejectionData = fetched && !rejectionDataBool
  const data = overall ? overall_rejections : rejections_by_job

  return (
    <>
      {noRejectionData || (
        <Grid item xs={12} md={6}>
          <GraphCard
            showInfoDialog
            infoText={t('dashboard.info.common_rejections')}
            title={textOrNothing(t('dashboard.rejections'), rejectionDataBool)}
          >
            {rejectionDataBool && data ? (
              <CommonRejectionsSunburst data={data} />
            ) : (
              <DefaultSkeleton />
            )}
          </GraphCard>
        </Grid>
      )}
    </>
  )
}

CommonRejectionsSunburstCard.defaultProps = {
  overall: false
}

export default CommonRejectionsSunburstCard
